import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { toggleModal } from '../../redux-store/modalReducer'
import InputBox from '../../common-components/InputBox'
import { electionTypeArray } from '../../utilities/electionTypeArray'
import { partyArray } from '../../utilities/partyArray'
import FilterButton from '../../common-components/FilterButton'
import useValidator from '../../utilities/useValidator'
import Swal from 'sweetalert2'
import { communication } from '../../services/communication'
import { useNavigate } from 'react-router-dom'
import Loader from '../../utilities/Loader'
import { hideLoader, showLoader } from '../../redux-store/loaderReducer'

const AddNewElection = ({ fetchElectionList }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loaderReducer.loaderState);
    const [validator, showValidationMessage] = useValidator();
    const [electionData, setElectionData] = useState(
        {
            name: "", 
            type: "",
            party: "",
            year: "",
            electionDate: ""
        }
    );

    //Add Election
    const addElection = async () => {
        try {
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false
            }
            dispatch(showLoader());
            const serverResponse = await communication.addElection(electionData);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                dispatch(toggleModal(""));
                Swal.fire({ text: serverResponse?.data?.message, icon: "success" });
                fetchElectionList();
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
            }
        } catch (error) {
            dispatch(hideLoader());
            Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
        }
    }
    return (
        <>
            {loader === true ?
                <Loader />
                :
                <div className="modal_wrapper">
                    <div className="card_wrapper">
                        <div className="card_header">
                            <div className="title">
                                <h5>Add Election</h5>
                            </div>
                            <div className="close_button" onClick={() => dispatch(toggleModal(""))}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>
                        <div className="card_body">
                            <div className="row">
                                <div className="col-6">
                                    <div className="input_box_wrapper">
                                        <label>Election Name</label>
                                        <InputBox type="text" name="name" value={electionData.name} onChange={(event) => setElectionData({ ...electionData, name: event.target.value })} placeholder="Enter Election Name" />
                                        {validator.message("name", electionData.name, "required", {
                                            messages: {
                                                required: "Election Name is required"
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input_box_wrapper">
                                        <label>Election Type</label>
                                        <select name="type" onChange={(event) => setElectionData({ ...electionData, type: event.target.value })} className="form-select">
                                            <option value="">Select Election Type</option>
                                            {electionTypeArray?.map((electionType, index) => {
                                                return (
                                                    <option value={electionType} key={index}>{electionType}</option>
                                                );
                                            })}
                                        </select>
                                        {validator.message("type", electionData.type, "required", {
                                            messages: {
                                                required: "Election Type is required"
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input_box_wrapper">
                                        <label>Party</label>
                                        <select name="party" onChange={(event) => setElectionData({ ...electionData, party: event.target.value })} className="form-select party_select_box">
                                            <option value="">Select Party</option>
                                            {partyArray?.map((party, index) => {
                                                return (
                                                    <option value={party} key={index}>{party}</option>
                                                );
                                            })}
                                        </select>
                                        {validator.message("party", electionData.party, "required", {
                                            messages: {
                                                required: "Party Name is required"
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input_box_wrapper">
                                        <label>Year</label>
                                        <InputBox type="number" name="year" value={electionData.year} onChange={(event) => setElectionData({ ...electionData, year: event.target.value })} placeholder="Enter Election Year" />
                                        {validator.message("year", electionData.year, "required|min:4|max:4", {
                                            messages: {
                                                required: "Election Year is required"
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input_box_wrapper">
                                        <label>Election Date</label>
                                        <InputBox type="date" name="electionDate" value={electionData.electionDate} onChange={(event) => setElectionData({ ...electionData, electionDate: event.target.value })} placeholder="Enter Election Date" />
                                    </div>
                                </div>
                                <div className="submit_button_wrapper">
                                    <FilterButton name="Add" onClick={() => addElection()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default AddNewElection