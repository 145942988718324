import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleDown, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { communication } from '../../services/communication'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader } from '../../redux-store/loaderReducer'
import FileSaver from 'file-saver';

const DownloadTemplates = () => {
    const dispatch = useDispatch();

    //Export Organization data
    const DownloadTemplates = async (templateType) => {
        try {
            Swal.fire({
                // title: "Are you sure?",
                text: `Are you sure you want to Download ${templateType} Template?`,
                showCancelButton: true,
                confirmButtonColor: '#38CAB3',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(async function (isConfirm) {
                if (isConfirm.isConfirmed) {
                    dispatch(showLoader());
                    let responseFromServer;
                    if (templateType === "Organizer") {
                        responseFromServer = await communication.downloadOrganizerTemplate();
                    } else {
                        responseFromServer = await communication.downloadVoterTemplate();
                    }
                    if (responseFromServer.data) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob([responseFromServer.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        FileSaver.saveAs(file, `${templateType}.xlsx`);
                    } else {
                        Swal.fire({ text: "Failed to export data in excel", icon: "warning" });
                    }
                    dispatch(hideLoader());
                } else {
                    return;
                }
            });
        } catch (error) {
            dispatch(hideLoader());
            Swal.fire({ text: error.message, icon: "warning" });
        }
    }

    return (
        <>
            <div className="top_header">
                <div className="title">Download Template</div>
            </div>
            <section>
                <div className="download_template_wrapper">
                    <div className="card_wrapper" onClick={() => DownloadTemplates("Organizer")}>
                        <h6>Download</h6>
                        <h6>Organizer Data Template</h6>
                        <FontAwesomeIcon icon={faFileExcel} />
                        <FontAwesomeIcon icon={faCircleDown} className="download_icon" />
                    </div>
                    <div className="card_wrapper" onClick={() => DownloadTemplates("Voter")}>
                        <h6>Download</h6>
                        <h6>Voter Data Template</h6>
                        <FontAwesomeIcon icon={faFileExcel} />
                        <FontAwesomeIcon icon={faCircleDown} className="download_icon" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default DownloadTemplates