import React, { useState, useEffect } from "react";
import { getServerUrl } from "../../../services/communication";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../redux-store/loaderReducer";
import { useDispatch } from "react-redux";

const BoothTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [socket, setSocket] = useState();
  const [data, setData] = useState([]);
  const [id, setId] = useState("");

  useEffect(() => {
    const socketConnection = io(getServerUrl(), { transports: ["websocket"] });

  //   socketConnection.on("connect_error", (error) => {
  //     Swal.fire({
  //     icon:'warning',
  //     // title:'Socket not connected',
  //     text:'The socket connection has been disconnected'
  //     })
  //     console.log(`connect_error due to ${error.message}`);
  // });

    setSocket(socketConnection);
    return () => {
      socketConnection.disconnect();
      console.log("disconnect");
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("getElectionReport", (graph) => {
        setData(graph?.getHighestAndLowestBoothCount);
        setId(graph.id)
      });
    }
  }, [socket]);

  return (
    <div className="msi_panel_card_wrapper">
      <div className="booth_card_wrapper">
        <div className="booth_card">
          <div className="title">
            <h5>Lowest Voting Booth</h5>
          </div>
          <div className="booth_table">
            <div className="header">
              <div className="column">
                <h6>Booth</h6>
              </div>
              <div className="column">
                <h6>Voting %</h6>
              </div>
              <div className="column">
                <h6>Party %</h6>
              </div>
            </div>
            {data?.lowest5?.map((graphData, index) => {
              return (
                <div className="data" key={index}>
                  <div className="column">
                    <h6>
                      <a
                        onClick={() =>
                          navigate(
                            `/dashboard/voting-day/msi-panel/booth-voter-list?boothNo=${graphData.boothNo}&id=${id}`
                          )
                        }
                      >
                        {graphData.boothNo}
                      </a>
                    </h6>
                  </div>
                  <div className="column">
                    <h6>{graphData.totalVoteGet}</h6>
                  </div>
                  <div className="column">
                    <h6>{graphData.partyVotingPercentage}</h6>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="booth_card">
          <div className="title">
            <h5>Highest Voting Booth</h5>
          </div>
          <div className="booth_table">
            <div className="header">
              <div className="column">
                <h6>Booth</h6>
              </div>
              <div className="column">
                <h6>Voting %</h6>
              </div>
              <div className="column">
                <h6>Party %</h6>
              </div>
            </div>
            {data?.top5?.map((graphData, index) => {
              return (
                <div className="data" key={index}>
                  <div className="column">
                    <h6>
                      <a
                        onClick={() =>
                          navigate(
                            `/dashboard/voting-day/msi-panel/booth-voter-list?boothNo=${graphData.boothNo}&id=${id}`
                          )
                        }
                      >
                        {graphData.boothNo}
                      </a>
                    </h6>
                  </div>
                  <div className="column">
                    <h6>{graphData.totalVoteGet}</h6>
                  </div>
                  <div className="column">
                    <h6>{graphData.partyVotingPercentage}</h6>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoothTable;
