import React from 'react'
import { NavLink } from 'react-router-dom'

const DashboardDropdown = () => {
    return (
        <>
            <NavLink to="/dashboard/reports/current-progress">
                <div className="dropdown_tab">
                    <div className="title"><span>Current Progress</span></div>
                </div>
            </NavLink>
            <NavLink to="/dashboard/reports/category-wise-voters">
                <div className="dropdown_tab">
                    <div className="title"><span>Category Wise Voters</span></div>
                </div>
            </NavLink>
            <NavLink to="/dashboard/reports/age-wise-voters">
                <div className="dropdown_tab">
                    <div className="title"><span>Age Wise Voters</span></div>
                </div>
            </NavLink>
            <NavLink to="/dashboard/reports/occupation-wise-voters">
                <div className="dropdown_tab">
                    <div className="title"><span>Occupation Wise Voters</span></div>
                </div>
            </NavLink>
            <NavLink to="/dashboard/reports/immediate-focus">
                <div className="dropdown_tab">
                    <div className="title"><span>Immediate Focus</span></div>
                </div>
            </NavLink>
        </>
    )
}

export default DashboardDropdown