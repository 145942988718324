import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../common-components/Button";
import { toggleModal } from "../../redux-store/modalReducer";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../redux-store/loaderReducer";
import { communication } from "../../services/communication";
import { useEffect } from "react";
import Loader from "../../utilities/Loader";
import AddNewEvent from "./AddNewEvent";
import UpdateEvent from "./UpdateEvent";
import Pagination from "../../common-components/Pagination";

const ActivityList = () => {
  const [toggleSearchInput, SetToggleSearchInput] = useState(false);
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const modal = useSelector((state) => state.modalReducer.modal);
  const pageLimit = process.env.REACT_APP_PAGE_LIMIT ?? 20;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [activityId, setActivityId] = useState();
  const [page, setPage] = useState();
  const [isPageUpdated, setIsPageUpdated] = useState(false);

  // set current page on click
  const handlePageClick = (e) => {
    if (currentPage > pageCount) {
      Swal.fire({
        text: "Entered Page Cannot be grater than total Page",
        icon: "warning",
      });
      return false;
    } else {
      if (searchValue) {
        getActivityListBySearch(searchValue, currentPage);
      } else {
        fetchActivityList(searchValue, currentPage);
      }
    }
  };

  //get activity list by search
  const getActivityListBySearch = async (searchString, page) => {
    try {
      if (searchString) {
        const responseFromServer = await communication.getActivityList(
          searchString,
          page
        );
        if (responseFromServer?.data?.status === "SUCCESS") {
          setActivityList(responseFromServer?.data?.activity);
          setPageCount(responseFromServer?.data?.totalPages);
          setPage(page);
        } else if (responseFromServer?.data?.status === "JWT_INVALID") {
          Swal.fire({
            text: responseFromServer?.data?.message,
            icon: "warning",
          });
          navigate("/login");
        } else {
          setActivityList([]);
          setPageCount(0);
          Swal.fire({
            text: responseFromServer?.data?.message,
            icon: "warning",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //fetch activity list on initial load
  const fetchActivityList = async (searchString, page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await communication.getActivityList(
        searchString,
        page
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        setActivityList(responseFromServer?.data?.activity);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(currentPage);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setActivityList([]);
        setPageCount(0);
        // Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };
  // useEffect(() => {
  //   fetchActivityList(page);
  // }, []);

  useEffect(() => {
    handlePageClick();
  }, [isPageUpdated]);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">Activity Management</div>
            <div className="search_wrapper">
              <input
                type="search"
                value={searchValue}
                onChange={(event) => {
                  getActivityListBySearch(event.target.value.trim(), 1);
                  setPage(1);
                  setCurrentPage(1);
                  setSearchValue(event.target.value.trim());
                }}
                className="form-control"
                placeholder="search..."
                style={{
                  width: `${toggleSearchInput === false ? "37px" : "250px"}`,
                  borderRadius: `${
                    toggleSearchInput === false ? "50%" : "5px"
                  }`,
                }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() => {
                  SetToggleSearchInput(!toggleSearchInput);
                  setSearchValue("");
                  toggleSearchInput &&
                    searchValue !== "" &&
                    fetchActivityList("", 1);
                }}
                style={{
                  left: `${toggleSearchInput === false ? "50" : "90"}%`,
                  padding: "16px",
                }}
              />
            </div>
          </div>

          <section>
            <div className="section_wrapper">
              <div className="section_header_wrapper">
                <div className="paginationWrapper">
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageCount={pageCount}
                    isPageUpdated={isPageUpdated}
                    setIsPageUpdated={setIsPageUpdated}
                  />
                </div>
                <div className="button_wrapper">
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    onClick={() => {
                      fetchActivityList("", currentPage);
                      setPage(currentPage);
                      // setCurrentPage(1);
                      setSearchValue("");
                    }}
                    className="refresh_icon"
                    title="Refresh"
                  />
                  <Button
                    icon={faPlus}
                    name="Add New Event"
                    onClick={() => dispatch(toggleModal("add_event"))}
                  />
                </div>
              </div>

              <div className="table_wrapper activity_table_wrapper">
                <div className="table">
                  <div className="table_header">
                    <div className="serial_number">
                      <h5>Sr. No</h5>
                    </div>
                    <div className="election_name">
                      <h5>Election Name</h5>
                    </div>
                    <div className="event_name">
                      <h5>Event Name</h5>
                    </div>
                    <div className="booth_name">
                      <h5>Booth Name</h5>
                    </div>
                    <div className="alloted_member">
                      <h5>Alloted Member</h5>
                    </div>
                    <div className="points">
                      <h5>Alloted Points</h5>
                    </div>
                    <div className="points">
                      <h5>Utilized Points</h5>
                    </div>
                    <div className="description">
                      <h5>Description</h5>
                    </div>
                    <div className="action">
                      <h5>Action</h5>
                    </div>
                  </div>
                  {activityList?.length > 0 ? (
                    <>
                      {activityList?.map((activityData, index) => {
                        return (
                          <div className="table_data" key={index}>
                            <div className="serial_number">
                              <h6>
                                {Number(pageLimit) * (page - 1) + (index + 1)}
                              </h6>
                            </div>
                            <div className="election_name">
                              <h6>
                                {activityData?.electionDetails?.map((ele) => {
                                  return ele?.name;
                                })}
                              </h6>
                            </div>
                            <div className="event_name">
                              <h6>{activityData?.event}</h6>
                            </div>
                            <div className="booth_name">
                              <div className="booths">
                                {activityData?.boothName?.map((ele, index) => {
                                  return (
                                    <h6 key={index}>{`${
                                      index + 1
                                    }) ${ele}`}</h6>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="alloted_member">
                              <div className="organizers">
                                {/* {console.log("activityData",activityData)} */}
                                {activityData?.organizerDetails?.map(
                                  (ele, index) => {
                                    return (
                                      <div key={index}>
                                        <h6>{`${index + 1}) ${ele?.name}`}</h6>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                            <div className="points">
                              <h6>{activityData?.allotedPoints}</h6>
                            </div>
                            <div className="points">
                              <h6>{activityData?.utilizedPoints}</h6>
                            </div>
                            <div className="description">
                              <h6>{activityData?.description}</h6>
                            </div>
                            <div className="action">
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                onClick={() => {
                                  setActivityId(activityData?._id);
                                  dispatch(toggleModal("update_event"));
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="data_not_available_wrapper">
                      Data Not Available
                    </div>
                  )}
                </div>
              </div>
            </div>
            {modal === "add_event" && (
              <AddNewEvent
                fetchActivityList={fetchActivityList}
                currentPage={currentPage}
              />
            )}
            {modal === "update_event" && (
              <UpdateEvent
                activityId={activityId}
                handlePageClick={handlePageClick}
              />
            )}
          </section>
        </>
      )}
    </>
  );
};

export default ActivityList;
