import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import InputBox from '../../common-components/InputBox'
import FilterButton from '../../common-components/FilterButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { tabArray } from '../../utilities/tabArray'
import { fieldArray } from '../../utilities/fieldArray'
import { hideLoader, showLoader } from '../../redux-store/loaderReducer'
import { useDispatch, useSelector } from 'react-redux'
import { communication } from '../../services/communication'
import Swal from 'sweetalert2'
import useValidator from '../../utilities/useValidator'


const UpdateDesignation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParam] = useSearchParams()
    const [validator, showValidationMessage] = useValidator();

    const loader = useSelector((state) => state.loaderReducer.loaderState)
    const [data, setData] = useState({
        tab: [],
        name: "",
        fields: []
    });

    function handlerchange(event, fields) {
        if (fields) {
            const fieldData = event.target.checked ? [...data?.fields, event.target.value] : data?.fields.filter((ele) => ele !== event.target.value);
            setData({ ...data, fields: fieldData });
        } else {
            const tabData = event.target.checked ? [...data?.tab, event.target.value] : data?.tab.filter((ele) => ele !== event.target.value);
            setData({ ...data, tab: tabData });

        }
    }



    // update designation update
    const updateDesignation = async () => {
        try {
            if (!validator.allValid()) {
                showValidationMessage(true)
                return false
            }

            if (data.tab.length === 0) {
                Swal.fire({ text: "At least one Tab access is required", icon: "warning" });
                return false
            } else if (data.fields.length === 0) {
                Swal.fire({ text: "At least one Field access is required", icon: "warning" });
                return false
            } else {

                dispatch(showLoader());
                const dataTosend = {
                    ...data,
                    designationId: searchParam.get("id")
                }
                const responseFromServer = await communication.updateDesignation(dataTosend);
                if (responseFromServer?.data?.status === "SUCCESS") {
                    Swal.fire({ text: responseFromServer?.data?.message, icon: "success" });
                    navigate("/dashboard/designation-management");
                    dispatch(hideLoader());
                } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                    Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" })

                } else {
                    Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" })
                }
                dispatch(hideLoader());
            }

        } catch (error) {
            dispatch(hideLoader())
            Swal.fire({ text: error?.responseFromServer?.data?.message || error?.message, icon: "error" })
        }
    }

    // add designation detils by id
    const fetchdesignationDetailById = async () => {
        try {
            dispatch(showLoader());
            const responseFromServer = await communication.getdesignationDetailById(searchParam.get("id"));
            if (responseFromServer?.data?.status === "SUCCESS") {
                setData(responseFromServer.data.designation)
                dispatch(hideLoader());

            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" })
                navigate("/login");

            } else {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" })
            }
            dispatch(hideLoader());
        }
        catch (error) {
            dispatch(hideLoader())
            Swal.fire({ text: error?.responseFromServer?.data?.message || error?.message, icon: "error" })
        }
    }

    useEffect(() => {
        fetchdesignationDetailById();
    }, []);


    return (


        <>
            {loader === true ?
                <loader />
                :
                <>
                    <div className="top_header">
                        <div className="title">Update Designation</div>
                    </div>
                    <div className="designation_wrapper">
                        <div className="designation_card">
                            <div className="card_header">
                                <div className="title">
                                    <h5>Update Designation</h5>
                                </div>
                                <div className="close_buttom">
                                    <div className="" onClick={() => navigate("/dashboard/designation-management")}>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>
                            </div>
                            <div className="card_body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="input_box_wrapper">
                                            <label>Designation</label>
                                            <InputBox type="text" name="designation" value={data.name} placeholder="Enter Designation" onChange={(e) => setData({ ...data, name: e.target.value })} />
                                        </div>
                                        {validator.message("designation", data.name, "required", {
                                            messages: {
                                                required: "Designation is required"
                                            }
                                        })}
                                    </div>
                                    <div className="designation_tab_heading">
                                        <h6>Tab Access</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    {tabArray?.map((ele) => {
                                        return (
                                            <div className='col-lg-3  col-md-6 mb-2'>
                                                <div className="check_box_wrapper">
                                                    <input type="Checkbox" value={ele} onChange={(e) => handlerchange(e)} checked={data.tab.includes(ele)} />
                                                    <label>{ele}</label>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>

                                <div className="row mt-2">
                                    <div className="designation_tab_heading mt-2">
                                        <h6>Input Field Access</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    {fieldArray?.map((ele) => {
                                        return (

                                            <div className="col-lg-3 col-md-4">
                                                <div className="check_box_wrapper">
                                                    <input type="checkbox" value={ele} onChange={(e) => handlerchange(e, "fields")} checked={data.fields.includes(ele)} />
                                                    <label>{ele}</label>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                                <div className="submit_button_wrapper edit_button">
                                    <FilterButton name="Update" onClick={() => updateDesignation()}></FilterButton>
                                </div>

                            </div>
                        </div>

                    </div>
                </>
            }
        </>

    )
}


export default UpdateDesignation