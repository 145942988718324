import { getServerUrl } from "../../../services/communication";
import React, { useState, useEffect } from "react";
import { io } from "socket.io-client";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import Swal from "sweetalert2";
ChartJS.register(ArcElement, Tooltip, Legend);

const FinalReport = () => {
  const [socket, setSocket] = useState();
  const [graphData, setGraphData] = useState({
    pieChartDatasets: [],
    pieChartLabels: [],
  });
  const data = {
    labels: graphData?.pieChartLabels,
    datasets: [
      {
        label: "% of Votes",
        data: graphData?.pieChartDatasets,
        backgroundColor: ["#3BCD90", "#25D2DC", "#FA6D83"],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
    },
    responsive: true,
  };

  useEffect(() => {
    const socketConnection = io(getServerUrl(), { transports: ["websocket"] });

  //   socketConnection.on("connect_error", (error) => {
  //     Swal.fire({
  //     icon:'warning',
  //     // title:'Socket not connected',
  //     text:'The socket connection has been disconnected'
  //     })
  //     console.log(`connect_error due to ${error.message}`);
  // }
  // );

    setSocket(socketConnection);
    return () => {
      socketConnection.disconnect();
      console.log("disconnect");
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("getElectionReport", (data) => {
        console.log(data);
        setGraphData(data.pieChart);
      });
    }
  }, [socket]);
  return (
    <div className="msi_panel_card_wrapper">
      <div className="title">
        <h5>Final Report</h5>
      </div>
      <div className="final_report_graph_wrapper">
        <div className="final_report_graph">
          <div className="final_report_label_wrapper">
            <div className="label_wrapper">
              <div className="label_color_box"></div>
              <h6>Bjp</h6>
            </div>
            <div className="label_wrapper">
              <div className="label_color_box"></div>
              <h6>Congress</h6>
            </div>
            <div className="label_wrapper">
              <div className="label_color_box"></div>
              <h6>Others</h6>
            </div>
          </div>
          <Pie data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default FinalReport;
