import { createSlice } from '@reduxjs/toolkit'

 const refreshSurveyDropdown = createSlice({
    name: 'loader',
    initialState: {
        refreshState: false,
    },
    reducers: {
        refresh: (state) => {
            state.refreshState = true;
        },
        preventDefault: (state) => {
            state.refreshState = false;
        },
    },
});

export const { refresh, preventDefault } = refreshSurveyDropdown.actions

export default refreshSurveyDropdown.reducer