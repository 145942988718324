import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faSliders, faUser, faXmark } from '@fortawesome/free-solid-svg-icons'
import InputBox from '../common-components/InputBox'
import FilterButton from '../common-components/FilterButton'
import { useNavigate } from 'react-router-dom'
import { communication } from '../services/communication'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showLoader } from '../redux-store/loaderReducer'


const UserProfile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(true);
    const id = useSelector((state) => state.userDetailReducer.id);
    const [data, setData] = useState({
        name: "",
        address: "",
        mobile: "",
        altMobile: "",
        city: "",
        designationName: "",
        boothAccess: []

    })

    const [pageNumberArray, setPageNumberArray] = useState();

    // update profile
    const updateOrganizerProfile = async () => {
        try {
            dispatch(showLoader());
            const dataTosend = {
                organizerId: data._id,
                name: data.name,
                address: data.address,
                mobile: data.mobile,
                altMobile: data.altMobile,
                city: data.city,
                designation: data.designation,
            }
            const responseFromServer = await communication.updateOrganizerProfile(dataTosend);
            if (responseFromServer?.data?.status === "SUCCESS") {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "success" });
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" })
                navigate("/login");
            } else {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" })
            } dispatch(hideLoader());
        } catch (error) {
            dispatch(hideLoader())
            Swal.fire({ text: error?.responseFromServer?.data?.message || error?.message, icon: "error" })
        }

    }
    // get organizer by id
    const getOrganizerById = async () => {
        try {
            dispatch(showLoader());
            const responseFromServer = await communication.getOrganizerDetailByID(id);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setData(responseFromServer.data.isOrganizerExist)
                const pageArray = responseFromServer?.data?.isOrganizerExist?.boothAccess?.map((ele) => { return ele?.page });
                setPageNumberArray(pageArray);
                dispatch(hideLoader());
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" })
                navigate("/login");
            } else {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" })
                setData({})
            }
            dispatch(hideLoader());
        }
        catch (error) {
            dispatch(hideLoader())
            Swal.fire({ text: error?.responseFromServer?.data?.message || error?.message, icon: "error" })
        }
    }

    useEffect(() => {
        getOrganizerById();
    }, []);

    return (
        <div className="profile_wrapper">
            <div className="profile_card">
                <div className="profile_header">
                    <div className="profile_div">
                        <div className="d-flex div_profile1">
                            <div className="profile_icon">
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                            <div className="profile_title">
                                <span>User Profile</span>
                            </div>
                        </div>
                        <div className="div_profile2">
                            <div className="profile_icon" onClick={() => navigate(-1)}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>
                    </div>


                </div>
                <div className="profile_body">

                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-4">
                            <div className="input_box_wrapper">
                                <label>Full Name</label>
                                <InputBox type="text" name="fname" value={data.name} placeholder="Enter Full Name" disable={disable} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4">
                            <div className="input_box_wrapper">
                                <label>Mobile Number</label>
                                <InputBox type="number" name="mobileNumber" value={data.mobile} placeholder="Enter Mobile Number" disable={disable} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4">
                            <div className="input_box_wrapper">
                                <label>Alternate Number</label>
                                <InputBox type="number" name="alternateNumber" value={data.altMobile} placeholder="Enter Alternate Number" disable={disable} />
                            </div>
                        </div>

                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-4 col-md-4 col-4">
                            <div className="input_box_wrapper">
                                <label>Village/City</label>
                                <InputBox type="text" name="villageCity" value={data.city} placeholder="Enter Village/City" disable={disable} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4">
                            <div className="input_box_wrapper">
                                <label>Designation</label>
                                <InputBox type="text" name="designation" value={data.designationName} placeholder="Enter Designation" disable />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4">
                            <div className="input_box_wrapper">
                                <label>Voter ID</label>
                                <InputBox type="text" name="voterId" value={data.voterId} placeholder="Enter Voter Id" disable />
                            </div>
                        </div>
                    </div>

                    {data.designationName.toLowerCase() !== "admin" &&
                        <div className="row mt-2">
                            <div className="booth_access_wrapper">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="input_box_wrapper">
                                            <label>Booth Name</label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input_box_wrapper">
                                            <label>Booth Number</label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input_box_wrapper">
                                            <label>Page Number</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {data?.boothAccess?.map((boothDetail, index) => {
                                return (
                                    <div className="booth_access_wrapper" key={index}>
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="input_box_wrapper">
                                                    <input type="text" name="booth" value={boothDetail?.booth} className="form-control" disabled />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_box_wrapper">
                                                    <input type="text" name="boothNo" value={boothDetail?.boothNo} className="form-control" disabled />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                {(boothDetail?.page.length > 0) &&
                                                    <div className="input_box_wrapper">
                                                        <input type="text" name="page" value={boothDetail?.page?.toString()} className="form-control" disabled />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    }

                    <div className="row mt-2">
                        <div className="col-lg-6 col-md-4 col-4">
                            <label>Address</label>
                            <textarea type="textarea" className="form-control" value={data.address} placeholder="Address" disabled={disable} style={{ width: '100%', height: '150px' }} />
                        </div>
                        <div className="submit_button_wrapper edit_button">
                            {disable ? <FilterButton name="Edit" icon={faPenToSquare} onClick={() => setDisable(false)}> </FilterButton>
                                :
                                <FilterButton name="Update" onClick={() => updateOrganizerProfile()}></FilterButton>}
                        </div>
                    </div>



                </div>


            </div>
        </div>
    )
}

export default UserProfile