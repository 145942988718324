import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

let decodeValue = null;
//**********************Helper Function ********************//
(function getcokkies() {
    // Split cookie string and get all individual valueName=value pairs in an array
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        // Decode the cookie value and return
        if ("victoryToken" === cookiePair[0].trim()) {
            decodeValue = jwtDecode(cookiePair[1]);
            return decodeValue;
        }
    }
})();
//**********************Helper Function ********************//

const userDetailReducer = createSlice({
    name: "userDetails",
    initialState: {
        userId: decodeValue?.userId ?? "",
        id: decodeValue?.id ?? "",
        tabs: decodeValue?.tabs ?? [],
        designation: decodeValue?.designationName ?? ""
    },
    reducers: {
        addUserDetails: (state, action) => {
            state.userId = action?.payload?.userDetails?.userId;
            state.id = action?.payload?.userDetails?.id;
            state.tabs = action?.payload?.userDetails?.tabs;
            state.designation = action?.payload?.userDetails?.designationName;
            document.cookie = `victoryToken=${action.payload.token};path=/`;
        },
        removeUserDetails: (state, action) => {
            state.userId = "";
            state.id = "";
            document.cookie = "victoryToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },
    },
});

export const { addUserDetails, removeUserDetails } = userDetailReducer.actions;
export default userDetailReducer.reducer;