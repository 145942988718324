import React from 'react'

const Loader = () => {
    return (
        <div className="conatiner_loader">
            <div className="laoder_wrapper">
                <div className="loader">
                    <div className="dot"></div>
                </div>
                <div className="loader">
                    <div className="dot"></div>
                </div>
                <div className="loader">
                    <div className="dot"></div>
                </div>
                <div className="loader">
                    <div className="dot"></div>
                </div>
                <div className="loader">
                    <div className="dot"></div>
                </div>
                <div className="loader">
                    <div className="dot"></div>
                </div>
            </div>
            <div className="loader_text"> Please wait</div>
        </div>

    )
}

export default Loader