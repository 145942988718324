import React, { useState, useEffect } from "react";
import BoothTable from "./BoothTable";
import BoothReportGraph from "./BoothReportGraph";
import FinalReport from "./FinalReport";
import VotingAreasGraph from "./VotingAreasGraph";
import { communication, getServerUrl } from "../../../services/communication";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { hideLoader, showLoader } from "../../../redux-store/loaderReducer";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../../utilities/Loader";

const MISPanel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [electionList, setElectionList] = useState([]);
  const [electionId, setElectionId] = useState("");
  const [socket, setSocket] = useState();
  const [isElectionStarted, setIsElectionStarted] = useState(false);

  //get all election list
  const fetchElectionList = async () => {
    try {
      const responseFromServer = await communication.getAllElectionList();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setElectionList(responseFromServer?.data?.election);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setElectionList([]);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    fetchElectionList();
  }, []);

  useEffect(() => {
    const socketConnection = io(getServerUrl(), { transports: ["websocket"] });
    setSocket(socketConnection);
    return () => {
      socketConnection.disconnect();
      console.log("disconnect");
    };
  }, []);
  useEffect(() => {
    dispatch(showLoader());
    if (socket) {
      socket.on("getElectionReport", (data) => {
        console.log("data", data);
        if (typeof data === "object") {
          setIsElectionStarted(true);
          setElectionId(data?.id)
        }
      });
      dispatch(hideLoader());
    }
  }, [socket]);

  return (
    <>
      {loader === true ?
        <Loader />
        :
        <>
          <div className="top_header">
            <div className="title">Voting Day</div>
            <div className="button_wrapper">
              {/*<select
                onChange={(event) => { setElectionId(event.target.value); fetchElectionList(event.target.value) }}
                className="form-select"
              >
                <option value="">Select Election</option>
                {electionList?.map((election, index) => {
                  return (
                    <option
                      value={election?._id}
                      selected={election?._id === electionId}
                      key={index}
                    >
                      {election?.name}
                    </option>
                  );
                })}
              </select>*/}
            </div>
          </div>
          <section>
            {isElectionStarted === true ? (
              <div className="row msi_panel_wrapper">
                <div className="col-lg-6 pb-lg-1 pe-lg-1">
                  <BoothTable />
                </div>
                <div className="col-lg-6 pb-lg-1 ps-lg-1">
                  <VotingAreasGraph />
                </div>
                <div className="col-lg-6 pt-1 pb-lg-0 pe-lg-1">
                  <BoothReportGraph />
                </div>
                <div className="col-lg-6 pt-1 pb-lg-0 ps-lg-1">
                  <FinalReport />
                </div>
              </div>
            ) : (
              <div className="data_not_available_wrapper">Election not started</div>
            )}
          </section>
        </>
      }
    </>
  );
};

export default MISPanel;
