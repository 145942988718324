import React from 'react'
import { NavLink } from 'react-router-dom'

const VotingDayDropdown = () => {
    return (
        <>
            <NavLink to="/dashboard/voting-day/onfield-input">
                <div className="dropdown_tab">
                    <div className="title"><span>OnField Input</span></div>
                </div>
            </NavLink>
            <NavLink to="/dashboard/voting-day/msi-panel">
                <div className="dropdown_tab">
                    <div className="title"><span>MSI Panel</span></div>
                </div>
            </NavLink>
        </>
    )
}

export default VotingDayDropdown