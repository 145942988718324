export const tabArray =["Dashboard", 
"Organization Data",
"Election Management",
"Designation Management",
"Voter Data",
"Activity Management",
"Beneficial List",
"Confirm Voters", 
"Mobile Access",
"Survey Management",
"Data Analysis",
"Voting Day",
"Download Templates"]