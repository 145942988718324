import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { io } from "socket.io-client";
import { getServerUrl } from "../../../services/communication";
import Swal from "sweetalert2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
ChartJS.defaults.set("plugins.datalabels", {
  color: "#FFF",
});

const BoothReportGraph = () => {
  const [socket, setSocket] = useState();
  const [graphData, setGraphData] = useState({
    datasets: [],
    labels: [],
  });

  const data = {
    labels: graphData?.labels,
    datasets: graphData?.datasets,
    plugins: [ChartDataLabels],
  };

  const options = {
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: { size: 12 },
        },
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  useEffect(() => {
    const socketConnection = io(getServerUrl(), { transports: ["websocket"] });

   

    setSocket(socketConnection);
    return () => {
      socketConnection.disconnect();
      console.log("disconnect");
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("getElectionReport", (data) => {
        console.log(data);
        if (typeof data === "object") {
          setGraphData(data.boothWiseGraph);
        }
      });
    }
  }, [socket]);

  return (
    <div className="msi_panel_card_wrapper">
      <div className="title">
        <h5>Booth Report</h5>
      </div>
      <div className="booth_report_graph_wrapper">
        <div className="booth_report_graph">
          <Bar options={options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default BoothReportGraph;
