import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faReply, faUnlock, faUser } from '@fortawesome/free-solid-svg-icons'
import { NavLink, useNavigate } from 'react-router-dom'
import useValidator from '../utilities/useValidator'
import Swal from 'sweetalert2'
import { communication } from '../services/communication'

const ResetPassword = () => {
  const navigate = useNavigate();
  const [validator, showValidationMessage] = useValidator();
  const [togglePassword, setTogglePassword] = useState({ oldPassword: false, newPassword: false, confirmPassword: false });
  const [loginDetail, setLoginDetail] = useState(
    {
      userId: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    }
  );

  // Reset Password
  const resetPassword = async () => {
    try {
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false
      }
      const dataToSend = {
        userId: loginDetail.userId,
        oldPassword: loginDetail.oldPassword,
        newPassword: loginDetail.newPassword
      }
      const serverResponse = await communication.resetPassword(dataToSend);

      if (serverResponse?.data?.status === "SUCCESS") {
        Swal.fire({ text: serverResponse?.data?.message, icon: "success" });
        navigate("/login");
      } else {
        Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
      }
    } catch (error) {
      Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
    }
  }

  return (
    <div className="login_wrapper">
      <div className="row card_wrapper">
        <div className="col-lg-6 col-md-6 col-12 p-0">
          <div className="card_img">
            <img src="/images/sign-in-img.png" alt="sign-in-img" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12 form_wrapper">
          <div className="form">
            <div className="title"><h1>Reset Password</h1></div>
            <div className="row">
              <div className="col-12">
                <div className="input_wrapper">
                  <input type="text" name="userId" onChange={(event) => setLoginDetail({ ...loginDetail, userId: event.target.value })} className="form-control" placeholder="Enter User ID" autoComplete="off" />
                  <FontAwesomeIcon icon={faUser} />
                </div>
                {validator.message("userId", loginDetail.userId, "required", {
                  messages: {
                    required: "User ID is required"
                  }
                })}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input_wrapper">
                  <input type={togglePassword.oldPassword ? "text" : "password"} name="oldPassword" onChange={(event) => setLoginDetail({ ...loginDetail, oldPassword: event.target.value })} className="form-control" placeholder="Enter Old Password" autoComplete="off" />
                  <FontAwesomeIcon icon={!togglePassword.oldPassword ? faLock : faUnlock} onClick={() => setTogglePassword({ ...togglePassword, oldPassword: !togglePassword.oldPassword })} />
                </div>
                {validator.message("oldPassword", loginDetail.oldPassword, "required|min:8|max:16", {
                  messages: {
                    required: "Old Password is required"
                  }
                })}
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="input_wrapper">
                  <input type={togglePassword.newPassword ? "text" : "password"} name="newPassword" onChange={(event) => setLoginDetail({ ...loginDetail, newPassword: event.target.value })} className="form-control" placeholder="Enter New Password" autoComplete="off" />
                  <FontAwesomeIcon icon={!togglePassword.newPassword ? faLock : faUnlock} onClick={() => setTogglePassword({ ...togglePassword, newPassword: !togglePassword.newPassword })} />
                </div>
                {validator.message("newPassword", loginDetail.newPassword, "required|min:8|max:16", {
                  messages: {
                    required: "New Password is required"
                  }
                })}
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="input_wrapper">
                  <input type={togglePassword.confirmPassword ? "text" : "password"} name="confirmPassword" onChange={(event) => setLoginDetail({ ...loginDetail, confirmPassword: event.target.value })} className="form-control" placeholder="Confirm Password" autoComplete="off" />
                  <FontAwesomeIcon icon={!togglePassword.confirmPassword ? faLock : faUnlock} onClick={() => setTogglePassword({ ...togglePassword, confirmPassword: !togglePassword.confirmPassword })} />
                </div>
                {validator.message("confirmPassword", loginDetail.confirmPassword, `required|in:${loginDetail.newPassword}`, {
                  messages: {
                    required: "Confirm Password is required",
                    in: "Password must be Match"
                  }
                })}
              </div>
            </div>

            <button className="button" onClick={() => resetPassword()}>Reset</button>
            <div className="backto_login_wrapper">
              <NavLink to="/login">
                <div className="back_icon">
                  <FontAwesomeIcon icon={faReply} />
                </div>
                <h6>Back to Login</h6>
              </NavLink></div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default ResetPassword