import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import Login from '../pages/Login'
import ResetPassword from '../pages/ResetPassword'
import DashboardLayout from '../common-components/DashboardLayout'
import CurrentProgress from '../pages/dashboard/current-progress/CurrentProgress'
import CategoryWiseVoters from '../pages/dashboard/CategoryWiseVoters'
import AgeWiseVoters from '../pages/dashboard/AgeWiseVoters'
import OccupationWiseVoters from '../pages/dashboard/OccupationWiseVoters'
import ImmediateFocus from '../pages/dashboard/immediate-focus/ImmediateFocus'
import ElectionManagement from '../pages/election-management/ElectionList'
import DesignationList from '../pages/designation-management/DesignationList'
import OrganizerList from '../pages/organization-management/OrganizerList'
import AddOrganizer from '../pages/organization-management/AddOrganizer'
import UpdateOrganizer from '../pages/organization-management/UpdateOrganizer'
import VoterList from '../pages/voter-data/VoterList'
import BeneficialVoterList from '../pages/beneficial-voters/BeneficialVoterList'
import ConfirmVotersList from '../pages/confirm-voters/ConfirmVotersList'
import SurveyList from '../pages/survey-management/survey-list/SurveyList'
import SurveyData from '../pages/survey-management/SurveyData'
import SurveyTrends from '../pages/data-analysis/SurveyTrends'
import SurveyAnalysisData from '../pages/data-analysis/SurveyAnalysisData'
import OnFieldInput from '../pages/voting-day/onfield-input/OnFieldInput'
import MSIPanel from '../pages/voting-day/msi-panel/MISPanel'
import ActivityList from '../pages/activity-management/ActivityList'
import DownloadTemplates from '../pages/download-template/DownloadTemplates'
import UserProfile from '../pages/UserProfile'
import AddNewDesignation from '../pages/designation-management/AddNewDesignation'
import UpdateDesignation from '../pages/designation-management/UpdateDesignation'
import AddNewVoter from '../pages/voter-data/AddNewVoter'
import UpdateVoter from '../pages/voter-data/UpdateVoter'
import {  getServerUrl } from '../services/communication'
import BoothVoterList from '../pages/voting-day/msi-panel/BoothVoterList'
import { PrivateRoute } from './PrivateRoute'
import ImmediateFocusVoterList from '../pages/dashboard/immediate-focus/ImmediateFocusVoterList'
import { useSelector } from 'react-redux'
import { io } from "socket.io-client";


const MainRoutes = () => {
    const navigate = useNavigate();
    const userId = useSelector((state) => state.userDetailReducer.userId);
    const [tabArray, setTabArray] = useState([]);
    const [socket, setSocket] = useState();

    useEffect(() => {
        const socketConnection = io(getServerUrl(), { transports: ["websocket"] });
        setSocket(socketConnection);
        return () => {
            socketConnection.disconnect();
            console.log("disconnect");
        };
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on("surveyTab", (data) => {
                setTabArray(data)
            })
        }
    }, [socket])

    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/dashboard" element={<PrivateRoute><DashboardLayout /></PrivateRoute>}>
                <Route path="/dashboard" element={<Navigate to="/dashboard/reports/current-progress" />} />
                <Route path="/dashboard/reports/current-progress" element={<CurrentProgress />} />
                <Route path="/dashboard/reports/category-wise-voters" element={<CategoryWiseVoters />} />
                <Route path="/dashboard/reports/age-wise-voters" element={<AgeWiseVoters />} />
                <Route path="/dashboard/reports/occupation-wise-voters" element={<OccupationWiseVoters />} />
                <Route path="/dashboard/reports/immediate-focus" element={<ImmediateFocus />} />
                <Route path="/dashboard/reports/immediate-focus/voter-list" element={<ImmediateFocusVoterList />} />
                <Route path="/dashboard/election-management" element={<ElectionManagement />} />
                <Route path="/dashboard/designation-management" element={<DesignationList />} />
                <Route path="/dashboard/designation-management/add-designation" element={<AddNewDesignation />} />
                <Route path="/dashboard/designation-management/update-designation" element={<UpdateDesignation />} />
                <Route path="/dashboard/organization-management" element={<OrganizerList />} />
                <Route path="/dashboard/organization-management/add-organizer" element={<AddOrganizer />} />
                <Route path="/dashboard/organization-management/update-organizer" element={<UpdateOrganizer />} />
                <Route path="/dashboard/voter-data" element={<VoterList />} />
                <Route path="/dashboard/voter-data/add-voter" element={<AddNewVoter />} />
                <Route path="/dashboard/voter-data/update-vote" element={<UpdateVoter />} />
                <Route path="/dashboard/beneficial-voters" element={<BeneficialVoterList />} />
                <Route path="/dashboard/confirmed-voters" element={<ConfirmVotersList />} />
                <Route path="/dashboard/survey-management/survey-list" element={<SurveyList />} />
                {tabArray?.map((tabs, index) => {
                    return (
                        <Route path={`/dashboard/survey-management/survey-data${index + 1}`} key={index} element={<SurveyData />} />
                    );
                })}
                <Route path="/dashboard/data-analysis/survey-trends" element={<SurveyTrends />} />
                <Route path="/dashboard/data-analysis/survey-data" element={<SurveyAnalysisData />} />
                <Route path="/dashboard/voting-day/onfield-input" element={<OnFieldInput />} />
                <Route path="/dashboard/voting-day/msi-panel" element={<MSIPanel />} />
                <Route path="/dashboard/voting-day/msi-panel/booth-voter-list" element={<BoothVoterList />} />
                <Route path="/dashboard/activity-management/activity-list" element={<ActivityList />} />
                <Route path="/dashboard/templates" element={<DownloadTemplates />} />
                <Route path="/dashboard/profile" element={<UserProfile />} />
            </Route>
        </Routes>
    )
}

export default MainRoutes