import React, { useEffect, useState } from 'react'
import FilterButton from '../../common-components/FilterButton'
import InputBox from '../../common-components/InputBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import useValidator from '../../utilities/useValidator'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showLoader } from '../../redux-store/loaderReducer'
import { communication } from '../../services/communication'
import Loader from '../../utilities/Loader'

const AddOrganizer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [validator, showValidationMessage] = useValidator();
  const [accessValidator, showAccessValidationMessage] = useValidator();
  const [designationList, setDesignationList] = useState([]);
  const [organizerData, setOrganizerData] = useState(
    {
      name: "",
      mobile: "",
      altMobile: "",
      address: "",
      voterId: "",
      city: "",
      designationId: "",
      password: "",
      confirmPassword: ""
    }
  );

  const [boothData, setBoothData] = useState(
    {
      booth: "",
      boothNo: "",
      page: []
    }
  )

  const [boothAccess, setBoothAccess] = useState([]);

  //add access
  const addAccess = () => {
    try {
      if (!accessValidator.allValid()) {
        showAccessValidationMessage(true);
        return false
      }
      setBoothAccess([...boothAccess, boothData]);
      setBoothData({ booth: "", boothNo: "", page: [] });
    } catch (error) {
      Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
    }
  }

  //delete booth access
  const deleteBoothAccess = (index) => {
    const newBoothArray = boothAccess?.filter((ele, id) => index !== id);
    setBoothAccess(newBoothArray);
  }

  //get all designation list
  const fetchDesignationList = async () => {
    try {
      const responseFromServer = await communication.getAllDesignations();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setDesignationList(responseFromServer?.data?.designation);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setDesignationList([]);
      }
    } catch (error) {
      Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
    }
  }

  //add organizer
  const addOrganizer = async () => {
    try {
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false
      }
      if (boothAccess?.length === 0) {
        Swal.fire({ text: "Add at least one booth access", icon: "warning" });
        return false;
      }
      const dataToSend = {
        name: organizerData.name,
        mobile: Number(organizerData.mobile),
        altMobile: Number(organizerData.altMobile),
        address: organizerData.address,
        voterId: organizerData.voterId,
        city: organizerData.city,
        designationId: organizerData.designationId,
        password: organizerData.password,
        boothAccess
      }
      dispatch(showLoader());
      const serverResponse = await communication.addOrganizer(dataToSend);
      if (serverResponse?.data?.status === "SUCCESS") {
        Swal.fire({ text: serverResponse?.data?.message, icon: "success" });
        navigate(-1);
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
    }
  }

  useEffect(() => {
    fetchDesignationList();
  }, []);

  return (
    <>
      {loader === true ?
        <Loader />
        :
        <>
          <div className="top_header">
            <div className="title">Add Organizer</div>
          </div>
          <section>
            <div className="form_wrapper_main">
              <div className="card_wrapper">
                <div className="card_header">
                  <div className="title">
                    <h5>Add Organizer</h5>
                  </div>
                  <div className="close_button" onClick={() => navigate("/dashboard/organization-management")}>
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </div>
                <div className="card_body">
                  <div className="row">
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Name</label>
                        <InputBox type="text" name="name" onChange={(event) => setOrganizerData({ ...organizerData, name: event.target.value })} placeholder="Enter Full Name" />
                        {validator.message("name", organizerData.name, "required", {
                          messages: {
                            required: "Full Name is required"
                          }
                        })}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Mobile Number</label>
                        <InputBox type="number" name="mobile" onChange={(event) => setOrganizerData({ ...organizerData, mobile: event.target.value })} placeholder="Enter Mobile Number" />
                        {validator.message("mobile", organizerData.mobile, "required|numeric|min:10|max:10", {
                          messages: {
                            required: "Mobile Number is required"
                          }
                        })}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Alternate Number</label>
                        <InputBox type="number" name="altMobile" onChange={(event) => setOrganizerData({ ...organizerData, altMobile: event.target.value })} placeholder="Enter Alternate Number" />
                        {validator.message("mobile", organizerData.altMobile, "numeric|min:10|max:10", {
                          messages: {
                            required: "Alternate Number is required"
                          }
                        })}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Village/City</label>
                        <InputBox type="text" name="city" onChange={(event) => setOrganizerData({ ...organizerData, city: event.target.value })} placeholder="Enter Village/City " />
                        {validator.message("city", organizerData.city, "required", {
                          messages: {
                            required: "City is required"
                          }
                        })}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Voter ID</label>
                        <InputBox type="text" name="voterId" onChange={(event) => setOrganizerData({ ...organizerData, voterId: event.target.value })} placeholder="Enter Voter Id" />
                        {validator.message("voterId", organizerData.voterId, "required", {
                          messages: {
                            required: "Voter ID is required"
                          }
                        })}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Designation</label>
                        <select name="designationId" onChange={(event) => setOrganizerData({ ...organizerData, designationId: event.target.value })} className="form-select designation_select_box">
                          <option value="">Select Designation</option>
                          {designationList?.map((designation, index) => {
                            return (
                              <option value={designation?._id} key={index}>{designation?.name}</option>
                            );
                          })}
                        </select>
                        {validator.message("designationId", organizerData.designationId, "required", {
                          messages: {
                            required: "Designation is required"
                          }
                        })}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Password</label>
                        <InputBox type="password" name="password" onChange={(event) => setOrganizerData({ ...organizerData, password: event.target.value })} placeholder="Enter Password" />
                        {validator.message("password", organizerData.password, "required|min:8|max:16", {
                          messages: {
                            required: "Password is required"
                          }
                        })}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Confirm Password</label>
                        <InputBox type="password" name="confirmPassword" onChange={(event) => setOrganizerData({ ...organizerData, confirmPassword: event.target.value })} placeholder="Enter Confirm Password" />
                        {validator.message("confirmPassword", organizerData.confirmPassword, `required|in:${organizerData.password}`, {
                          messages: {
                            required: "Confirm Password is required",
                            in: "Password must match"
                          }
                        })}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Address</label>
                        <textarea className="form-control" placeholder="Enter Address" onChange={(event) => setOrganizerData({ ...organizerData, address: event.target.value })}></textarea>
                        {validator.message("address", organizerData.address, "required", {
                          messages: {
                            required: "Address is required"
                          }
                        })}
                      </div>
                    </div>

                  </div>
                  <div className="booth_access_wrapper">
                    <div className="row">
                      <div className="col-3">
                        <div className="input_box_wrapper">
                          <label>Booth Name</label>
                          <InputBox type="text" name="booth" value={boothData.booth} onChange={(event) => setBoothData({ ...boothData, booth: event.target.value })} placeholder="Enter Booth Name" />
                          {accessValidator.message("booth", boothData.booth, "required", {
                            messages: {
                              required: "Booth Name is required"
                            }
                          })}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="input_box_wrapper">
                          <label>Booth Number</label>
                          <InputBox type="text" name="boothNo" value={boothData.boothNo} onChange={(event) => setBoothData({ ...boothData, boothNo: event.target.value })} placeholder="Enter Booth Number" />
                          {accessValidator.message("boothNo", boothData.boothNo, "required", {
                            messages: {
                              required: "Booth Number is required"
                            }
                          })}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="input_box_wrapper">
                          <label>Page Number</label>
                          <InputBox type="text" name="page" value={boothData.page} onChange={(event) => setBoothData({ ...boothData, page: event.target.value.split(",") })} placeholder="Enter page Number ex: 1,2,3" />
                          {accessValidator.message("page", boothData.page, "required", {
                            messages: {
                              required: "Page Number is required"
                            }
                          })}
                        </div>
                      </div>
                    </div>
                    <button className="add_access_button" onClick={() => addAccess()}>Add Access</button>
                  </div>
                  {boothAccess?.map((boothDetail, index) => {
                    return (
                      <div className="booth_access_wrapper" key={index}>
                        <div className="row">
                          <div className="col-3">
                            <div className="input_box_wrapper">
                              <input type="text" name="booth" value={boothDetail?.booth} className="form-control" disabled />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="input_box_wrapper">
                              <input type="text" name="boothNo" value={boothDetail?.boothNo} className="form-control" disabled />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="input_box_wrapper">
                              <input type="text" name="page" value={boothDetail?.page?.map((pageNumber) => {
                                return (
                                  pageNumber?.length > 1 ? `${pageNumber},` : pageNumber
                                );
                              })} className="form-control" disabled />
                            </div>
                          </div>
                        </div>
                        <FontAwesomeIcon icon={faTrash} className="trash_button" onClick={() => deleteBoothAccess(index)} />
                      </div>
                    );
                  })}

                  <div className="submit_button_wrapper">
                    <FilterButton name="Add" onClick={() => addOrganizer()} />
                  </div>
                </div>
              </div>
            </div>
          </section>

        </>
      }
    </>
  )
}

export default AddOrganizer