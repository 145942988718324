import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { communication } from '../../services/communication';
import Swal from 'sweetalert2';
import Loader from '../../utilities/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { toggleModal } from '../../redux-store/modalReducer';
import { hideLoader, showLoader } from '../../redux-store/loaderReducer';

const VoterBenefits = ({ voterId, electionId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const loader = useSelector((state) => state.loaderReducer.loaderState);
    const pageLimit = Number(process.env.REACT_APP_PAGE_LIMIT);
    const [benefitVoter, setBenifitVoter] = useState([]);

    // get benefits to voter
    const fetchBenifitsVoter = async () => {
        try {
            const responseFromServer = await communication.getBenifitsVoterList(voterId, electionId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(showLoader());
                setBenifitVoter(responseFromServer?.data?.benefits);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
        }
            dispatch(hideLoader());
        } catch (error) {
            Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" })

        }
    }

    useEffect(() => {
        fetchBenifitsVoter();
    }, []);

    return (
        <>
            {loader === true ?
                <Loader />
                :
                <div className="modal_wrapper">
                    <div className="card_wrapper aid_card_wrapper">
                        <div className="card_header">
                            <div className="title">
                                <h5>AID/Help Given</h5>
                            </div>
                            <div className="close_button" onClick={() => dispatch(toggleModal(""))}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>
                        <div className="card_body">
                            <div className="table_wrapper">
                                <div className="table">
                                    <div className="table_header">
                                        <div className="col-2">
                                            <h5>Sr. No</h5>
                                        </div>
                                        <div className="col-3">
                                            <h5>Survey</h5>
                                        </div>
                                        <div className="col-3">
                                            <h5>AID/Help Given</h5>
                                        </div>
                                        <div className="col-4">
                                            <h5>Remark</h5>
                                        </div>
                                    </div>
                                    {benefitVoter?.length > 0 ?
                                        <>
                                            {benefitVoter?.map((benefitsList, index) => {
                                                return (

                                                    <div className="table_data" key={index}>
                                                        <div className="col-2">
                                                            <h6>{Number(pageLimit) * currentPage + (index + 1)}</h6>
                                                        </div>
                                                        <div className="col-3">
                                                            <h6>{benefitsList?.surveyName}</h6>
                                                        </div>
                                                        <div className="col-3">
                                                            {/*{benefitsList?.aid?.map((aidHelp, index) => {

                                                                if ((index + 1) === benefitsList?.aid?.length) {
                                                                    return <h6>{aidHelp}</h6>
                                                                } else {
                                                                    return <h6>{aidHelp},</h6>
                                                                }
                                                            })}*/}
                                                            <h6>{benefitsList?.aid?.toString()}</h6> 
                                                        </div>
                                                        <div className="col-4">
                                                            <h6>{benefitsList?.remark}</h6>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                        :
                                        <div className="data_not_available_wrapper">
                                            Benefits Not Available
                                        </div>

                                    }

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            }
        </>
    )
}

export default VoterBenefits