import "./css/style.css"
import "./css/mediaQuery.css"
import MainRoutes from "./routes/MainRoutes";

function App() {
  return (
    <div className="App">
      <MainRoutes />
    </div>
  );
}

export default App;
