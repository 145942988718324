import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faEye,
  faEyeSlash,
  faFileExport,
  faFileImport,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../common-components/Button";
import FilterButton from "../../common-components/FilterButton";
import { hideLoader, showLoader } from "../../redux-store/loaderReducer";
import Swal from "sweetalert2";
import { communication } from "../../services/communication";
import Loader from "../../utilities/Loader";
import { toggleModal } from "../../redux-store/modalReducer";
import OrganizerFilter from "./OrganizerFilter";
import ImportData from "../../common-components/ImportData";
import FileSaver from "file-saver";
import Pagination from "../../common-components/Pagination";

const OrganizerList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useSelector((state) => state.modalReducer.modal);
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const pageLimit = Number(process.env.REACT_APP_PAGE_LIMIT);
  const [toggleSearchInput, SetToggleSearchInput] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isPageUpdated, setIsPageUpdated] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState(true);
  const [organizerList, setOrganizerList] = useState([]);
  const [organizerId, setOrganizerId] = useState();
  const [togglePassword, setTogglePassword] = useState(false);
  const [filterDetail, setFilterDetail] = useState({
    city: "",
    designationId: "",
    voterId: "",
  });

  //State to handle index numbers of list
  const [page, setPage] = useState();

  const handlePageClick = (e) => {
    if (currentPage > pageCount) {
      Swal.fire({
        text: "Entered Page Cannot be grater than total Page",
        icon: "warning",
      });
      return false;
    } else {
      if (searchValue) {
        getOrganizerListBySearch(
          searchValue,
          currentPage,
          filterDetail.city,
          filterDetail.designationId,
          filterDetail.voterId
        );
      } else {
        fetchOrganizerList(
          searchValue,
          currentPage,
          filterDetail.city,
          filterDetail.designationId,
          filterDetail.voterId
        );
      }
    }
  };

  //get organizer list by search
  const getOrganizerListBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await communication.getOrganizerList(
        searchString,
        page,
        filterDetail.city,
        filterDetail.designationId,
        filterDetail.voterId
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        setOrganizerList(responseFromServer?.data?.organizers);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(page);
        // setSearchValue();
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setOrganizerList([]);
        setPageCount(0);
      }
      dispatch(toggleModal(""));
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    } finally {
      dispatch(toggleModal(""));
    }
  };

  //Fetch organizer list on initial load
  const fetchOrganizerList = async (
    searchString,
    page,
    city,
    designationId,
    voterId
  ) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await communication.getOrganizerList(
        searchString,
        page,
        city,
        designationId,
        voterId
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setOrganizerList(responseFromServer?.data?.organizers);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(page);
        setSearchValue("");
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        dispatch(hideLoader());
        setOrganizerList([]);
        setPageCount(0);
        //Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //Export Organization data
  const exportOrganizationData = async () => {
    try {
      Swal.fire({
        // title: "Are you sure?",
        text: "Are you sure you want to export Organization Data?",
        showCancelButton: true,
        confirmButtonColor: "#38CAB3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async function (isConfirm) {
        if (isConfirm.isConfirmed) {
          dispatch(showLoader());
          const serverResponse = await communication.exportOrganizations(
            searchValue,
            currentPage,
            filterDetail.city,
            filterDetail.designationId,
            filterDetail.voterId
          );
          if (serverResponse.data) {
            //Create a Blob from the PDF Stream
            const file = new Blob([serverResponse.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(file, "OrganizerData.xlsx");
          } else {
            Swal.fire({
              text: "Failed to export data in excel",
              icon: "warning",
            });
          }
          dispatch(hideLoader());
        } else {
          return;
        }
      });
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({ text: error.message, icon: "warning" });
    }
  };

  //Change organizer status
  const changeOrganizerStatus = (status, organizerId) => {
    try {
      Swal.fire({
        text: `Are you sure you want to ${status ? "Active" : "InActive"} ?`,
        showCancelButton: true,
        confirmButtonColor: "#38CAB3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async function (isConfirm) {
        if (isConfirm.isConfirmed) {
          dispatch(showLoader());
          const responseFromServer = await communication.changeOrganizerStatus(
            status,
            organizerId
          );
          if (responseFromServer?.data?.status === "SUCCESS") {
            Swal.fire({
              text: responseFromServer?.data?.message,
              icon: "success",
            });
            fetchOrganizerList();
          } else if (responseFromServer?.data?.status === "JWT_INVALID") {
            Swal.fire({
              text: responseFromServer?.data?.message,
              icon: "warning",
            });
            navigate("/login");
          } else {
            Swal.fire({
              text: responseFromServer?.data?.message,
              icon: "warning",
            });
          }
          dispatch(hideLoader());
        } else {
          return;
        }
      });
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({ text: error.message, icon: "warning" });
    }
  };
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (searchValue?.trim() !== "") {
  //       getOrganizerListBySearch(searchValue?.trim(), 1);
  //     } else {
  //       fetchOrganizerList("", 1);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [searchValue]);

  useEffect(() => {
    if (searchValue.trim() !== "") {
      // If search value is present,we get designation list by search
      getOrganizerListBySearch(searchValue.trim(), 1);
    } else {
      // Otherwise, fetch designation list with empty search string
      fetchOrganizerList("", 1);
    }
  }, [searchValue]); 

  useEffect(() => {
    handlePageClick();
  }, [isPageUpdated]);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">Organizer List</div>
            <div className="search_wrapper">
              <input
                value={searchValue}
                type="search"
                onChange={(event) => {
                  // getOrganizerListBySearch(event.target.value.trim(), 1);
                  setPage(1);
                  setCurrentPage(1);
                  setSearchValue(event.target.value.trim());
                }}
                className="form-control"
                placeholder="search..."
                style={{
                  width: `${toggleSearchInput === false ? "37px" : "250px"}`,
                  borderRadius: `${
                    toggleSearchInput === false ? "50%" : "5px"
                  }`,
                }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() => {
                  SetToggleSearchInput(!toggleSearchInput);
                  setSearchValue("");
                  toggleSearchInput &&
                    searchValue !== "" &&
                    fetchOrganizerList("", 1);
                }}
                style={{
                  left: `${toggleSearchInput === false ? "50" : "90"}%`,
                  padding: "16px",
                }}
              />
            </div>
          </div>
          <section>
            <div className="section_wrapper">
              <div className="section_header_wrapper">
                <Pagination style={{fontsize:"10px"}}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  isPageUpdated={isPageUpdated}
                  setIsPageUpdated={setIsPageUpdated}
                />
                <div className="button_wrapper">
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    onClick={() => {
                      fetchOrganizerList("", currentPage);
                      setPage(currentPage);
                      // setCurrentPage(1);
                      setFilterDetail({});
                      setSearchValue("");
                    }}
                    className="refresh_icon"
                    title="Refresh"
                  />
                  <Button 
                    icon={faFileImport}
                    name="Import"
                    onClick={() => dispatch(toggleModal("import"))}
                  />
                  <Button
                    icon={faFileExport}
                    name="Export"
                    onClick={() => exportOrganizationData()}
                  />
                  <Button
                    icon={faPlus} name="Add Organizer"
                    onClick={() =>
                      navigate(
                        "/dashboard/organization-management/add-organizer"
                      )
                    }
                  />
                  <FilterButton
                    name="Filter"
                    icon={faSliders}
                    onClick={() => dispatch(toggleModal("filter"))}
                  />
                </div>
              </div>
              <div className="table_wrapper">
                <div className="table organization_table">
                  <>
                    <div className="table_header">
                      <div className="serial_number">
                        <h5>Sr. No</h5>
                      </div>
                      <div className="userId">
                        <h5>User ID</h5>
                      </div>
                      <div className="password">
                        <h5>Password</h5>
                      </div>
                      <div className="city">
                        <h5>Village/City</h5>
                      </div>
                      <div className="voterId">
                        <h5>Voter ID</h5>
                      </div>
                      <div className="designation">
                        <h5>Designation</h5>
                      </div>
                      <div className="booth_name">
                        <h5>Booth Name</h5>
                      </div>
                      <div className="page_number">
                        <h5>Page Number</h5>
                      </div>
                      <div className="mobile_number">
                        <h5>Mobile Number</h5>
                      </div>
                      <div className="action">
                        <h5>Action</h5>
                      </div>
                    </div>
                    {organizerList?.length > 0 ? (
                      <>
                        {organizerList?.map((organizerDetail, index) => {
                          return (
                            <div className="table_data" key={index}>
                              <div className="serial_number">
                                <h6>
                                  {Number(pageLimit) * (page - 1) + (index + 1)}
                                </h6>
                              </div>
                              <div className="userId">
                                <h6>{organizerDetail?.userId}</h6>
                              </div>
                              <div className="password">
                                {organizerId === organizerDetail?._id &&
                                togglePassword ? (
                                  <h6>{organizerDetail?.password}</h6>
                                ) : (
                                  <h5>.................</h5>
                                )}
                                <FontAwesomeIcon
                                  icon={
                                    organizerId === organizerDetail?._id &&
                                    togglePassword
                                      ? faEyeSlash
                                      : faEye
                                  }
                                  onClick={() => {
                                    setTogglePassword(!togglePassword);
                                    setOrganizerId(organizerDetail?._id);
                                  }}
                                />
                              </div>
                              <div className="city">
                                <h6>{organizerDetail?.city}</h6>
                              </div>
                              <div className="voterId">
                                <h6>{organizerDetail?.voterId}</h6>
                              </div>
                              <div className="designation">
                                <h6>{organizerDetail?.designation}</h6>
                              </div>
                              <div className="booth_name">
                                <h6>
                                  {organizerDetail?.boothAccess?.map(
                                    (boothArray, index) => {
                                      return organizerDetail?.boothAccess
                                        ?.length ===
                                        index + 1
                                        ? boothArray?.booth
                                        : `${boothArray?.booth},`;
                                    }
                                  )}
                                </h6>
                                {/* <h6>{organizerDetail?.boothAccess?.map((boothArray, index) => boothArray?.booth?.toString() )}</h6> */}
                              </div>
                              <div className="page_number">
                                {/* <h6>{organizerDetail?.boothAccess?.map((boothArray) => {
                                  return (
                                    boothArray?.page?.length === 1 ? boothArray?.page : `${boothArray?.page}, `
                                  );
                                })}</h6> */}

                                <h6>
                                  {organizerDetail?.boothAccess?.map(
                                    (boothArray, index) =>
                                      index ===
                                      organizerDetail?.boothAccess.length - 1
                                        ? `${boothArray?.page?.toString()}`
                                        : `${boothArray?.page?.toString()},`
                                  )}
                                </h6>
                              </div>
                              <div className="mobile_number">
                                <h6>{organizerDetail?.mobile}</h6>
                              </div>
                              <div className="action">
                                <FontAwesomeIcon
                                  icon={faPenToSquare}
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/organization-management/update-organizer?organizerId=${organizerDetail?._id}`
                                    )
                                  }
                                />
                                <div className="form_switch_wrapper">
                                  <div className="form-check form-switch">
                                    <input
                                      type="checkbox"
                                      value={organizerDetail?.isActive}
                                      checked={organizerDetail?.isActive}
                                      onChange={(event) => {
                                        changeOrganizerStatus(
                                          event.target.checked,
                                          organizerDetail?._id
                                        );
                                      }}
                                      className="form-check-input"
                                    />
                                  </div>
                                  <span
                                    style={{
                                      left: `${
                                        organizerDetail?.isActive ? "35" : "63"
                                      }%`,
                                      color: `${
                                        organizerDetail?.isActive
                                          ? "#fff"
                                          : "#38CAB3"
                                      }`,
                                    }}
                                  >
                                    {organizerDetail?.isActive
                                      ? "Active"
                                      : "InActive"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="data_not_available_wrapper">
                        Organizer Not Available
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
            {modal === "filter" && (
              <OrganizerFilter
                filterDetail={filterDetail}
                setFilterDetail={setFilterDetail}
                getOrganizerListBySearch={getOrganizerListBySearch}
                searchString={searchValue}
                setCurrentPage={setCurrentPage}
              />
            )}
            {modal === "import" && (
              <ImportData
                tabName="organizer"
                fetchOrganizerList={fetchOrganizerList}
              />
            )}
          </section>
        </>
      )}
    </>
  );
};

export default OrganizerList;
