import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleModal } from "../../../redux-store/modalReducer";
import Button from "../../../common-components/Button";
import AddSurvey from "../survey-list/AddSurvey";
import { communication } from "../../../services/communication";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../redux-store/loaderReducer";
import Loader from "../../../utilities/Loader";
import UpdateElection from "../../election-management/UpdateElection";
import UpdateSurvey from "./UpdateSurvey";
import Pagination from "../../../common-components/Pagination";

const SurveyList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useSelector((state) => state.modalReducer.modal);
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const pageLimit = Number(process.env.REACT_APP_PAGE_LIMIT);
  const [toggleSearchInput, SetToggleSearchInput] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPageUpdated, setIsPageUpdated] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [surveyList, setSurveyList] = useState([]);
  const [surveyId, setSurveyId] = useState("");

  //State to handle index numbers of list
  const [page, setPage] = useState();

  const handlePageClick = (e) => {
    if (currentPage > pageCount) {
      Swal.fire({
        text: "Entered Page Cannot be grater than total Page",
        icon: "warning",
      });
      return false;
    } else {
      if (searchValue) {
        getSurveyListBySearch(searchValue, currentPage);
      } else {
        fetchSurveyList(searchValue, currentPage);
      }
    }
  };

  //fetch survey list by search
  const getSurveyListBySearch = async (searchString, page) => {
    try {
      if (searchString) {
        const responseFromServer = await communication.getSurveyList(
          searchString,
          page
        );
        if (responseFromServer?.data?.status === "SUCCESS") {
          setSurveyList(responseFromServer?.data?.survey);
          setPageCount(responseFromServer?.data?.totalPages);
          setPage(page);
        } else if (responseFromServer?.data?.status === "JWT_INVALID") {
          Swal.fire({
            text: responseFromServer?.data?.message,
            icon: "warning",
          });
          navigate("/login");
        } else {
          setSurveyList([]);
          setPageCount(1);
        }
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };
  //fetch survey list on initial load
  const fetchSurveyList = async (searchString, page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await communication.getSurveyList(
        searchString,
        page
      );
      // console.log("data",responseFromServer?.data);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setSurveyList(responseFromServer?.data?.survey);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(currentPage);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setSurveyList([]);
        setPageCount(1);
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  // useEffect(() => {
  //   fetchSurveyList(currentPage);
  // }, []);

  useEffect(() => {
    handlePageClick();
  }, [isPageUpdated]);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">Survey Management</div>
            <div className="search_wrapper">
              <input
                type="search"
                value={searchValue}
                onChange={(event) => {
                  getSurveyListBySearch(event.target.value.trim(), 1);
                  setPage(1);
                  setCurrentPage(1);
                  setSearchValue(event.target.value.trim());
                }}
                className="form-control"
                placeholder="search..."
                style={{
                  width: `${toggleSearchInput === false ? "37px" : "250px"}`,
                  borderRadius: `${
                    toggleSearchInput === false ? "50%" : "5px"
                  }`,
                }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() => {
                  SetToggleSearchInput(!toggleSearchInput);
                  setSearchValue("");
                  toggleSearchInput &&
                    searchValue !== "" &&
                    fetchSurveyList("", 1);
                }}
                style={{
                  left: `${toggleSearchInput === false ? "50" : "90"}%`,
                  padding:"16px"
                }}
              />
            </div>
          </div>
          <section>
            <div className="section_wrapper">
              <div className="section_header_wrapper">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  isPageUpdated={isPageUpdated}
                  setIsPageUpdated={setIsPageUpdated}
                />
                <div className="button_wrapper">
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    onClick={() => {
                      fetchSurveyList("", currentPage);
                      setPage(currentPage);
                      // setCurrentPage(1);
                      setSearchValue("");
                    }}
                    className="refresh_icon"
                    title="Refresh"
                  />
                  <Button
                    icon={faPlus}
                    name="Add Survey"
                    onClick={() => dispatch(toggleModal("add_survey"))}
                  />
                </div>
              </div>
              <div className="table_wrapper">
                <div className="table">
                  <div className="table_header">
                    <div className="row">
                      <div className="col-1">
                        <h5>Sr. No</h5>
                      </div>
                      <div className="col-3">
                        <h5>Survey Name</h5>
                      </div>
                      <div className="col-3">
                        <h5>Election Name</h5>
                      </div>
                      <div className="col-2">
                        <h5>Start Date</h5>
                      </div>
                      <div className="col-2">
                        <h5>End Date</h5>
                      </div>
                      <div className="col-1">
                        <h5>Action</h5>
                      </div>
                    </div>
                  </div>
                  <>
                    {surveyList?.length > 0 ? (
                      <>
                        {surveyList?.map((surveyDetail, index) => {
                          return (
                            <div className="table_data" key={index}>
                              <div className="row">
                                <div className="col-1">
                                  <h6>
                                    {Number(pageLimit) * (page - 1) +
                                      (index + 1)}
                                  </h6>
                                </div>
                                <div className="col-3">
                                  <h6>{surveyDetail?.name}</h6>
                                </div>
                                <div className="col-3">
                                  <h6>{surveyDetail?.electionName}</h6>
                                </div>
                                <div className="col-2">
                                  <h6>
                                    {new Date(
                                      surveyDetail?.startDate
                                    ).toLocaleDateString()}
                                  </h6>
                                </div>
                                <div className="col-2">
                                  <h6>
                                    {new Date(
                                      surveyDetail?.endDate
                                    ).toLocaleDateString()}
                                  </h6>
                                </div>
                                <div className="col-1 row_action">
                                  <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    onClick={() => {
                                      setSurveyId(surveyDetail?._id);
                                      dispatch(toggleModal("update_survey"));
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="data_not_available_wrapper">
                        Survey Not Available
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
            {modal === "add_survey" && (
              <AddSurvey handlePageClick={handlePageClick} />
            )}
            {modal === "update_survey" && (
              <UpdateSurvey
                handlePageClick={handlePageClick}
                surveyId={surveyId}
              />
            )}
          </section>
        </>
      )}
    </>
  );
};

export default SurveyList;
