import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loader from '../../../utilities/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { communication } from '../../../services/communication';
import { showLoader, hideLoader } from '../../../redux-store/loaderReducer';
import { useRef } from 'react';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ImmediateFocus = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loaderReducer.loaderState);
    const [graphData, setGraphData] = useState();
    const [electionId, setElectionId] = useState("");
    const [electionList, setElectionList] = useState([]);
    const barRef = useRef();

    //get all election list
    const fetchElectionList = async () => {
        try {
            const responseFromServer = await communication.getAllElectionList();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setElectionList(responseFromServer?.data?.election)
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setElectionList([]);
            }
        } catch (error) {
            Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
        }
    }

    //get Category graph data on initial load
    const fetchGraphData = async (electionId) => {
        try {
            dispatch(showLoader());
            const responseFromServer = await communication.getImmediateFocusGraphData(electionId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setGraphData(responseFromServer?.data);
                setElectionId(responseFromServer?.data?.electionId)
                fetchElectionList();
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setGraphData([]);
            }
            dispatch(hideLoader());
        } catch (error) {
            dispatch(hideLoader());
            Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
        }
    }

    const labels = graphData?.label;

    const data = {
        labels,
        datasets: [
            {
                label: 'Voters',
                data: graphData?.countOfVoters,
                backgroundColor: '#FF7D7D',
                barPercentage: graphData?.countOfVoters?.length > 6 ? 0.4 : 0.2
            }
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Immediate Focus',
                padding: 30
            },
        },
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            },
        },
    };

    const handleClick = (event) => {
        const index = getElementAtEvent(barRef.current, event)[0]?.index;
        const label = data.labels[index]
        navigate(`/dashboard/reports/immediate-focus/voter-list?taluka=${label}&electionId=${electionId}`);

    }

    useEffect(() => {
        fetchElectionList();
        fetchGraphData();
    }, []);

    return (
        <>
            {loader === true ?
                <Loader />
                :
                <>
                    <div className="top_header">
                        <div className="title">Dashboard</div>
                        <div className="button_wrapper">
                            <select onChange={(event) => { setElectionId(event.target.value); fetchGraphData(event.target.value) }} className="form-select">
                                <option value="">Select Election</option>
                                {electionList?.map((election, index) => {
                                    return (
                                        <option value={election?._id} selected={election?._id === electionId} key={index}>{election?.name}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <section>
                        <div className="section_wrapper">
                            <div className="dashboard_graph_wrapper immediate_focus_graph">
                            {graphData?.countOfVoters?.length > 0 ?
                                <>
                                <Bar ref={barRef} options={options} data={data} onClick={handleClick} />
                                <p className='text-center mt-5 text-secondary'>Taluka Wise Graph</p>
                                </>
                                :
                                    <>
                                        <div className="dashboard_data_not_available">
                                            Data Not Available
                                        </div>
                                    </>
                            }
                            
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    )
}

export default ImmediateFocus