import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Button = ({ name, icon, onClick }) => {
    return (
        <button className="button" onClick={onClick}>{![undefined, null, ""].includes(icon) && <FontAwesomeIcon icon={icon} />} {name}</button>
    )
}

export default Button