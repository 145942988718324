import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import InputBox from '../../../common-components/InputBox'
import FilterButton from '../../../common-components/FilterButton'
import { useDispatch } from 'react-redux'
import { toggleModal } from '../../../redux-store/modalReducer'
import { genderArray } from '../../../utilities/genderArray'
import Swal from 'sweetalert2'

const ImmediateFocusVoterFilter = ({ filterData, setFilterData, taluka, electionId, fetchVoterListBySearch, searchString, setCurrentPage }) => {
    const dispatch = useDispatch();

    const searchFilter = () => {
        if (filterData.voterId || filterData.gender || filterData.boothHead) {
            setCurrentPage(1);
            fetchVoterListBySearch(taluka, electionId, searchString, 1)
        } else {
            Swal.fire({ text: "At least one field is required", icon: "warning" });
            return false;
        }
    }
    
    return (
        <div className="modal_wrapper">
            <div className="card_wrapper">
                <div className="card_header">
                    <div className="title">
                        <h5>Filter</h5>
                    </div>
                    <div className="close_button" onClick={() => dispatch(toggleModal(""))}>
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                </div>
                <div className="card_body">
                    <div className="row">
                        <div className="col-4">
                            <div className="input_box_wrapper">
                                <InputBox type="text" name="voterId" value={filterData?.voterId} onChange={(event) => setFilterData({ ...filterData, voterId: event.target.value })} placeholder="Enter Voter ID" />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="input_box_wrapper">
                                <select name="gender" onChange={(event) => setFilterData({ ...filterData, gender: event.target.value })} className="form-select">
                                    <option value="">Select Gender</option>
                                    {genderArray?.map((gender, index) => {
                                        return (
                                            <option value={gender} selected={gender === filterData?.gender} key={index}>{gender}</option>

                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="input_box_wrapper">
                                <InputBox type="text" name="boothHead" value={filterData?.boothHead} onChange={(event) => setFilterData({ ...filterData, boothHead: event.target.value })} placeholder="Enter Booth Head Name" />
                            </div>
                        </div>
                        <div className="submit_button_wrapper">
                            <FilterButton name="Add Filter" onClick={() => searchFilter()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImmediateFocusVoterFilter