import { configureStore } from '@reduxjs/toolkit'
import loaderReducer from './loaderReducer'
import userDetailReducer from './userDetailReducer'
import modalReducer from './modalReducer'
import refreshSurveyDropdown from './refreshSurveyDropdown'

export const store = configureStore({
    reducer: {
        loaderReducer: loaderReducer,
        userDetailReducer: userDetailReducer,
        modalReducer: modalReducer,
        refreshSurveyDropdown: refreshSurveyDropdown,
    },
})