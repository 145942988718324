import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';


function generateIcon(iconName) {
    // Add the Font Awesome Solid icon library to the library object
    library.add(fas);

    const iconDefinition = Object.values(fas).find(
        (icon) => {
            if(icon.iconName === iconName){
                return icon.iconName
            }
        }
        );

    if (iconDefinition) {
        return iconDefinition.icon;
    } else {
        // Handle the case when the icon is not found
        console.error(`Icon "${iconName}" not found.`);
        // You can assign a default icon or provide an alternative action here
    }
}

export default generateIcon;

