import React, { useEffect, useState } from "react";
import {
  faCircleCheck,
  faCircleXmark,
  faMagnifyingGlass,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toggleModal } from "../../redux-store/modalReducer";
import AidHelpGiven from "./AidHelpGiven";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { communication } from "../../services/communication";
import Swal from "sweetalert2";
import Loader from "../../utilities/Loader";
import { hideLoader, showLoader } from "../../redux-store/loaderReducer";
import { preventDefault } from "../../redux-store/refreshSurveyDropdown";
import Pagination from "../../common-components/Pagination";

const SurveyData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageLimit = Number(process.env.REACT_APP_PAGE_LIMIT);
  const modal = useSelector((state) => state.modalReducer.modal);
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [toggleSearchInput, SetToggleSearchInput] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isPageUpdated, setIsPageUpdated] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [surveyId] = useSearchParams();
  const [surveyData, setSurveyData] = useState([]);
  const [electionData, setElectionData] = useState({});
  const [surveyCount, setSurveyCount] = useState({
    completed: 0,
    notCompleted: 0,
  });
  const [vote, setVote] = useState({
    vote: "",
    id: "",
  });
  const [voterData, setVoterData] = useState({
    voterId: "",
    electionId: "",
  });
  //State to handle index numbers of list
  const [page, setPage] = useState();

  const handlePageClick = (e) => {
    if (currentPage > pageCount) {
      Swal.fire({
        text: "Entered Page Cannot be greater than total Page",
        icon: "warning",
      });
      return false;
    } else {
      if (searchValue) {
        fetchSurveyDataBySearch(
          searchValue,
          currentPage,
          surveyId.get("surveyId")
        );
      } else {
        fetchSurveyData(searchValue, currentPage, surveyId.get("surveyId"));
      }
    }
  };

  //get survey data by search
  const fetchSurveyDataBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await communication.getCreatedSurveyList(
        surveyId.get("surveyId"),
        searchString,
        page
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        setSurveyData(responseFromServer?.data?.survey);
        setSurveyCount({
          ...surveyCount,
          completed: responseFromServer?.data?.completedCount,
          notCompleted: responseFromServer?.data?.notCompletedCount,
        });
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(page);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setSurveyData([]);
        setPageCount(0);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };
  // get election by id
  const fetchElectionDetailById = async (electionId) => {
    try {
      const responseFromServer = await communication.getElectionDetailById(
        electionId
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        setElectionData(responseFromServer?.data?.elections);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setElectionData({});
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //get survey data by surveyId
  const fetchSurveyData = async (searchString, page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await communication.getCreatedSurveyList(
        surveyId.get("surveyId"),
        searchString,
        page
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        setSurveyData(responseFromServer?.data?.survey);
        setSurveyCount({
          ...surveyCount,
          completed: responseFromServer?.data?.completedCount,
          notCompleted: responseFromServer?.data?.notCompletedCount,
        });
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(currentPage);
        fetchElectionDetailById(
          responseFromServer?.data?.survey[0]?.electionId
        );
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setSurveyData([]);
        setPageCount(0);
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //Submit Survey Vote
  const submitVote = async () => {
    try {
      if (vote.vote === "") {
        Swal.fire({ text: "Vote is required", icon: "warning" });
        return false;
      }

      const dataToSend = {
        surveyId: surveyId.get("surveyId"),
        voterId: voterData.voterId,
        vote: vote.vote,
      };
      dispatch(showLoader());
      const responseFromServer = await communication.submitSurveyVote(
        dataToSend
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "success" });
        setVote({ vote: "", id: "" });
        fetchSurveyData();
        dispatch(preventDefault());
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue?.trim() !== "") {
        fetchSurveyDataBySearch(searchValue?.trim(), 1);
      } else {
        fetchSurveyData("", 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  useEffect(() => {
    setCurrentPage(1);
    fetchSurveyData();
  }, [surveyId]);

  useEffect(() => {
    handlePageClick();
  }, [isPageUpdated]);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">Survey Management</div>
            <div className="search_wrapper">
              <input
                type="search"
                value={searchValue}
                onChange={(event) => {
                  // fetchSurveyDataBySearch(event.target.value.trim(), 1);
                  setPage(1);
                  setCurrentPage(1);
                  setSearchValue(event.target.value.trim());
                }}
                className="form-control"
                placeholder="search..."
                style={{
                  width: `${toggleSearchInput === false ? "37px" : "250px"}`,
                  borderRadius: `${
                    toggleSearchInput === false ? "50%" : "5px"
                  }`,
                }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() => {
                  SetToggleSearchInput(!toggleSearchInput);
                  setSearchValue("");
                  toggleSearchInput &&
                    searchValue !== "" &&
                    fetchSurveyData("", 1);
                }}
                style={{
                  left: `${toggleSearchInput === false ? "50" : "90"}%`,
                  padding: "16px",
                }}
              />
            </div>
          </div>
          <section>
            <div className="section_wrapper">
              <div className="section_header_wrapper survey_management_header">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  isPageUpdated={isPageUpdated}
                  setIsPageUpdated={setIsPageUpdated}
                />
                <div className="survey_card_wrapper">
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    onClick={() => {
                      fetchSurveyData("", currentPage);
                      setPage(currentPage);
                      // setCurrentPage(1);
                      setSearchValue("");
                    }}
                    className="refresh_icon"
                    title="Refresh"
                  />
                  <div className="survey_card">
                    <h6>{surveyCount?.completed}</h6>
                    <div className="icon_wrapper">
                      <FontAwesomeIcon icon={faCircleCheck} />
                      <h6>Completed</h6>
                    </div>
                  </div>
                  <div className="survey_card">
                    <h6>{surveyCount?.notCompleted}</h6>
                    <div className="icon_wrapper">
                      <FontAwesomeIcon icon={faCircleXmark} />
                      <h6>Not Completed</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table_wrapper survey_data_wrapper">
                <div className="table">
                  <div className="table_header">
                    <div className="serial_number">
                      <h5>Sr. No</h5>
                    </div>
                    <div className="name">
                      <h5>Full Name</h5>
                    </div>
                    <div className="city">
                      <h5>Village/City</h5>
                    </div>
                    <div className="voterId">
                      <h5>Voter ID</h5>
                    </div>
                    <div className="gender">
                      <h5>Gender</h5>
                    </div>
                    <div className="vote">
                      <h5>Vote</h5>
                    </div>
                    <div className="action">
                      <h5>Action</h5>
                    </div>
                  </div>
                  {surveyData?.length > 0 ? (
                    <>
                      {surveyData?.map((votersList, index) => {
                        return (
                          <div className="table_data" key={index}>
                            <div className="serial_number">
                              <h6>
                                {Number(pageLimit) * (page - 1) + (index + 1)}
                              </h6>
                            </div>
                            <div className="name">
                              <h6
                                className="link"
                                onClick={() => {
                                  dispatch(toggleModal("aid"));
                                  setVoterData({
                                    ...voterData,
                                    voterId: votersList?.voterId,
                                    electionId: votersList?.electionId,
                                  });
                                }}
                              >
                                <a>{votersList?.name}</a>
                              </h6>
                            </div>
                            <div className="city">
                              <h6>{votersList?.city}</h6>
                            </div>
                            <div className="voterId">
                              <h6>{votersList?.voterId}</h6>
                            </div>
                            <div className="gender">
                              <h6>{votersList?.gender}</h6>
                            </div>
                            <div className="vote">
                              <div className="party_wrapper">
                                {votersList?.party?.map((partyArray, index) => {
                                  return (
                                    <div className="party_input" key={index}>
                                      <input
                                        type="radio"
                                        name={`vote${votersList?._id}`}
                                        value={partyArray}
                                        checked={
                                          (votersList.vote?.toLowerCase() ===
                                            partyArray?.toLowerCase() &&
                                            votersList?.isVoted === true) ||
                                          (vote.id === votersList._id &&
                                            vote?.vote.toLowerCase() ===
                                              partyArray?.toLowerCase())
                                            ? true
                                            : false
                                        }
                                        onChange={(event) => {
                                          setVote({
                                            vote: event.target.value,
                                            id: votersList._id,
                                          });
                                          setVoterData({
                                            voterId: votersList?.voterId,
                                          });
                                        }}
                                        className="form-check-input"
                                        disabled={
                                          votersList?.isVoted ? true : false
                                        }
                                      />
                                      <label>{partyArray}</label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="action">
                              {electionData?.status === "new" ? (
                                votersList?.isVoted ? (
                                  <h6>-</h6>
                                ) : (
                                  <button
                                    className="action_button"
                                    disabled={
                                      vote?.id === votersList?._id
                                        ? false
                                        : true
                                    }
                                    onClick={() => submitVote()}
                                  >
                                    Submit
                                  </button>
                                )
                              ) : (
                                <h6>-</h6>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="data_not_available_wrapper">
                      Data Not Available
                    </div>
                  )}
                </div>
              </div>
            </div>
            {modal === "aid" && (
              <AidHelpGiven
                surveyId={surveyId.get("surveyId")}
                voterData={voterData}
                fetchSurveyData={fetchSurveyData}
                currentPage={currentPage}
              />
            )}
          </section>
        </>
      )}
    </>
  );
};

export default SurveyData;
