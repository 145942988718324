import React, { useEffect, useState } from "react";
import FilterButton from "../../common-components/FilterButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toggleModal } from "../../redux-store/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { helpGivenArray } from "../../utilities/helpGivenArray";
import Swal from "sweetalert2";
import { communication } from "../../services/communication";
import { useNavigate } from "react-router-dom";
import Loader from "../../utilities/Loader";
import { hideLoader, showLoader } from "../../redux-store/loaderReducer";
import useValidator from "../../utilities/useValidator";

const AidHelpGiven = ({
  surveyId,
  voterData,
  fetchSurveyData,
  currentPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validator, showValidationMessage] = useValidator();
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [isBenefitGiven, setIsBenefitGiven] = useState(false);
  const [aidData, setAidData] = useState({
    aid: [],
    remark: "",
  });

  //get checkbox values
  const checkBoxHandler = (event) => {
    const filteredAid = event.target.checked
      ? [...aidData?.aid, event.target.value]
      : aidData.aid?.filter((ele) => ele !== event.target.value);
    setAidData({ ...aidData, aid: filteredAid });
  };

  //get aid given detail by voter
  const fetchAidGivenDetailByVoter = async () => {
    try {
      const dataToSend = {
        surveyId,
        ...voterData,
      };
      const responseFromServer = await communication.getAidGivenDetailByVoter(
        dataToSend
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        setAidData({
          ...aidData,
          aid: [undefined, null].includes(
            responseFromServer?.data?.benefits?.aid
          )
            ? []
            : responseFromServer?.data?.benefits?.aid,
          remark:
            responseFromServer?.data?.benefits?.remark === undefined
              ? ""
              : responseFromServer?.data?.benefits?.remark,
        });
        setIsBenefitGiven(responseFromServer?.data?.benefits?.isBenefiteGiven);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //submit aid
  const submitAid = async () => {
    try {
      if (aidData.aid.length === 0) {
        Swal.fire({ text: "Select at least one AID", icon: "warning" });
        return false;
      }

      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }
      const dataToSend = {
        surveyId,
        voterId: voterData.voterId,
        electionId: voterData.electionId,
        ...aidData,
      };
      dispatch(showLoader());
      const serverResponse = await communication.addAidAgainstVoter(dataToSend);
      if (serverResponse?.data?.status === "SUCCESS") {
        Swal.fire({ text: serverResponse?.data?.message, icon: "success" });
        dispatch(toggleModal(""));
        fetchSurveyData("", currentPage);
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    fetchAidGivenDetailByVoter();
  }, []);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <div className="modal_wrapper">
          <div className="card_wrapper aid_card_wrapper">
            <div className="card_header">
              <div className="title">
                <h5>AID / Help Given</h5>
              </div>
              <div
                className="close_button"
                onClick={() => dispatch(toggleModal(""))}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div className="card_body">
              <div className="row">
                {helpGivenArray?.map((aid, index) => {
                  return (
                    <div className="col-3" key={index}>
                      <div className="input_box_wrapper">
                        <div className="checkbox_wrapper">
                          <input
                            type="checkbox"
                            name={aid}
                            value={aid}
                            checked={aidData?.aid.includes(aid)}
                            onChange={(event) => checkBoxHandler(event)}
                            className="form-check-input"
                            disabled={isBenefitGiven}
                          />
                          <h6>{aid}</h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="col-8">
                  <div className="input_box_wrapper">
                    <textarea
                      value={aidData?.remark}
                      onChange={(event) =>
                        setAidData({ ...aidData, remark: event.target.value })
                      }
                      className="form-control"
                      placeholder="Enter Remark..."
                      disabled={isBenefitGiven}
                    ></textarea>
                    {validator.message("remark", aidData.remark, "required", {
                      messages: {
                        required: "Remark is required",
                      },
                    })}
                  </div>
                </div>
                {isBenefitGiven ? (
                  <></>
                ) : (
                  <div className="submit_button_wrapper">
                    <FilterButton name="Submit" onClick={() => submitAid()} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AidHelpGiven;
