import React, { useState, useEffect } from "react";
import FilterButton from "../../common-components/FilterButton";
import InputBox from "../../common-components/InputBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import useValidator from "../../utilities/useValidator";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../redux-store/loaderReducer";
import { communication } from "../../services/communication";
import Loader from "../../utilities/Loader";
import { genderArray } from "../../utilities/genderArray";
import { categoryArray } from "../../utilities/categoryArray";
import { bloodGroupArray } from "../../utilities/bloodGroupArray";

const AddNewVoter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [validator, showValidationMessage] = useValidator();
  const [electionList, setElectionList] = useState([]);
  const [voterData, setVoterData] = useState({
    name: "",
    mobile: "",
    gender: "",
    dob: "",
    address: "",
    aadharNo: "",
    bloodGroup: "",
    category: "",
    caste: "",
    occupation: "",
    city: "",
    pageNo: Number(),
    voterId: "",
    boothNo: "",
    boothName: "",
    panchayatSamiti: "",
    nagarParishad: "",
    zillaParishad: "",
    taluka: "",
    electionId: "",
  });
  // To find age
  const ageCalculator = (year) => {
    const currentDate = new Date();
    const birthYear = year?.split("-")[0];
    const currentYear = currentDate?.getFullYear();
    if (currentYear - birthYear >= 18) {
      setVoterData({
        ...voterData,
        dob: year,
      });
    } else {
      Swal.fire({ text: "You can'not add a voter below 18 Years", icon: "warning" });
    }
  };
  // add voter
  const addVoter = async () => {
    try {
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      dispatch(showLoader());
      const responseFromServer = await communication.addVoter(voterData);
      if (responseFromServer?.data?.status === "SUCCESS") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "success" });
        navigate(-1);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.responseFromServer?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //get all election list
  const fetchElectionList = async () => {
    try {
      const responseFromServer = await communication.getAllElectionList();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setElectionList(responseFromServer?.data?.election);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setElectionList([]);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };
  useEffect(() => {
    fetchElectionList();
  }, []);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">Add Voter</div>
          </div>
          <section>
            <div className="form_wrapper_main">
              <div className="card_wrapper">
                <div className="card_header">
                  <div className="title">
                    <h5>Add Voter</h5>
                  </div>
                  <div
                    className="close_button"
                    onClick={() => navigate("/dashboard/voter-data")}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </div>
                <div className="card_body">
                  <div className="row">
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Full Name</label>
                        <InputBox
                          name="name"
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              name: event.target.value,
                            })
                          }
                          placeholder="Enter Full Name"
                        />
                        {validator.message("name", voterData.name, "required", {
                          messages: {
                            required: "Full Name is required",
                          },
                        })}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Mobile Number</label>
                        <InputBox
                          name="mobile"
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              mobile: event.target.value,
                            })
                          }
                          placeholder="Enter Moblie Number"
                        />
                        {validator.message(
                          "mobile",
                          voterData.mobile,
                          "required|numeric|min:10|max:10",
                          {
                            messages: {
                              required: "Mobile Number is required",
                            },
                          }
                        )}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Gender</label>
                        <div className="gender_input_wrapper">
                          {genderArray?.map((gender, index) => {
                            return (
                              <div className="gender_input" key={index}>
                                <input
                                  type="radio"
                                  name="gender"
                                  value={gender}
                                  checked={gender === voterData.gender}
                                  onChange={(event) =>
                                    setVoterData({
                                      ...voterData,
                                      gender: event.target.value,
                                    })
                                  }
                                  className="form-check-input"
                                />
                                <label>{gender}</label>
                              </div>
                            );
                          })}
                        </div>
                        {validator.message(
                          "gender",
                          voterData.gender,
                          "required",
                          {
                            message: {
                              require: "Gender is Required",
                            },
                          }
                        )}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Date of Birth</label>
                        <InputBox
                          type="date"
                          name="dob"
                          placeholder="mm-dd-yy"
                          onChange={(event) => {
                            ageCalculator(event.target.value);
                          }}
                        />
                      </div>
                      {validator.message("dob", voterData.dob, "required", {
                        message: {
                          require: "Data of Birth is Required",
                        },
                      })}
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Address</label>
                        <InputBox
                          name="address"
                          placeholder="Enter Address"
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              address: event.target.value,
                            })
                          }
                        />
                      </div>
                      {validator.message(
                        "address",
                        voterData.address,
                        "required",
                        {
                          message: {
                            require: "Address is Required",
                          },
                        }
                      )}
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Blood Group</label>
                        <select
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              bloodGroup: event.target.value,
                            })
                          }
                          name="bloodGroup"
                          className="form-select party_select_box"
                        >
                          <option value="">Select Blood Group</option>
                          {bloodGroupArray?.map((bloodGroup, index) => {
                            return (
                              <option
                                value={bloodGroup}
                                selected={bloodGroup === voterData.bloodGroup}
                                key={index}
                              >
                                {bloodGroup}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Aadhar Card No</label>
                        <InputBox
                          name="aadharNo"
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              aadharNo: event.target.value,
                            })
                          }
                          placeholder="Enter Aadhar Card Number"
                        />
                      </div>
                      {/* {validator.message("Aadhar No", voterData.aadharNo, "required", {
                        message: {
                          require: "Aadhar No  is Required"
                        }
                      })} */}
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Category</label>
                        <select
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              category: event.target.value,
                            })
                          }
                          name="category"
                          className="form-select party_select_box"
                        >
                          <option value="">Select Category</option>
                          {categoryArray?.map((category, index) => {
                            return (
                              <option
                                value={category}
                                selected={category === voterData.category}
                                key={index}
                              >
                                {category}
                              </option>
                            );
                          })}
                        </select>
                        {validator.message(
                          "category",
                          voterData.category,
                          "required",
                          {
                            message: {
                              require: "Category is Required",
                            },
                          }
                        )}
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Caste</label>
                        <InputBox
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              caste: event.target.value,
                            })
                          }
                          name="cast"
                          placeholder="Enter Cast"
                        />
                        {validator.message(
                          "caste",
                          voterData.caste,
                          "required",
                          {
                            message: {
                              require: "Caste is Required",
                            },
                          }
                        )}
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Occupation</label>
                        <InputBox
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              occupation: event.target.value,
                            })
                          }
                          name="occupation"
                          placeholder="Enter Occupation"
                        />
                        {validator.message(
                          "occupation",
                          voterData.occupation,
                          "required",
                          {
                            message: {
                              require: "occupation is Required",
                            },
                          }
                        )}
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Village/City</label>
                        <InputBox
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              city: event.target.value,
                            })
                          }
                          name="city"
                          placeholder="Enter Village/City"
                        />
                        {validator.message("city", voterData.city, "required", {
                          message: {
                            require: "City/Village is Required",
                          },
                        })}
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Voter ID</label>
                        <InputBox
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              voterId: event.target.value,
                            })
                          }
                          name="voterId"
                          placeholder="Enter Voter ID"
                        />
                        {validator.message(
                          "VoteId",
                          voterData.voterId,
                          "required",
                          {
                            message: {
                              require: "Voter Id is Required",
                            },
                          }
                        )}
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Booth Name</label>
                        <InputBox
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              boothName: event.target.value,
                            })
                          }
                          name="boothName"
                          placeholder="Enter Booth Name"
                        />
                        {validator.message(
                          "booth Name",
                          voterData.boothName,
                          "required",
                          {
                            message: {
                              require: "Booth Name is Required",
                            },
                          }
                        )}
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Booth Number</label>
                        <InputBox
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              boothNo: event.target.value,
                            })
                          }
                          name="boothNumbar"
                          placeholder="Enter Booth Number"
                        />
                        {validator.message(
                          "Booth Number",
                          voterData.boothNo,
                          "required",
                          {
                            message: {
                              require: "Booth Number is Required",
                            },
                          }
                        )}
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Page Number</label>
                        <InputBox
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              pageNo: event.target.value,
                            })
                          }
                          name="pageNumber"
                          placeholder="Enter Page Number"
                        />
                        {validator.message(
                          "pageNo",
                          voterData.pageNo,
                          "required",
                          {
                            message: {
                              require: "Page Number is Required",
                            },
                          }
                        )}
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Panchayat Samiti</label>
                        <InputBox
                          name="panchayatSamiti"
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              panchayatSamiti: event.target.value,
                            })
                          }
                          placeholder="Enter Panchayat Samiti"
                        />
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Nagar Parishad</label>
                        <InputBox
                          name="nagarParishad"
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              nagarParishad: event.target.value,
                            })
                          }
                          placeholder="Enter Nagar Parishad"
                        />
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Zilah Parishad</label>
                        <InputBox
                          name="zilahParishad"
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              zillaParishad: event.target.value,
                            })
                          }
                          placeholder="Enter Zilah Parishad"
                        />
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Tehsil</label>
                        <InputBox
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              taluka: event.target.value,
                            })
                          }
                          name="tehsil"
                          placeholder="Enter Tehsil"
                        />
                        {/* {validator.message("Tehsil", voterData.taluka, "requried", {
                          message: {
                            require: "Tehsil is Required"
                          }
                        })} */}
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="input_box_wrapper">
                        <label>Election</label>
                        <select
                          name="election"
                          onChange={(event) =>
                            setVoterData({
                              ...voterData,
                              electionId: event.target.value,
                            })
                          }
                          className="form-select party_select_box"
                        >
                          <option value="">Select Election</option>
                          {electionList
                            ?.filter((ele) => ele.status === "new")
                            ?.map((election, index) => {
                              return (
                                <option
                                  value={election?._id}
                                  selected={
                                    election?._id === voterData.electionId
                                  }
                                  key={index}
                                >
                                  {election?.name}
                                </option>
                              );
                            })}
                        </select>
                        {validator.message(
                          "Election",
                          voterData.electionId,
                          "requried",
                          {
                            message: {
                              require: "Election is Required",
                            },
                          }
                        )}
                      </div>
                    </div>

                    <div className="submit_button_wrapper">
                      <FilterButton name="Add" onClick={() => addVoter()} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AddNewVoter;
