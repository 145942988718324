import React, { useEffect, useState } from "react";
import { toggleModal } from "../../../redux-store/modalReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import InputBox from "../../../common-components/InputBox";
import FilterButton from "../../../common-components/FilterButton";
import { useDispatch, useSelector } from "react-redux";
import useValidator from "../../../utilities/useValidator";
import Swal from "sweetalert2";
import { communication } from "../../../services/communication";
import { hideLoader, showLoader } from "../../../redux-store/loaderReducer";
import { useNavigate } from "react-router-dom";
import Loader from "../../../utilities/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { refresh } from "../../../redux-store/refreshSurveyDropdown";

const UpdateSurvey = ({ handlePageClick, surveyId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [validator, showValidationMessage] = useValidator();
  const [electionArray, setElectionArray] = useState([]);
  const [surveyData, setSurveyData] = useState({
    name: "",
    electionId: "",
    startDate: new Date(),
    endDate: new Date(),
  });

  //get survey detail by id
  const fetchSurveyDetailById = async () => {
    try {
      const responseFromServer = await communication.getSurveyDetailById(
        surveyId
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        setSurveyData(responseFromServer?.data?.survey);
        fetchElectionList();
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setSurveyData([]);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //Get all election list
  const fetchElectionList = async () => {
    try {
      const responseFromServer = await communication.getAllElectionList();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setElectionArray(responseFromServer?.data?.election);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setElectionArray([]);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //Add Survey
  const updateExistingSurvey = async () => {
    try {
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }
      if (surveyData.startDate > surveyData.endDate) {
        Swal.fire({
          text: "End date cannot be smaller than start date",
          icon: "warning",
        });
        return false;
      }
      const dataToSend = {
        surveyId,
        name: surveyData.name,
        electionId: surveyData.electionId,
        startDate: surveyData.startDate,
        endDate: surveyData.endDate,
      };

      dispatch(showLoader());
      const responseFromServer = await communication.updateSurvey(dataToSend);
      if (responseFromServer?.data?.status === "SUCCESS") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "success" });
        dispatch(toggleModal(""));
        dispatch(refresh());
        handlePageClick();
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    fetchSurveyDetailById();
  }, []);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <div className="modal_wrapper">
          <div className="card_wrapper">
            <div className="card_header">
              <div className="title">
                <h5>Update Survey</h5>
              </div>
              <div
                className="close_button"
                onClick={() => dispatch(toggleModal(""))}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div className="card_body">
              <div className="row">
                <div className="col-6">
                  <div className="input_box_wrapper">
                    <label>Survey Name</label>
                    <InputBox
                      type="text"
                      name="name"
                      value={surveyData.name}
                      onChange={(event) =>
                        setSurveyData({
                          ...surveyData,
                          name: event.target.value,
                        })
                      }
                      placeholder="Enter Survey Name"
                    />
                    {validator.message("name", surveyData.name, "required", {
                      messages: {
                        required: "Survey Name is required",
                      },
                    })}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input_box_wrapper">
                    <label>Election Name</label>
                    <select
                      name="electionId"
                      onChange={(event) =>
                        setSurveyData({
                          ...surveyData,
                          electionId: event.target.value,
                        })
                      }
                      className="form-select"
                    >
                      <option value="">Select Election</option>
                      {electionArray
                        ?.filter((ele) => ele.status === "new")
                        ?.map((election, index) => {
                          return (
                            <option
                              value={election?._id}
                              selected={election?._id === surveyData.electionId}
                              key={index}
                            >
                              {election?.name}
                            </option>
                          );
                        })}
                    </select>
                    {validator.message(
                      "electionId",
                      surveyData.electionId,
                      "required",
                      {
                        messages: {
                          required: "Election Name is required",
                        },
                      }
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input_box_wrapper">
                    <label>Start Date</label>
                    <DatePicker
                      selected={new Date(surveyData.startDate)}
                      onChange={(date) =>
                        setSurveyData({ ...surveyData, startDate: date })
                      }
                    />
                    {validator.message(
                      "startDate",
                      surveyData.startDate,
                      "required",
                      {
                        messages: {
                          required: "Survey Start Date is required",
                        },
                      }
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input_box_wrapper">
                    <label>End Date</label>
                    <DatePicker
                      selected={new Date(surveyData.endDate)}
                      onChange={(date) =>
                        setSurveyData({ ...surveyData, endDate: date })
                      }
                    />
                    {validator.message(
                      "endDate",
                      surveyData.endDate,
                      "required",
                      {
                        messages: {
                          required: "Survey End Date is required",
                        },
                      }
                    )}
                  </div>
                </div>
                <div className="submit_button_wrapper">
                  <FilterButton
                    name="Update"
                    onClick={() => updateExistingSurvey()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateSurvey;
