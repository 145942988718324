import axios from "axios";
import Swal from "sweetalert2";

const nodeEnvironment = process.env.REACT_APP_NODE_ENVIRONMENT;
const serverUrl = process.env.REACT_APP_SERVER_URL;
const machineIp = "http://192.168.1.48:3019";
const developmentURL = "http://localhost:3012";


function getCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        if ("victoryToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if not found
    return null;
}

export function getServerUrl() {
    if (nodeEnvironment === "development") {
        return developmentURL;
    }
    if (nodeEnvironment === "production") {
        return serverUrl;
    }
    if (nodeEnvironment === "machine_ip") {
        return machineIp;
    }
    return serverUrl;
}

export const communication = {
    login: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/login`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    resetPassword: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/reset-password`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    //==========Election Management=============//
    addElection: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/election/create-election`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    updateElection: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/election/update-election`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getElectionList: (searchString, page, name, type, party, year) => {
        try {
            return axios.post(`${getServerUrl()}/election/get-election-list`, { searchString, page, name, type, party, year }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getElectionDetailById: (electionId) => {
        try {
            return axios.post(`${getServerUrl()}/election/get-election-by-id`, { electionId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getAllElectionList: () => {
        try {
            return axios.get(`${getServerUrl()}/election/fetch-election-list`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    getElectionStart: (electionId) => {
        try {
            return axios.post(`${getServerUrl()}/election/start-election`, { electionId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getElectionCompleted: (electionId, status) => {
        try {
            return axios.post(`${getServerUrl()}/election/change-election-status`, { electionId, status }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    // =============== Designation Management=============

    getDesignationList: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/designation/get-designation-list`,
                { searchString, page },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${getCookie()}`,
                    },
                });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    addDesignation: (data) => {
        try {
            return axios.post(`${getServerUrl()}/designation/create-designation`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    updateDesignation: (data) => {
        try {
            return axios.post(`${getServerUrl()}/designation/update-designation`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    getdesignationDetailById: (designationId) => {
        try {
            return axios.post(`${getServerUrl()}/designation/get-designation-by-id`, { designationId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    getAllDesignations: () => {
        try {
            return axios.get(`${getServerUrl()}/designation/get-all-designation`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    //==========Organization Management=============//
    getOrganizerList: (searchString, page, city, designationId, voterId) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/get-organizer-list`, { searchString, page, city, designationId, voterId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    addOrganizer: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/create-organizer`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    updateOrganizerProfile: (data) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/update-organizer-profile`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    updateOrganizer: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/update-organizer`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    getOrganizerDetailByID: (organizerId) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/get-organizer-by-id`, { organizerId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    exportOrganizations: (searchString, page, city, designation, voterId) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/export-organizer`, { searchString, page, city, designation, voterId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    changeOrganizerStatus: (status, organizerId) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/change-organizer-status`, { status, organizerId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    importExcelData: (tabName, formData) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/import-${tabName}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getAllOrganizers: () => {
        try {
            return axios.get(`${getServerUrl()}/organizer/get-all-organizer`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    // ====================== Voter Tab==============

    getVoterList: (searchString, page, electionId, city, gender, voterId, boothName,signal) => {
        try {
            return axios.post(`${getServerUrl()}/voter/get-voter-list`, { searchString, page, electionId, city, gender, voterId, boothName }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                // cancelToken:cancelToken
                signal:signal
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    updateVoter: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/voter/update-voter`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    addVoter: (voterData) => {
        try {
            return axios.post(`${getServerUrl()}/voter/create-voter`, voterData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    exportVoter: (searchString, page, electionId, city, gender, voterId, boothNo, pageNo) => {
        try {
            return axios.post(`${getServerUrl()}/voter/export-voter`, { searchString, page, electionId, city, gender, voterId, boothNo, pageNo }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    importExcelData: (electionId, formData) => {
        try {
            return axios.post(`${getServerUrl()}/voter/import-voter?electionId=${electionId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    importOrganizeData: (formData) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/import-organizer`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },


    //==============Survey Management=================//
    getSurveyList: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/survey/get-survey-list`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },


    addSurvey: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/survey/create-survey`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    exportOrganizations: (searchString, page, city, designationId, voterId) => {
        try {
            return axios.post(`${getServerUrl()}/organizer/export-organizer`, { searchString, page, city, designationId, voterId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    updateSurvey: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/survey/update-survey`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    getvoterDetailById: (id) => {
        try {
            return axios.post(`${getServerUrl()}/voter/get-voter-by-id`, { id }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getSurveyDetailById: (surveyId) => {
        try {
            return axios.post(`${getServerUrl()}/survey/get-survey-by-id`, { surveyId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getSurveyTabs: () => {
        try {
            return axios.get(`${getServerUrl()}/survey/get-survey-tab`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getCreatedSurveyList: (surveyId, searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/survey/get-survey-dynamic-list`, { surveyId, searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    addAidAgainstVoter: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/benefit/create-benefit`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getAidGivenDetailByVoter: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/benefit/get-benefit-by-voter`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    submitSurveyVote: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/survey/create-survey-report`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    // ================= Benificial Voter===================//
    getBenificialVoterList: (searchString, page, electionId, city, gender, voterId) => {
        try {
            return axios.post(`${getServerUrl()}/voter/get-benefit-voters`, { searchString, page, electionId, city, gender, voterId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getSurveyTrendsVoterList: (searchString, page, electionId, city, voterId, gender) => {
        try {
            return axios.post(`${getServerUrl()}/survey/get-survey-trend-voter`, { searchString, page, electionId, city, voterId, gender }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    getBenifitsVoterList: (voterId, electionId) => {
        try {
            return axios.post(`${getServerUrl()}/benefit/get-benefit`, { voterId, electionId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    // ================= Confirm Voter===================//
    getConfirmVoterList: (searchString, page, electionId, city, gender, voterId, pageNo, boothName) => {
        try {
            return axios.post(`${getServerUrl()}/voter/get-confirmed-voters`, { searchString, page, electionId, city, gender, voterId, pageNo, boothName }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getSurveyAnalysisData: (electionId) => {
        try {
            return axios.post(`${getServerUrl()}/survey/get-survey-trend-graph`, { electionId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getGraphData: (graphType, electionId) => {
        try {
            return axios.post(`${getServerUrl()}/dashboard/get-${graphType}-wise-graph-data`, { electionId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getImmediateFocusGraphData: (electionId) => {
        try {
            return axios.post(`${getServerUrl()}/dashboard/get-taluka-wise-voter-count`, { electionId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getImmediateFocusVoterList: (taluka, electionId, searchString, page, voterId, gender, boothHead) => {
        try {
            return axios.post(`${getServerUrl()}/dashboard/get-taluka-wise-voter-data`, { taluka, electionId, searchString, page, voterId, gender, boothHead }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    exportImmediateFocusVoterData: (taluka, electionId, searchString, page, voterId, gender, boothHead) => {
        try {
            return axios.post(`${getServerUrl()}/dashboard/export-taluka-wise-voter-data`, { taluka, electionId, searchString, page, voterId, gender, boothHead }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    getDataOnGraph: (electionId) => {
        try {
            return axios.post(`${getServerUrl()}/dashboard/area-wise-survey-report`, { electionId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },

    //=============Activity Management===========//
    getActivityList: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/activity/get-activity-list`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    addNewEvent: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/activity/create-activity`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    getEventDetailById: (activityId) => {
        try {
            return axios.post(`${getServerUrl()}/activity/get-activity-by-id`, { activityId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    updateEvent: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/activity/update-activity`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    //=============Download Template=================//
    downloadOrganizerTemplate: () => {
        try {
            return axios.get(`${getServerUrl()}/organizer/download-organizer-template`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    downloadVoterTemplate: () => {
        try {
            return axios.get(`${getServerUrl()}/voter/download-voter-template`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    //?------------------------Voting day------------------------------
    getVotingDayVoterList: (searchString, page, city, gender, voterId) => {
        try {
            return axios.post(`${getServerUrl()}/votingDay/get-voters-list`, { searchString, page, city, gender, voterId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    submitVotingDayVote: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/votingDay/submit-vote`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    fetchHighestAndLowestBoothList: (electionId, boothNo, searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/votingDay/get-highest-and-lowest-booth-list`, { electionId, boothNo: Number(boothNo), searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
    exportHighestAndLowestBoothWiseVoterList: (electionId, boothNo, searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/votingDay/export-higher-and-lowest-booth-list`, { electionId, boothNo: Number(boothNo), searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "error" });
        }
    },
}