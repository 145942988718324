import React, { useState, useEffect } from 'react'
import { communication } from '../../services/communication';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import SurveyAnalysisGraph from './SurveyAnalysisGraph';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../utilities/Loader';
import { hideLoader, showLoader } from '../../redux-store/loaderReducer';

const SurveyAnalysisData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loaderReducer.loaderState);
    const [surveyAnalysisData, setSurveyAnalysisData] = useState({});
    const [filteredTabData, setFilteredTabData] = useState({ tabName: "", CompletedCount: 0, notCompletedCount: 0 })
    const [electionId, setElectionId] = useState("");
    const [electionList, setElectionList] = useState([]);

    //get election wist voter list
    const electionWiseVoterList = (electionId) => {
        try {
            if (electionId) {
                fetchSurveyAnalysisData(electionId);
            }
        } catch (error) {
            Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });

        }
    }
    //fetch survey analysis data on intial load
    const fetchSurveyAnalysisData = async (electionId) => {
        try {
            dispatch(showLoader());
            const responseFromServer = await communication.getSurveyAnalysisData(electionId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setSurveyAnalysisData(responseFromServer?.data);
                setElectionId(responseFromServer?.data?.electionId);
                fetchElectionList();
                setFilteredTabData({ ...filteredTabData, tabName: responseFromServer?.data?.surveyData[0].name, CompletedCount: responseFromServer?.data?.surveyData[0].Completed, notCompletedCount: responseFromServer?.data?.surveyData[0].notCompleted });
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setSurveyAnalysisData([]);
            }
            dispatch(hideLoader());
        } catch (error) {
            dispatch(hideLoader());
            Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
        }
    }

    //Filter Tab wise Data
    const filterTabWiseData = (tab) => {
        const filteredData = surveyAnalysisData?.surveyData?.filter((ele) => { return ele?.name === tab });
        setFilteredTabData({ ...filteredTabData, tabName: filteredData[0]?.name, CompletedCount: filteredData[0]?.Completed, notCompletedCount: filteredData[0]?.notCompleted });
    }

    //get all election list
    const fetchElectionList = async () => {
        try {
            const responseFromServer = await communication.getAllElectionList();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setElectionList(responseFromServer?.data?.election)
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setElectionList([]);
            }
        } catch (error) {
            Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchElectionList()
        fetchSurveyAnalysisData();
    }, []);

    return (
        <>
            {loader === true ?
                <Loader />
                :
                <>
                    <div className="top_header">
                        <div className="title">Data Analysis</div>
                        <div className="button_wrapper">

                            <select name="election" onChange={(event) => { electionWiseVoterList(event.target.value); fetchSurveyAnalysisData(event.target.value); setElectionId(event.target.value)}} className="form-select party_select_box">
                                <option value="">Select Election</option>
                                {electionList?.map((election, index) => {
                                    return (
                                        <option value={election?._id} selected={electionId === election?._id} key={index}>{election?.name}</option>
                                    );
                                })}
                            </select>

                        </div>
                    </div>
                    <section>

                        <div className="section_wrapper">
                            <div className="survey_analysis_tab_wrapper">
                                {surveyAnalysisData?.surveyData?.map((tabs, index) => {
                                    return (
                                        <div className={`tab ${filteredTabData.tabName === tabs?.name ? "active" : ""}`} key={index} onClick={() => filterTabWiseData(tabs?.name)}>
                                            <h5>{tabs?.name}</h5>
                                        </div>
                                    );
                                })}
                            </div>
                            {surveyAnalysisData?.surveyData?.length > 0 ?
                                <>
                            <div className="data_analysis_card_wrapper">
                                <div className="survey_card">
                                    <h6>{filteredTabData?.CompletedCount}</h6>
                                    <div className="icon_wrapper">
                                        <FontAwesomeIcon icon={faCircleCheck} />
                                        <h6>Completed</h6>
                                    </div>
                                </div>
                                <div className="survey_card">
                                    <h6>{filteredTabData?.notCompletedCount}</h6>
                                    <div className="icon_wrapper">
                                        <FontAwesomeIcon icon={faCircleXmark} />
                                        <h6>Not Completed</h6>
                                    </div>
                                </div>
                            </div>
                            
                                <SurveyAnalysisGraph graphData={surveyAnalysisData?.data} />
                                </>

                                :
                                    <>
                                        <div className="dashboard_data_not_available">
                                            Data Not Available
                                        </div>
                                    </>
                            }
                        </div>
                    </section>
                </>
            }
        </>
    )
}

export default SurveyAnalysisData