import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import FilterButton from "../../../common-components/FilterButton";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleLeft,
  faFileExport,
  faMagnifyingGlass,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../common-components/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { communication } from "../../../services/communication";
import { hideLoader, showLoader } from "../../../redux-store/loaderReducer";
import Swal from "sweetalert2";
import FileSaver from "file-saver";
import { toggleModal } from "../../../redux-store/modalReducer";
import Loader from "../../../utilities/Loader";
import Pagination from "../../../common-components/Pagination";

const BoothVoterList = () => {
  const navigate = useNavigate();
  const pageLimit = Number(process.env.REACT_APP_PAGE_LIMIT);
  const [toggleSearchInput, SetToggleSearchInput] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isPageUpdated, setIsPageUpdated] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [voterList, setVoterList] = useState([]);
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  let test = false;

  //State to handle index numbers of list
  const [page, setPage] = useState();
  const handlePageClick = (e) => {
    if (currentPage > pageCount) {
      Swal.fire({text: "Entered Page Cannot be grater than total Page",icon: "warning",});
      return false;
    } else {
      if (searchValue) {
        fetchHighestAndLowestBoothListBySearch(searchValue, currentPage);
      } else {
        
        fetchHighestAndLowestBoothList(searchParam.get("id"), Number(searchParam.get("boothNo")),searchValue,currentPage
        );
      }
    }
  };

  //get voter list who is in confirm voter list but not voted by search
  const fetchHighestAndLowestBoothListBySearch = async (searchString, page) => {
    try {
      //   dispatch(showLoader());
      if (searchString) {
        const responseFromServer =await communication.fetchHighestAndLowestBoothList(searchParam.get("id"), Number(searchParam.get("boothNo")),searchString, page);
        if (responseFromServer?.data?.status === "SUCCESS") {
          setVoterList(responseFromServer?.data?.voter);
          setPageCount(responseFromServer?.data?.totalPages);
          setPage(page);
        } else if (responseFromServer?.data?.status === "JWT_INVALID") {
          Swal.fire({text: responseFromServer?.data?.message,icon: "warning",});
          navigate("/login");
        } else {
          setVoterList([]);
          setPageCount(0);
        }
        dispatch(toggleModal(""));
      } else {
        const responseFromServer =await communication.fetchHighestAndLowestBoothList(searchParam.get("id"), Number(searchParam.get("boothNo")), searchString, page);
        if (responseFromServer?.data?.status === "SUCCESS") {
          setVoterList(responseFromServer?.data?.voter);
          setPageCount(responseFromServer?.data?.totalPages);
          setPage(page);
          setSearchValue();
        } else if (responseFromServer?.data?.status === "JWT_INVALID") {
          Swal.fire({text: responseFromServer?.data?.message,icon: "warning",});
          navigate("/login");
        } else {
          setVoterList([]);
          setPageCount(0);
        }
      }
    } catch (error) {
      Swal.fire({text: error?.response?.data?.message || error?.message,icon: "error",});
    }
  };

  //get voter list who is in confirm voter list but not voted
  const fetchHighestAndLowestBoothList = async (searchString, page) => {
    try {
      dispatch(showLoader());
      const responseFromServer =
        await communication.fetchHighestAndLowestBoothList(searchParam.get("id"), Number(searchParam.get("boothNo")), searchString, page);
      if (responseFromServer?.data?.status === "SUCCESS") {
        setVoterList(responseFromServer?.data?.voter);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(page);
        setSearchValue();
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setVoterList([]);
        setPageCount(0);
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({text: error?.response?.data?.message || error?.message, icon: "error",});
    }
  };

  //Export Immediate Focus Voter data
  const exportHighestAndLowestBoothWiseVoterList = async () => {
    try {
      Swal.fire({
        // title: "Are you sure?",
        text: "Are you sure you want to export Voter Data?",
        showCancelButton: true,
        confirmButtonColor: "#38CAB3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async function (isConfirm) {
        if (isConfirm.isConfirmed) {
          dispatch(showLoader());
          const serverResponse = await communication.exportHighestAndLowestBoothWiseVoterList( searchParam.get("id"),Number(searchParam.get("boothNo")),searchValue,currentPage);
          if (serverResponse.data) {
            //Create a Blob from the PDF Stream
            const file = new Blob([serverResponse.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(file, "Voters.xlsx");
          } else {
            Swal.fire({text: "Failed to export data in excel",icon: "warning",});
          }
          dispatch(hideLoader());
        } else {
          return;
        }
        dispatch(hideLoader());
      });
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({ text: error.message, icon: "warning" });
    }
  };
  useEffect(() => {
    if (isPageUpdated != null) {
      handlePageClick();
    }
  }, [isPageUpdated != null]);

  useEffect(() => {
    fetchHighestAndLowestBoothList("",1);
  }, []);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">MSI Panel</div>
            <div className="search_wrapper">
              <input
                type="search"
                onChange={(event) => {
                  fetchHighestAndLowestBoothListBySearch( event.target.value.trim(),1); setCurrentPage(1); setPage(1);
                  setSearchValue(event.target.value.trim());
                }}
                className="form-control"
                placeholder="search..."
                style={{
                  width: `${toggleSearchInput === false ? "37px" : "250px"}`,
                  borderRadius: `${
                    toggleSearchInput === false ? "50%" : "5px"
                  }`,
                }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() =>{ SetToggleSearchInput(!toggleSearchInput);setSearchValue("");toggleSearchInput &&  searchValue !=="" && fetchHighestAndLowestBoothListBySearch ("",1); }}
                style={{
                  left: `${toggleSearchInput === false ? "50" : "90"}%`,
                }}
              />
            </div>
          </div>
          <section>
            <div className="section_wrapper">
              <div className="section_header_wrapper">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  isPageUpdated={isPageUpdated}
                  setIsPageUpdated={setIsPageUpdated}
                />

                <div className="button_wrapper">
                  <Button
                    icon={faFileExport}
                    name="Export"
                    onClick={() => exportHighestAndLowestBoothWiseVoterList()}
                  />
                  {/* <FilterButton name="Filter" icon={faSliders} onClick={() => dispatch(toggleModal("filter"))} /> */}
                  <FilterButton
                    name="Back"
                    icon={faCircleLeft}
                    onClick={() => navigate("/dashboard/voting-day/msi-panel")}
                  />
                </div>
              </div>
              <div className="table_wrapper voter_list_wrapper">
                <div className="table">
                  <div className="table_header">
                    <div className="serial_number">
                      <h5>Sr. No</h5>
                    </div>
                    <div className="name">
                      <h5>Full Name</h5>
                    </div>
                    <div className="city">
                      <h5>Voter ID</h5>
                    </div>
                    <div className="gender">
                      <h5>Gender</h5>
                    </div>
                    <div className="survey_name">
                      <h5>Booth Head</h5>
                    </div>
                    <div className="booth_name">
                      <h5>Booth Name</h5>
                    </div>
                    <div className="page_number">
                      <h5>Page Number</h5>
                    </div>
                    <div className="mobile_number">
                      <h5>Booth Head Number</h5>
                    </div>
                  </div>
                  {voterList?.length > 0 ? (
                    <>
                      {voterList?.map((voterDetail, index) => {
                        return (
                          <div div className="table_data" key={index}>
                            <div className="serial_number">
                              <h6>
                                {Number(pageLimit) * (page - 1) + (index + 1)}
                              </h6>
                            </div>
                            <div className="name">
                              <h6>{voterDetail?.name}</h6>
                            </div>
                            <div className="voterId">
                              <h6>{voterDetail?.voterId}</h6>
                            </div>
                            <div className="gender">
                              <h6>{voterDetail?.gender}</h6>
                            </div>
                            <div className="survey_name">
                              <h6>{voterDetail?.boothHead}</h6>
                            </div>
                            <div className="booth_name">
                              <h6>{voterDetail?.boothName}</h6>
                            </div>
                            <div className="page_number">
                              <h6>{voterDetail?.pageNo}</h6>
                            </div>
                            <div className="mobile_number">
                              <h6>
                                {voterDetail?.boothHeadMobile
                                  ? voterDetail?.boothHeadMobile
                                  : "NA"}
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="data_not_available_wrapper">
                      Voters Not Available
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default BoothVoterList;
