import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../services/communication";
import { io } from "socket.io-client";

const SurveyManagementDropdown = () => {
    const [tabArray, setTabArray] = useState([]);
    const [socket, setSocket] = useState();

    useEffect(() => {
        const socketConnection = io(getServerUrl(), { transports: ["websocket"] });
        setSocket(socketConnection);
        return () => {
        socketConnection.disconnect();
        console.log("disconnect");
        };
    }, []);

    useEffect(() => {
    if (socket) {
        socket.on("surveyTab", (data) => {
        setTabArray(data);
        });
    }
    }, [socket]);

        return (
        <>
            <NavLink to="/dashboard/survey-management/survey-list">
                <div className="dropdown_tab">
                    <div className="title">
                    <span>Survey List</span>
                    </div>
                </div>
            </NavLink>
                {tabArray?.map((tabs, index) => {
                    return (
                        <NavLink
                            to={`/dashboard/survey-management/survey-data${
                            index + 1
                            }?surveyId=${tabs?._id}`}
                            key={index}
                        >
                            <div className="dropdown_tab">
                            <ul>
                                <li>
                                <div className="title">
                                    <span>{tabs?.name}</span>
                                </div>
                                </li>
                            </ul>
                            </div>
                        </NavLink>
                    );
            })}
        </>
    );
};

export default SurveyManagementDropdown;
