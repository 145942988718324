import React, { useEffect, useState } from "react";
import {
  faArrowsRotate,
  faMagnifyingGlass,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FilterButton from "../../../common-components/FilterButton";
import { toggleModal } from "../../../redux-store/modalReducer";
import OnFieldInputFilter from "../onfield-input/OnFieldInputFilter";
import { hideLoader, showLoader } from "../../../redux-store/loaderReducer";
import { communication } from "../../../services/communication";
import Swal from "sweetalert2";
import Loader from "../../../utilities/Loader";
import Pagination from "../../../common-components/Pagination";

const OnFieldInput = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageLimit = process.env.REACT_APP_PAGE_LIMIT ?? 20;
  const modal = useSelector((state) => state.modalReducer.modal);
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [toggleSearchInput, SetToggleSearchInput] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPageUpdated, setIsPageUpdated] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [voterList, setVoterList] = useState([]);
  const [voterCount, setVoterCount] = useState({});
  const [filterDetail, setFilterDetail] = useState({
    city: "",
    gender: "",
    voterId: "",
  });
  const [vote, setVote] = useState({
    vote: "",
    voterId: "",
    electionId: "",
    id: "",
  });
  const [page, setPage] = useState();

  //set current page on click
  const handlePageClick = (e) => {
    if (currentPage > pageCount) {
      Swal.fire({
        text: "Entered Page Cannot be grater than total Page",
        icon: "warning",
      });
      return false;
    } else {
      if (searchValue) {
        getVoterListBySearch(
          searchValue,
          currentPage,
          filterDetail.city,
          filterDetail.gender,
          filterDetail.voterId
        );
      } else {
        fetchVoterList(
          searchValue,
          currentPage,
          filterDetail.city,
          filterDetail.gender,
          filterDetail.voterId
        );
      }
    }
  };

  //Get Voter List By Search
  const getVoterListBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await communication.getVotingDayVoterList(
        searchString,
        page,
        filterDetail.city,
        filterDetail.gender,
        filterDetail.voterId
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        setVoterList(responseFromServer?.data?.voter);
        setVoterCount(responseFromServer?.data?.voterCounts);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(page);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setVoterList([]);
        setPageCount(1);
      }
      dispatch(toggleModal(""));
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    } finally {
      dispatch(toggleModal(""));
    }
  };
  //Fetch Voter list on initial load
  const fetchVoterList = async (searchString, page, city, gender, voterId) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await communication.getVotingDayVoterList(
        searchString,
        page,
        city,
        gender,
        voterId
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        setVoterList(responseFromServer?.data?.voter);
        setVoterCount(responseFromServer?.data?.voterCounts);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(currentPage);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setVoterList([]);
        setPageCount(1);
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //Submit vote
  const submitVote = async () => {
    try {
      if (vote.vote === "") {
        Swal.fire({ text: "Vote is Required", icon: "warning" });
        return false;
      }
      const dataToSend = {
        voterId: vote.voterId,
        vote: vote.vote,
        electionId: vote.electionId,
      };
      dispatch(showLoader());
      const responseFromServer = await communication.submitVotingDayVote(
        dataToSend
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "success" });
        setVote({ vote: "", electionId: "", voterId: "", id: "" });
        dispatch(hideLoader());
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    } finally {
      dispatch(hideLoader());
      fetchVoterList(searchValue?.trim(), 1);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue?.trim() !== "") {
        getVoterListBySearch(searchValue?.trim(), 1);
      } else {
        fetchVoterList("", 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  useEffect(() => {
    handlePageClick();
  }, [isPageUpdated]);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">Voting Day</div>
            <div className="search_wrapper">
              <input
                value={searchValue}
                type="search"
                onChange={(event) => {
                  // getVoterListBySearch(event.target.value.trim(), 1);
                  setPage(1);
                  setCurrentPage(1);
                  setSearchValue(event.target.value.trim());
                }}
                className="form-control"
                placeholder="search..."
                style={{
                  width: `${toggleSearchInput === false ? "37px" : "250px"}`,
                  borderRadius: `${
                    toggleSearchInput === false ? "50%" : "5px"
                  }`,
                }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() => {
                  SetToggleSearchInput(!toggleSearchInput);
                  setSearchValue("");
                  toggleSearchInput &&
                    searchValue !== "" &&
                    fetchVoterList("", 1);
                }}
                style={{
                  left: `${toggleSearchInput === false ? "50" : "90"}%`,
                  padding: "16px",
                }}
              />
            </div>
          </div>
          <section>
            <div className="section_wrapper">
              <div className="section_header_wrapper">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  isPageUpdated={isPageUpdated}
                  setIsPageUpdated={setIsPageUpdated}
                />
                <div className="button_wrapper">
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    onClick={() => {
                      fetchVoterList("", currentPage);
                      setPage(currentPage);
                      // setCurrentPage(1);
                      setFilterDetail({});
                      setSearchValue("");
                    }}
                    className="refresh_icon"
                    title="Refresh"
                  />
                  <FilterButton
                    name="Filter"
                    icon={faSliders}
                    onClick={() => dispatch(toggleModal("filter"))}
                  />
                </div>
              </div>
              <div className="counter_card_wrapper">
                <div className="counter_card">
                  <h5>{voterCount?.votedCount}</h5>
                  <h6>Total Voted</h6>
                </div>
                {voterCount?.partyWiseCount?.map((partyDetails) => {
                  return (
                    <div className="counter_card">
                      <h5>{partyDetails?.count}</h5>
                      <h6>{partyDetails?.party}</h6>
                    </div>
                  );
                })}
                <div className="counter_card">
                  <h5>{voterCount?.notVotedVoter}</h5>
                  <h6>Not Voted</h6>
                </div>
              </div>
              <div className="table_wrapper onField_input_table_wrapper">
                <div className="table">
                  <div className="table_header">
                    <div className="serial_number">
                      <h5>Sr. No</h5>
                    </div>
                    <div className="name">
                      <h5>Full Name</h5>
                    </div>
                    <div className="city">
                      <h5>Village/City</h5>
                    </div>
                    <div className="voterId">
                      <h5>Voter ID</h5>
                    </div>
                    <div className="gender">
                      <h5>Gender</h5>
                    </div>
                    <div className="booth_name">
                      <h5>Booth Name</h5>
                    </div>
                    <div className="vote">
                      <h5>Vote</h5>
                    </div>
                    <div className="action">
                      <h5>Action</h5>
                    </div>
                  </div>
                  {voterList?.length > 0 ? (
                    <>
                      {voterList.map((voterDetails, index) => {
                        return (
                          <div className="table_data" key={index}>
                            <div className="serial_number">
                              <h6>
                                {Number(pageLimit) * (page - 1) + (index + 1)}
                              </h6>
                            </div>
                            <div className="name">
                              <h6>{voterDetails?.name}</h6>
                            </div>
                            <div className="city">
                              <h6>{voterDetails?.city}</h6>
                            </div>
                            <div className="voterId">
                              <h6>{voterDetails?.voterId}</h6>
                            </div>
                            <div className="gender">
                              <h6>{voterDetails?.gender}</h6>
                            </div>
                            <div className="booth_name">
                              <h6>{voterDetails?.boothName}</h6>
                            </div>
                            <div className="vote">
                              <div className="party_wrapper">
                                {voterDetails?.party?.map((partyArray, ind) => {
                                  return (
                                    <div className="party_input" key={ind}>
                                      <input
                                        type="radio"
                                        name={`vote${voterDetails?._id}`}
                                        value={partyArray}
                                        checked={
                                          (voterDetails?.isVoted === true &&
                                            voterDetails.vote?.toLowerCase() ===
                                              partyArray?.toLowerCase()) ||
                                          (vote.id === voterDetails._id &&
                                            vote?.vote.toLowerCase() ===
                                              partyArray?.toLowerCase())
                                        }
                                        onChange={(event) => {
                                          setVote({
                                            vote: event.target.value,
                                            voterId: voterDetails?.voterId,
                                            electionId:
                                              voterDetails?.electionId,
                                            id: voterDetails?._id,
                                          });
                                        }}
                                        className="form-check-input"
                                        disabled={
                                          voterDetails?.isVoted ? true : false
                                        }
                                      />
                                      <label>{partyArray}</label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="action">
                              {voterDetails?.isVoted ? (
                                <h6>-</h6>
                              ) : (
                                <button
                                  className="action_button"
                                  disabled={
                                    vote?.id === voterDetails?._id
                                      ? false
                                      : true
                                  }
                                  onClick={() => submitVote()}
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="data_not_available_wrapper">
                      Voters Not Available
                    </div>
                  )}
                </div>
              </div>
            </div>
            {modal === "filter" && (
              <OnFieldInputFilter
                setFilterDetail={setFilterDetail}
                filterDetail={filterDetail}
                getVoterListBySearch={getVoterListBySearch}
                searchString={searchValue}
                setCurrentPage={setCurrentPage}
              />
            )}
          </section>
        </>
      )}
    </>
  );
};

export default OnFieldInput;
