import React from 'react'
import { NavLink } from 'react-router-dom'

const DataAnalysisDropdown = () => {
    return (
        <>
            <NavLink to="/dashboard/data-analysis/survey-trends">
                <div className="dropdown_tab">
                    <div className="title"><span>Survey Trends</span></div>
                </div>
            </NavLink>
            <NavLink to="/dashboard/data-analysis/survey-data">
                <div className="dropdown_tab">
                    <div className="title"><span>Survey Data</span></div>
                </div>
            </NavLink>
        </>
    )
}

export default DataAnalysisDropdown