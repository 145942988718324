import React from "react";
import { useEffect } from "react";
import { communication } from "../../services/communication";
import { toggleModal } from "../../redux-store/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import useValidator from "../../utilities/useValidator";
import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import InputBox from "../../common-components/InputBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import FilterButton from "../../common-components/FilterButton";
import Multiselect from "multiselect-react-dropdown";
import Loader from "../../utilities/Loader";
import { hideLoader, showLoader } from "../../redux-store/loaderReducer";

const AddNewEvent = ({ fetchActivityList, currentPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [validator, showValidationMessage] = useValidator();
  const [boothValidator, showBoothValidationMessage] = useValidator();
  const [electionArray, setElectionArray] = useState([]);
  const [organizerArray, setOrganizerArray] = useState([]);
  const [eventData, setEventData] = useState({
    event: "",
    electionId: "",
    allotedPoints: "",
    utilizedPoints: "",
    description: "",
    booth: "",
  });
  const [booth, setBooth] = useState("");
  const [boothName, setBoothName] = useState([]);
  const [allotedMembers, setAllotedMembers] = useState([]);

  //Get all election list
  const fetchElectionList = async () => {
    try {
      const responseFromServer = await communication.getAllElectionList();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setElectionArray(responseFromServer?.data?.election);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setElectionArray([]);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //Get all organizers
  const fetchAllOrganizers = async () => {
    try {
      const responseFromServer = await communication.getAllOrganizers();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setOrganizerArray(responseFromServer?.data?.result);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setOrganizerArray([]);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //Add booth
  const addBooth = () => {
    if (!boothValidator.allValid()) {
      showBoothValidationMessage(true);
      return false;
    }
    setBoothName([...boothName, booth]);
    setBooth("");
  };

  //Remove booth form array
  const deleteBooth = (index) => {
    const filteredBooth = boothName?.filter((ele, ind) => {
      return index !== ind;
    });
    setBoothName(filteredBooth);
  };

  //Add Event
  const addEvent = async () => {
    try {
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }
      if (!boothName.length > 0) {
        Swal.fire({ text: "At least one booth is required", icon: "warning" });
        return false;
      }

      const dataToSend = {
        event: eventData.event,
        electionId: eventData.electionId,
        allotedPoints: Number(eventData.allotedPoints),
        utilizedPoints: Number(eventData.utilizedPoints),
        description: eventData.description,
        boothName,
        allotedMembers,
      };
      dispatch(showLoader());
      const responseFromServer = await communication.addNewEvent(dataToSend);
      if (responseFromServer?.data?.status === "SUCCESS") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "success" });
        fetchActivityList("", currentPage);
        dispatch(toggleModal(""));
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //check utilized points is greater than alloted points or not
  const checkIsGreateThanAllotedPoints = (utilizedPoints) => {
    if (Number(utilizedPoints) > Number(eventData?.allotedPoints)) {
      // console.log("Data", checkIsGreateThanAllotedPoints);
      Swal.fire({
        text: "Utilized points cannot be greater than alloted points",
      });
      return false;
    }
  };

  useEffect(() => {
    fetchElectionList();
    fetchAllOrganizers();
  }, []);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <div className="modal_wrapper">
          <div className="card_wrapper">
            <div className="card_header">
              <div className="title">
                <h5>Add Event</h5>
              </div>
              <div
                className="close_button"
                onClick={() => dispatch(toggleModal(""))}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div className="card_body">
              <div className="row">
                <div className="col-6">
                  <div className="input_box_wrapper">
                    <label>Election Name</label>
                    <select
                      name="electionId"
                      onChange={(event) =>
                        setEventData({
                          ...eventData,
                          electionId: event.target.value,
                        })
                      }
                      className="form-select"
                    >
                      <option value="">Select Election</option>
                      {electionArray
                        ?.filter((ele) => ele.status === "new")
                        ?.map((election, index) => {
                          return (
                            <option
                              value={election?._id}
                              selected={election?._id === eventData.electionId}
                              key={index}
                            >
                              {election?.name}
                            </option>
                          );
                        })}
                    </select>
                    {validator.message(
                      "electionId",
                      eventData.electionId,
                      "required",
                      {
                        messages: {
                          required: "Election Name is required",
                        },
                      }
                    )}
                  </div>
                </div>

                <div className="col-6">
                  <div className="input_box_wrapper">
                    <label>Event Name</label>
                    <InputBox
                      type="text"
                      name="event"
                      value={eventData.event}
                      onChange={(event) =>
                        setEventData({
                          ...eventData,
                          event: event.target.value,
                        })
                      }
                      placeholder="Enter Event Name"
                    />
                    {validator.message("event", eventData.event, "required", {
                      messages: {
                        required: "Event Name is required",
                      },
                    })}
                  </div>
                </div>

                <div className="col-6">
                  <div className="input_box_wrapper" style={{ cursor:"none" }}>
                    <label>Alloted Member</label>
                    <Multiselect
                      options={organizerArray}
                      displayValue="name"
                      selectedValues={setAllotedMembers?.allotedMember}
                      showCheckbox={true}
                      onChange={(event) => setAllotedMembers(event)}
                      onSelect={(event) => setAllotedMembers(event)}
                      onRemove={(event) => setAllotedMembers(event)}
                      placeholder="Select Alloted Member"
                      keepSearchTerm={true}
                      hidePlaceholder={true}
                      showArrow
                      closeIcon="cancel"
                      readOnly={true}
                      cursor={false}
                      customArrow={
                        <>
                          <FontAwesomeIcon icon={faAngleDown} />
                        </>
                      }
                    />
                    {validator.message(
                      "allotedMember",
                      allotedMembers,
                      "required",
                      {
                        messages: {
                          required: "Alloted Member is required",
                        },
                      }
                    )}
                  </div>
                </div>

                <div className="col-6">
                  <div className="input_box_wrapper">
                    <label>Alloted Points</label>
                    <InputBox
                      type="text"
                      name="allotedPoints"
                      value={eventData.allotedPoints}
                      onChange={(event) =>
                        setEventData({
                          ...eventData,
                          allotedPoints: event.target.value,
                        })
                      }
                      placeholder="Enter Alloted Points"
                    />
                    {validator.message(
                      "allotedPoints",
                      eventData.allotedPoints,
                      "required",
                      {
                        messages: {
                          required: "Alloted Points is required",
                        },
                      }
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input_box_wrapper">
                    <label>Utilized Points</label>
                    <InputBox
                      type="text"
                      name="utilizedPoints"
                      value={eventData.utilizedPoints}
                      onChange={(event) => {
                        if (
                          Number(event?.target?.value) >
                          Number(eventData?.allotedPoints)
                        ) {
                          setEventData({
                            ...eventData,
                            utilizedPoints: "",
                          });
                          Swal.fire({
                            text: "Utilized points cannot be greater than alloted points",
                            icon: "warning",
                          });
                          return false;
                        } else {
                          setEventData({
                            ...eventData,
                            utilizedPoints: event.target.value,
                          });
                        }
                        checkIsGreateThanAllotedPoints(event.target.value);
                      }}
                      placeholder="Enter Utilized Points"
                    />
                    {validator.message(
                      "utilizedPoints",
                      eventData.utilizedPoints,
                      "required",
                      {
                        messages: {
                          required: "Utilized Points is required",
                        },
                      }
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input_box_wrapper">
                    <label>Description</label>
                    <InputBox
                      type="text"
                      name="description"
                      value={eventData.description}
                      onChange={(event) =>
                        setEventData({
                          ...eventData,
                          description: event.target.value,
                        })
                      }
                      placeholder="Enter Description"
                    />
                    {validator.message(
                      "description",
                      eventData.description,
                      "required",
                      {
                        messages: {
                          required: "Description is required",
                        },
                      }
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input_box_wrapper">
                    <label>Booth Name</label>
                    <InputBox
                      type="text"
                      name="booth"
                      value={booth}
                      onChange={(event) => setBooth(event.target.value)}
                      placeholder="Enter Booth Name"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="event_button_wrapper">
                    <button onClick={() => addBooth()}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>
                {/* {boothValidator.message("booth", booth, "required", {
                  messages: {
                    required: "Booth is required"
                  }
                })} */}
                {boothName?.map((ele, index) => {
                  return (
                    <>
                      <div className="col-6">
                        <div className="input_box_wrapper">
                          <input
                            type="text"
                            name="booth"
                            value={ele}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="event_button_wrapper">
                          <button onClick={() => deleteBooth(index)}>
                            <FontAwesomeIcon icon={faTrash} className="trash" />
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}

                <div className="submit_button_wrapper">
                  <FilterButton name="Add" onClick={() => addEvent()} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewEvent;
