import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faFileExport,
  faFileImport,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../common-components/Button";
import FilterButton from "../../common-components/FilterButton";
import { hideLoader, showLoader } from "../../redux-store/loaderReducer";
import Swal from "sweetalert2";
import { communication } from "../../services/communication";
import Loader from "../../utilities/Loader";
import { toggleModal } from "../../redux-store/modalReducer";
import FileSaver from "file-saver";
import VoterFilter from "./VoterFilter";
import ImportDataVoter from "./ImportDataVoter";
import InputBox from "../../common-components/InputBox";
import Pagination from "../../common-components/Pagination";
import axios from "axios";

const VoterList = () => {
  const [toggleSearchInput, SetToggleSearchInput] = useState(false);
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const modal = useSelector((state) => state.modalReducer.modal);
  const pageLimit = process.env.REACT_APP_PAGE_LIMIT ?? 20;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isPageUpdated, setIsPageUpdated] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [voterList, setVoterList] = useState([]);
  const [electionId, setElectionId] = useState("");
  const [filterDetail, setFilterDetail] = useState({
    city: "",
    gender: "",
    voterId: "",
    boothName: "",
  });
  // Cancel Request for API
  // const cancelRequest = axios.CancelToken.source();
  const controller = new AbortController();
  //State to handle index numbers of list
  const [page, setPage] = useState();

  const handlePageClick = (e) => {
    if (currentPage > pageCount) {
      Swal.fire({
        text: "Entered Page Cannot be grater than total Page",
        icon: "warning",
      });
      return false;
    } else {
      if (searchValue) {
        getVoterListBySearch(
          searchValue,
          currentPage,
          electionId,
          filterDetail.city,
          filterDetail.gender,
          filterDetail.voterId,
          filterDetail.boothName
        );
      } else {
        fetchVoterList(
          searchValue,
          currentPage,
          electionId,
          filterDetail.city,
          filterDetail.gender,
          filterDetail.voterId,
          filterDetail.boothName
        );
      }
    }
  };

  //get Voter list by search
  const getVoterListBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await communication.getVoterList(
        searchString,
        page,
        electionId,
        filterDetail.city,
        filterDetail.gender,
        filterDetail.voterId,
        filterDetail.boothName,
        controller.signal
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(showLoader());
        setVoterList(responseFromServer?.data?.voter);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(page);
        dispatch(hideLoader());
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setVoterList([]);
        setPageCount(0);
      }
      dispatch(toggleModal(""));
    } catch (error) {
      // Swal.fire({
      //   text: error?.response?.data?.message || error?.message,
      //   icon: "error",
      // });
    } finally {
      dispatch(toggleModal(""));
    }
  };

  //Fetch Voter list on initial load
  const fetchVoterList = async (
    searchString,
    page,
    electionId,
    city,
    gender,
    voterId,
    boothName
  ) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await communication.getVoterList(
        searchString,
        page,
        electionId,
        city,
        gender,
        voterId,
        boothName
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setVoterList(responseFromServer?.data?.voter);
        setPageCount(responseFromServer?.data?.totalPages);
        setElectionId(responseFromServer?.data?.electionId);
        setPage(currentPage);
        // setSearchValue("");
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        dispatch(hideLoader());
        setVoterList([]);
        setPageCount(0);
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  const exportVoterData = async () => {
    try {
      Swal.fire({
        // title: "Are you sure?",
        text: "Are you sure you want to export Voter Data?",
        showCancelButton: true,
        confirmButtonColor: "#38CAB3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async function (isConfirm) {
        if (isConfirm.isConfirmed) {
          dispatch(showLoader());
          const serverResponse = await communication.exportVoter(
            searchValue,
            currentPage,
            electionId,
            filterDetail.city,
            filterDetail.gender,
            filterDetail.voterId,
            filterDetail.boothNo,
            filterDetail.pageNo
          );
          if (serverResponse.data) {
            //Create a Blob from the PDF Stream
            const file = new Blob([serverResponse.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(file, "VoterData.xlsx");
          } else {
            Swal.fire({
              text: "Failed to export data in excel",
              icon: "warning",
            });
          }
          dispatch(hideLoader());
        } else {
          return;
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (searchValue?.trim() !== "") {
      getVoterListBySearch(searchValue?.trim(), 1);
    } else {
      fetchVoterList("", 1);
    }

    return () => {
      // cancelRequest.cancel()
      controller.abort()
    };
  }, [searchValue]);

  useEffect(() => {
    handlePageClick();
  }, [isPageUpdated]);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">Voter List</div>
            <div className="search_wrapper">
              <input
                type="search"
                value={searchValue}
                onChange={(event) => {
                  // getVoterListBySearch(event.target.value.trim(), 1);
                  setPage(1);
                  setCurrentPage(1);
                  setSearchValue(event.target.value.trim());
                }}
                className="form-control"
                placeholder="search..."
                style={{
                  width: `${toggleSearchInput === false ? "37px" : "250px"}`,
                  borderRadius: `${
                    toggleSearchInput === false ? "50%" : "5px"
                  }`,
                }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() => {
                  SetToggleSearchInput(!toggleSearchInput);
                  setSearchValue("");
                  toggleSearchInput &&
                    searchValue !== "" &&
                    fetchVoterList("", 1);
                }}
                style={{
                  left: `${toggleSearchInput === false ? "50" : "90"}%`,
                  padding: "16px",
                }}
              />
            </div>
          </div>

          <section>
            <div className="section_wrapper">
              <div className="section_header_wrapper">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  isPageUpdated={isPageUpdated}
                  setIsPageUpdated={setIsPageUpdated}
                />
                <div className="button_wrapper">
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    onClick={() => {
                      fetchVoterList("", currentPage);
                      setPage(currentPage);
                      // setCurrentPage(1);
                      setSearchValue("");
                      setFilterDetail({});
                    }}
                    className="refresh_icon"
                    title="Refresh"
                  />
                  <Button
                    icon={faFileImport}
                    name="Import"
                    onClick={() => dispatch(toggleModal("import"))}
                  />
                  <Button
                    icon={faFileExport}
                    name="Export"
                    onClick={() => exportVoterData()}
                  />
                  <Button
                    icon={faPlus}
                    name="Add Voter"
                    onClick={() => navigate("/dashboard/voter-data/add-voter")}
                  />
                  <FilterButton
                    name="Filter"
                    icon={faSliders}
                    onClick={() => dispatch(toggleModal("filter"))}
                  />
                </div>
              </div>
              <div className="table_wrapper">
                <div className="table organization_table">
                  <div className="table_header">
                    <div className="serial_number">
                      <h5>Sr. No</h5>
                    </div>
                    <div className="full_name">
                      <h5>Full Name</h5>
                    </div>
                    <div className="city">
                      <h5>Village/City</h5>
                    </div>
                    <div className="voterId">
                      <h5>Voter ID</h5>
                    </div>
                    <div className="gender">
                      <h5>Gender</h5>
                    </div>
                    <div className="booth_name">
                      <h5>Booth Name</h5>
                    </div>

                    <div className="page_number">
                      <h5>Page Number</h5>
                    </div>
                    <div className="mobile_number">
                      <h5>Mobile Number</h5>
                    </div>
                    <div className="action">
                      <h5>Action</h5>
                    </div>
                  </div>

                  {voterList?.length > 0 ? (
                    <>
                      {voterList?.map((voter, index) => {
                        return (
                          <div className="table_data" key={index}>
                            <div className="serial_number">
                              <h6>
                                {Number(pageLimit) * (page - 1) + (index + 1)}
                              </h6>
                            </div>
                            <div className="full_name">
                              <h6>{voter.name}</h6>
                            </div>
                            <div className="city">
                              <h6>{voter.city}</h6>
                            </div>
                            <div className="voterId">
                              <h6>{voter.voterId}</h6>
                            </div>
                            <div className="gender">
                              <h6>{voter.gender}</h6>
                            </div>
                            <div className="booth_name">
                              <h6>{voter.boothName}</h6>
                            </div>

                            <div className="page_number">
                              <h6>{voter.pageNo}</h6>
                            </div>
                            <div className="mobile_number">
                              <h6>
                                {[undefined, null, ""].includes(voter.mobile)
                                  ? "NA"
                                  : voter.mobile}
                              </h6>
                            </div>
                            <div className="action">
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                onClick={() =>
                                  navigate(
                                    `/dashboard/voter-data/update-vote?id=${voter._id}`
                                  )
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="data_not_available_wrapper">
                      Voter Not Available
                    </div>
                  )}
                </div>
              </div>
            </div>

            {modal === "filter" && (
              <VoterFilter
                filterDetail={filterDetail}
                setFilterDetail={setFilterDetail}
                getVoterListBySearch={getVoterListBySearch}
                searchString={searchValue}
                setCurrentPage={setCurrentPage}
              />
            )}

            {modal === "import" && (
              <ImportDataVoter fetchVoterList={fetchVoterList} />
            )}
          </section>
        </>
      )}
    </>
  );
};

export default VoterList;
