import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUnlock, faUser } from '@fortawesome/free-solid-svg-icons'
import { NavLink, useNavigate } from 'react-router-dom'
import useValidator from '../utilities/useValidator'
import Swal from 'sweetalert2'
import { communication } from '../services/communication'
import { useDispatch, useSelector } from 'react-redux'
import { addUserDetails } from '../redux-store/userDetailReducer'
import { hideLoader, showLoader } from '../redux-store/loaderReducer'
import Loader from '../utilities/Loader'

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [validator, showValidationMessage] = useValidator();
  const [togglePassword, setTogglePassword] = useState(false);
  const [loginDetail, setLoginDetail] = useState(
    {
      userId: "",
      password: ""
    }
  );

  // user login
  const login = async () => {
    try {
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false
      }
      const dataToSend = {
        ...loginDetail
      }
      dispatch(showLoader());
      const serverResponse = await communication.login(dataToSend);
      if (serverResponse?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        Swal.fire({ text: serverResponse?.data?.message, icon: "success", timer: 2000 });
        dispatch(addUserDetails({ userDetails: serverResponse?.data?.userDetails, token: serverResponse?.data?.token }));
        navigate("/dashboard");
      } else {
        dispatch(hideLoader());
        Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
      }
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
    }
  }

  return (
    <>
      {loader === true ?
        <Loader />
        :
        <div className="login_wrapper">
          <div className="row card_wrapper">
            <div className="col-lg-6 col-md-6 col-12 p-0">
              <div className="card_img">
                <img src="/images/sign-in-img.png" alt="sign-in-img" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 form_wrapper">
              <div className="form">
                <div className="title"><h1>Login</h1></div>
                <div className="row">
                  <div className="col-12">
                    <div className="input_wrapper">
                      <input type="text" name="userId" value={loginDetail.userId} onChange={(event) => setLoginDetail({ ...loginDetail, userId: event.target.value })} className="form-control" placeholder="Enter User ID" autoComplete="off" />
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                    {validator.message("userId", loginDetail.userId, "required", {
                      messages: {
                        required: "User ID is required"
                      }
                    })}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input_wrapper">
                      <input type={togglePassword ? "text" : "password"} value={loginDetail.password} name="password" onChange={(event) => setLoginDetail({ ...loginDetail, password: event.target.value })} className="form-control" placeholder="Enter Password" autoComplete="off" />
                      <FontAwesomeIcon icon={!togglePassword ? faLock : faUnlock} onClick={() => setTogglePassword(!togglePassword)} />
                    </div>
                    {validator.message("password", loginDetail.password, "required|min:8|max:16", {
                      messages: {
                        required: "Password is required"
                      }
                    })}
                  </div>
                </div>
                <div className="reset"><NavLink to="/reset-password"><span>Reset Password</span></NavLink></div>
                <button className="button" onClick={() => login()}>Login</button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Login