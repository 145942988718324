import { createSlice } from '@reduxjs/toolkit'

 const loaderReducer = createSlice({
    name: 'loader',
    initialState: {
        loaderState: false,
    },
    reducers: {
        showLoader: (state) => {
            state.loaderState = true;
        },
        hideLoader: (state) => {
            state.loaderState = false;
        },
    },
});

export const { showLoader, hideLoader } = loaderReducer.actions

export default loaderReducer.reducer