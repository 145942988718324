import React from 'react'
import SideNav from './SideNav'
import { Outlet } from 'react-router-dom'

const DashboardLayout = () => {
    return (

        <div className="dashboard_wrapper">
            <div className="side_nav_section">
                <SideNav />
            </div>
            <div className="routes_view_section">
                <Outlet></Outlet>
            </div>
        </div>
    )
}

export default DashboardLayout