import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputBox from "../../common-components/InputBox";
import { partyArray } from "../../utilities/partyArray";
import { electionTypeArray } from "../../utilities/electionTypeArray";
import { toggleModal } from "../../redux-store/modalReducer";
import { useDispatch } from "react-redux";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import FilterButton from "../../common-components/FilterButton";
import Swal from "sweetalert2";

const ElectionFilter = ({
  filterData,
  setFilterData,
  getElectionListBySearch,
  searchString,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();
  const [isSearch, setIsSearch] = useState(false);
  // search Filter
  const searchFilter = () => {
    if (
      filterData?.name ||
      filterData?.type ||
      filterData?.party ||
      filterData?.year
    ) {
      setCurrentPage(1);
      getElectionListBySearch(searchString, 1);
    } else {
      Swal.fire({ text: "At least one field is required", icon: "warning" });
      return false;
    }
  };

  return (
    <div className="modal_wrapper">
      <div className="card_wrapper">
        <div className="card_header">
          <div className="title">
            <h5>Filter</h5>
          </div>
          <div
            className="close_button"
            onClick={() => {
              dispatch(toggleModal(""));
              setFilterData({
                name: "",
                type: "",
                party: "",
                year: "",
              });
            }}
          >
           
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        <div className="card_body">
          <div className="row">
            <div className="col-4">
              <div className="input_box_wrapper">
                <InputBox
                  type="text"
                  name="name"
                  value={filterData.name}
                  onChange={(event) =>
                    setFilterData({ ...filterData, name: event.target.value })
                  }
                  placeholder="Enter Election Name"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="input_box_wrapper">
                <select
                  name="type"
                  onChange={(event) =>
                    setFilterData({ ...filterData, type: event.target.value })
                  }
                  className="form-select"
                >
                  <option value="">Select Election Type</option>
                  {electionTypeArray?.map((electionType, index) => {
                    return (
                      <option
                        value={electionType}
                        selected={electionType === filterData.type}
                        key={index}
                      >
                        {electionType}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="input_box_wrapper">
                <select
                  name="party"
                  onChange={(event) =>
                    setFilterData({ ...filterData, party: event.target.value })
                  }
                  className="form-select party_select_box"
                >
                  <option value="">Select Party</option>
                  {partyArray?.map((party, index) => {
                    return (
                      <option
                        value={party}
                        selected={party === filterData.party}
                        key={index}
                      >
                        {party}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="input_box_wrapper">
                <InputBox
                  type="number"
                  name="year"
                  value={filterData.year}
                  onChange={(event) =>
                    setFilterData({ ...filterData, year: event.target.value })
                  }
                  placeholder="Enter Election Year"
                />
              </div>
            </div>
            <div className="submit_button_wrapper">
              <FilterButton
                name="Add Filter"
                onClick={() => {
                  searchFilter();
                  setIsSearch(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectionFilter;
