import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import FilterButton from '../../../common-components/FilterButton'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate, faCircleLeft, faFileExport, faMagnifyingGlass, faSliders } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../common-components/Button'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toggleModal } from '../../../redux-store/modalReducer'
import Loader from '../../../utilities/Loader'
import { hideLoader, showLoader } from '../../../redux-store/loaderReducer'
import { communication } from '../../../services/communication'
import Swal from 'sweetalert2'
import FileSaver from 'file-saver';
import ImmediateFocusVoterFilter from './ImmediateFocusVoterFilter'
import Pagination from '../../../common-components/Pagination'

const ImmediateFocusVoterList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const modal = useSelector((state) => state.modalReducer.modal);
    const loader = useSelector((state) => state.loaderReducer.loaderState);
    const pageLimit = Number(process.env.REACT_APP_PAGE_LIMIT);
    const [toggleSearchInput, SetToggleSearchInput] = useState(false);
    const [pageCount, setPageCount] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [isPageUpdated, setIsPageUpdated] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [taluka] = useSearchParams();
    const [electionId] = useSearchParams();
    const [voterList, setVoterList] = useState([]);
    const [filterData, setFilterData] = useState(
        {
            voterId: "",
            gender: "",
            boothHead: ""
        }
    )

    //get Data by Filter
    const getDataByFilter = () => {
        fetchImmediateFocusVoterList(taluka.get("taluka"), electionId, searchValue, currentPage, filterData.boothHead, filterData.gender, filterData.voterId);
        dispatch(toggleModal(""));
    }

    //State to handle index numbers of list
    const [page, setPage] = useState();

    const handlePageClick = (e) => {
        if (currentPage > pageCount) {
            Swal.fire({ text: "Entered Page Cannot be grater than total Page", icon: "warning" });
            return false;
        } else {
            if (searchValue) {
                fetchVoterListBySearch(taluka.get("taluka"), electionId.get("electionId"), searchValue, currentPage, filterData.voterId, filterData.gender, filterData.boothHead)
            } else {
                fetchImmediateFocusVoterList(taluka.get("taluka"), electionId.get("electionId"), searchValue, currentPage, filterData.voterId, filterData.gender, filterData.boothHead)
            }
        }
    }

    //get immediate focus voter list by search
    const fetchVoterListBySearch = async (taluka, electionId, searchString, page) => {
        try {
            if (searchString || filterData.voterId || filterData.gender || filterData.boothHead) {
                const responseFromServer = await communication.getImmediateFocusVoterList(taluka, electionId, searchString, page, filterData.voterId, filterData.gender, filterData.boothHead);
                if (responseFromServer?.data?.status === "SUCCESS") {
                    setVoterList(responseFromServer?.data?.voter);
                    setPageCount(responseFromServer?.data?.totalPages);
                    setPage(page);
                    dispatch(toggleModal(""));
                } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                    Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
                    navigate("/login");
                } else {
                    setVoterList([]);
                    setPageCount(0);
                }
            }
        } catch (error) {
            Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
        }finally {
            dispatch(toggleModal(""));
        }
    }

    //get Immediate Focus Voter data on initial load
    const fetchImmediateFocusVoterList = async (taluka, electionId, searchString, page, voterId, gender, boothHead) => {
        try {
            dispatch(showLoader());
            const responseFromServer = await communication.getImmediateFocusVoterList(taluka, electionId, searchString, page, voterId, gender, boothHead);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setVoterList(responseFromServer?.data?.voter);
                setPageCount(responseFromServer?.data?.totalPages);
                setPage(page);
                setSearchValue();
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setVoterList([]);
                setPageCount(0);
            }
        } catch (error) {
            Swal.fire({ text: error?.response?.data?.message || error?.message, icon: "error" });
        } finally {
            dispatch(hideLoader());
            dispatch(toggleModal(""));
        }
    }

    //Export Immediate Focus Voter data
    const exportImmediateFocusVoterData = async () => {
        try {
            Swal.fire({
                // title: "Are you sure?",
                text: "Are you sure you want to export Voter Data?",
                showCancelButton: true,
                confirmButtonColor: '#38CAB3',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(async function (isConfirm) {
                if (isConfirm.isConfirmed) {
                    dispatch(showLoader());
                    const serverResponse = await communication.exportImmediateFocusVoterData(taluka.get("taluka"), electionId.get("electionId"), searchValue, currentPage, filterData.voterId, filterData.gender, filterData.boothHead)
                    if (serverResponse.data) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob([serverResponse.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        FileSaver.saveAs(file, "ImmediateFocusVoters.xlsx");
                    } else {
                        Swal.fire({ text: "Failed to export data in excel", icon: "warning" });
                    }
                    dispatch(hideLoader());
                } else {
                    return;
                }
            });
        } catch (error) {
            Swal.fire({ text: error.message, icon: "warning" });
        } finally {
            dispatch(hideLoader());
        }
    }

    // useEffect(() => {
    //     fetchImmediateFocusVoterList(taluka.get("taluka"), electionId.get("electionId"),);
    // }, []);

    useEffect(() => {
        handlePageClick();
    }, [isPageUpdated]);

    return (
        <>
            {loader === true ?
                <Loader />
                :
                <>
                    <div className="top_header">
                        <div className="title">Dashboard</div>
                        <div className="search_wrapper">
                            <input type="search" onChange={(event) => { fetchVoterListBySearch(taluka.get("taluka"), electionId.get("electionId"), event.target.value.trim(), 1); setPage(1); setCurrentPage(1); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="search..." style={{ width: `${(toggleSearchInput === false) ? "37px" : "250px"}`, borderRadius: `${(toggleSearchInput === false) ? "50%" : "5px"}` }} />
                            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={() => SetToggleSearchInput(!toggleSearchInput)} style={{ left: `${toggleSearchInput === false ? "50" : "90"}%` }} />
                        </div>
                    </div>
                    <section>
                        <div className="section_wrapper">
                            <div className="section_header_wrapper">
                                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} isPageUpdated={isPageUpdated} setIsPageUpdated={setIsPageUpdated} />
                                <div className="button_wrapper">
                                    <FontAwesomeIcon icon={faArrowsRotate} onClick={() => { fetchImmediateFocusVoterList(taluka.get("taluka"), electionId.get("electionId"),"",currentPage); setPage(currentPage); 
                                    // setCurrentPage(1); 
                                    setFilterData({}) }} className="refresh_icon" title="Refresh" />
                                    <Button icon={faFileExport} name="Export" onClick={() => exportImmediateFocusVoterData()} />
                                    <FilterButton name="Filter" icon={faSliders} onClick={() => dispatch(toggleModal("filter"))} />
                                    <FilterButton name="Back" icon={faCircleLeft} onClick={() => navigate("/dashboard/reports/immediate-focus")} />
                                </div>
                            </div>
                            <div className="table_wrapper voter_list_wrapper">
                                <div className="table">
                                    <div className="table_header">
                                        <div className="serial_number">
                                            <h5>Sr. No</h5>
                                        </div>
                                        <div className="name">
                                            <h5>Full Name</h5>
                                        </div>
                                        <div className="voterId">
                                            <h5>Voter ID</h5>
                                        </div>
                                        <div className="gender">
                                            <h5>Gender</h5>
                                        </div>
                                        <div className="name">
                                            <h5>Booth Head</h5>
                                        </div>
                                        <div className="booth_name">
                                            <h5>Booth Name</h5>
                                        </div>
                                        <div className="page_number">
                                            <h5>Page Number</h5>
                                        </div>
                                        <div className="mobile_number">
                                            <h5>Booth Head Mobile Number</h5>
                                        </div>
                                    </div>
                                    {voterList?.length > 0 ?
                                        <>
                                            {voterList?.map((voterDetail, index) => {
                                                return (
                                                    <div div className="table_data" key={index}>
                                                        <div className="serial_number">
                                                            <h6>{Number(pageLimit) * (page - 1) + (index + 1)}</h6>
                                                        </div>
                                                        <div className="name">
                                                            <h6>{voterDetail?.name}</h6>
                                                        </div>
                                                        <div className="voterId">
                                                            <h6>{voterDetail?.voterId}</h6>
                                                        </div>
                                                        <div className="gender">
                                                            <h6>{voterDetail?.gender}</h6>
                                                        </div>
                                                        <div className="name">
                                                            <h6>{voterDetail?.boothHead}</h6>
                                                        </div>
                                                        <div className="booth_name">
                                                            <h6>{voterDetail?.boothName}</h6>
                                                        </div>
                                                        <div className="page_number">
                                                            <h6>{voterDetail?.pageNo}</h6>
                                                        </div>
                                                        <div className="mobile_number">
                                                            <h6>{voterDetail?.boothHeadMobile ? voterDetail?.boothHeadMobile : "NA"}</h6>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                        :
                                        <div className="data_not_available_wrapper">
                                            Voters Not Available
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {modal === "filter" &&
                            <ImmediateFocusVoterFilter setFilterData={setFilterData} filterData={filterData} taluka={taluka.get("taluka")} electionId={electionId.get("electionId")} fetchVoterListBySearch={fetchVoterListBySearch} searchString={searchValue} setCurrentPage={setCurrentPage}/>
                        }
                    </section>
                </>
            }
        </>
    )
}

export default ImmediateFocusVoterList