import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../common-components/Button";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../utilities/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import Swal from "sweetalert2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { communication } from "../../services/communication";
import { toggleModal } from "../../redux-store/modalReducer";
import Pagination from "../../common-components/Pagination";

const DesignationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const pageLimit = process.env.REACT_APP_PAGE_LIMIT;
  const [designationList, setDesignationList] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isPageUpdated, setIsPageUpdated] = useState(false);
  const [designationId, setDesignationID] = useState([]);
  const [toggleSearchInput, SetToggleSearchInput] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  //State to handle index numbers of list
  const [page, setPage] = useState();

  const handlePageClick = (e) => {
    if (currentPage > pageCount) {
      Swal.fire({
        text: "Entered Page Cannot be grater than total Page",
        icon: "warning",
      });
      return false;
    } else {
      if (searchValue) {
        getDesignationListBySearch(searchValue, currentPage);
      } else {
        fetchDesignationList(searchValue, currentPage);
      }
    }
  };

  // Fetch Designation List on initial load
  const fetchDesignationList = async (searchString, page) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await communication.getDesignationList(
        searchString,
        page
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setDesignationList(responseFromServer?.data?.designation);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(page);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        dispatch(hideLoader());
        setDesignationList([]);
        setPageCount(0);
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  // get DesignationList by search
  const getDesignationListBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await communication.getDesignationList(
        searchString,
        page
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        setDesignationList(responseFromServer?.data?.designation);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(page);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setDesignationList([]);
        setPageCount(0);
      }
      if (searchValue === "") {
        dispatch(toggleModal(""));
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (searchValue?.trim() !== "") {
  //       getDesignationListBySearch(searchValue?.trim(), 1);
  //     } else {
  //       fetchDesignationList("", 1);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [searchValue]);
  useEffect(() => {
    if (searchValue.trim() !== "") {
      // If search value is present,we get designation list by search
      getDesignationListBySearch(searchValue.trim(), 1);
    } else {
      // Otherwise, fetch designation list with empty search string
      fetchDesignationList("", 1);
    }
  }, [searchValue]); 

  // useEffect(() => {
  //   handlePageClick();
  // }, [isPageUpdated]);

  useEffect(() => {
 
    // console.log("isPageUpdated", isPageUpdated);

    if (isPageUpdated) {
      
      handlePageClick();
      setIsPageUpdated(false); 
    }
  }, [isPageUpdated]);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">DesignationList</div>
            <div className="search_wrapper">
              <input
                type="search"
                onChange={(event) => {
                  // getDesignationListBySearch(event.target.value.trim(), 1);
                  setSearchValue(event.target.value.trim());
                  setPage(1);
                  setCurrentPage(1);
                }}
                className="form-control"
                placeholder="search..."
                style={{
                  width: `${toggleSearchInput === false ? "37px" : "250px"}`,
                  borderRadius: `${
                    toggleSearchInput === false ? "50%" : "5px"
                  }`,
                }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() => {
                  setSearchValue("");
                  SetToggleSearchInput(!toggleSearchInput);
                  toggleSearchInput &&
                    searchValue !== "" &&
                    fetchDesignationList("", 1);
                }}
                style={{
                  left: `${toggleSearchInput === false ? "50" : "90"}%`,
                  padding: "16px",
                }}
              />
            </div>
          </div>
          <section>
            <div className="section_wrapper">
              <div className="section_header_wrapper">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  isPageUpdated={isPageUpdated}
                  setIsPageUpdated={setIsPageUpdated}
                />
                <div className="button_wrpper">
                  <Button
                    icon={faPlus}
                    name="Add Designation"
                    onClick={() =>
                      navigate(
                        "/dashboard/designation-management/add-designation"
                      )
                    }
                  />
                </div>
              </div>

              <div className="table_wrapper">
                <div className="table">
                  <div className="text-center">
                    <div className="table_header">
                      <div className="col-lg-2 col-md-2">
                        <h5>Sr.No</h5>
                      </div>
                      <div className="col-lg-5 col-md-5">
                        <h5>Designation</h5>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <h5>Creation Data</h5>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <h5> Action</h5>
                      </div>
                    </div>
                  </div>

                  {designationList?.length > 0 ? (
                    <>
                      {designationList?.map((designation, index) => {
                        return (
                          <div className=" table_data text-center" key={index}>
                            <div className="col-lg-2 col-md-2">
                              <h6>
                                {Number(pageLimit) * (page - 1) + (index + 1)}
                              </h6>
                            </div>
                            <div className="col-lg-5 col-md-5">
                              <h6>{designation.name}</h6>
                            </div>
                            <div className="col-lg-3 col-md-3">
                              <h6>
                                {new Date(
                                  designation?.createdAt
                                ).toLocaleDateString()}
                              </h6>
                            </div>

                            <div className="col-lg-2 col-md-2 action_designation">
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                onClick={() =>
                                  navigate(
                                    `/dashboard/designation-management/update-designation?id=${designation._id}`
                                  )
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="data_not_available_wrapper">
                      Designation Not Available
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default DesignationList;
