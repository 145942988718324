import React from 'react'
import FilterButton from '../../common-components/FilterButton';
import InputBox from '../../common-components/InputBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { toggleModal } from '../../redux-store/modalReducer';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import {genderArray} from '../../utilities/genderArray';

const SurveyTrendsFilter = ({ filterData, setFilterData, getSurveyTrendsVoterListBySearch,searchString,setCurrentPage }) => {
  const dispatch = useDispatch();

  //filter survey trend list
  const filter = () => {
    if (filterData.city || filterData.voterId || filterData.gender) {
      getSurveyTrendsVoterListBySearch(searchString,1);
      setCurrentPage(1);
      dispatch(toggleModal(""));
    } else {
      Swal.fire({ text: "At least one field is required", icon: "warning" });
      return false
    }
  }

  return (
    <div className="modal_wrapper">
      <div className="card_wrapper">
        <div className="card_header">
          <div className="title">
            <h5>Filter</h5>
          </div>
          <div className="close_button" onClick={() => {dispatch(toggleModal(""));
          setFilterData(
            {
              city: "",
              voterId: "",
              gender: "",
              electionId: ""
            }
          )
        }}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        <div className="card_body">
          <div className="row">
            <div className="col-4">
              <div className="input_box_wrapper">
                <InputBox type="text" name="city" onChange={(event) => setFilterData({ ...filterData,city: event.target.value })} placeholder="Enter Village/City" />
              </div>
            </div>
            <div className="col-4">
              <div className="input_box_wrapper">
                <InputBox type="text" name="voterId" onChange={(event) => setFilterData({ ...filterData, voterId: event.target.value })} placeholder="Enter Voter ID" />
              </div>
            </div>
            <div className="col-4">
              <div className="input_box_wrapper">
                <select name="gender" onChange={(event) => setFilterData({ ...filterData, gender: event.target.value })} className="form-select">
                  <option value="">Select Gender</option>
                  {genderArray?.map((gender, index) => {
                    return (
                      <option value={gender} selected={gender === filterData.gender} key={index}>{gender}</option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="submit_button_wrapper">
              <FilterButton name="Add Filter" onClick={() => filter()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SurveyTrendsFilter