import React, { useState } from "react";
import FilterButton from "./FilterButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { toggleModal } from "../redux-store/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { communication } from "../services/communication";
import useValidator from "../utilities/useValidator";
import { useNavigate } from "react-router-dom";
import { hideLoader, showLoader } from "../redux-store/loaderReducer";
import Loader from "../utilities/Loader";

const ImportData = ({ tabName, fetchOrganizerList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validator, showValidationMessage] = useValidator();
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [file, setFile] = useState("");

  //Import Data
  const importData = async () => {
    try {
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      const formData = new FormData();
      formData.append("file", file);
      dispatch(showLoader());
      const serverResponse = await communication.importOrganizeData(formData);
      if (serverResponse?.data?.status === "SUCCESS") {
        Swal.fire({ text: serverResponse?.data?.message, icon: "success" });
        dispatch(toggleModal(""));
        fetchOrganizerList();
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <div className="modal_wrapper">
          <div className="card_wrapper">
            <div className="card_header">
              <div className="title">
                <h5>Upload File</h5>
              </div>
              <div
                className="close_button"
                onClick={() => dispatch(toggleModal(""))}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div className="card_body">
              <div className="import_data_wrapper">
                <input
                  type="file"
                  name="file"
                  onChange={(event) => setFile(event.target.files[0])}
                  id="file"
                  style={{ display: "none" }}
                />
                <label for="file">
                  <FontAwesomeIcon icon={faArrowUpFromBracket} />
                  {file ? (
                    <h6>{file?.name}</h6>
                  ) : (
                    <h6>
                      Choose file <span>to Upload</span>
                    </h6>
                  )}
                </label>
                {validator.message("file", file, "required", {
                  messages: {
                    required: "File is required",
                  },
                })}
              </div>
              <div className="submit_button_wrapper">
                <FilterButton name="Upload" onClick={() => importData()} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImportData;
