import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../common-components/Button";
import FilterButton from "../../common-components/FilterButton";
import AddNewElection from "./AddNewElection";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../redux-store/modalReducer";
import UpdateElection from "./UpdateElection";
import ElectionFilter from "./ElectionFilter";
import Loader from "../../utilities/Loader";
import { communication } from "../../services/communication";
import { hideLoader, showLoader } from "../../redux-store/loaderReducer";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Pagination from "../../common-components/Pagination";

const ElectionList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useSelector((state) => state.modalReducer.modal);
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const pageLimit = Number(process.env.REACT_APP_PAGE_LIMIT);
  const [toggleSearchInput, SetToggleSearchInput] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [electionList, setElectionList] = useState([]);
  const [electionId, setElectionId] = useState("");
  const [filterData, setFilterData] = useState({
    name: "",
    type: "",
    party: "",
    year: "",
  });

  const [page, setPage] = useState();
  const [isPageUpdated, setIsPageUpdated] = useState(false);

  //set current page on click
  const handlePageClick = (e) => {
    if (currentPage > pageCount) {
      Swal.fire({
        text: "Entered Page Cannot be grater than total Page",
        icon: "warning",
      });
      return false;
    } else {
      if (searchValue) {
        getElectionListBySearch(
          searchValue,
          currentPage,
          filterData.name,
          filterData.type,
          filterData.party,
          filterData.year
        );
      } else {
        fetchElectionList(
          searchValue,
          currentPage,
          filterData.name,
          filterData.type,
          filterData.party,
          filterData.year
        );
      }
    }
  };

  //Get Election List by search
  const getElectionListBySearch = async (searchString, page) => {
    try {
      if (
        ![undefined, null, ""].includes(searchString) ||
        ![undefined, null, ""].includes(filterData.name) ||
        ![undefined, null, ""].includes(filterData.type) ||
        ![undefined, null, ""].includes(filterData.party) ||
        ![undefined, null, ""].includes(filterData.year)
      ) {
        const responseFromServer = await communication.getElectionList(
          searchString,
          page,
          filterData.name,
          filterData.type,
          filterData.party,
          filterData.year
        );
        if (responseFromServer?.data?.status === "SUCCESS") {
          setElectionList(responseFromServer?.data?.elections);
          setPageCount(responseFromServer?.data?.totalPages);
          setPage(page);
        } else if (responseFromServer?.data?.status === "JWT_INVALID") {
          Swal.fire({
            text: responseFromServer?.data?.message,
            icon: "warning",
          });
          navigate("/login");
        } else {
          setElectionList([]);
          setPageCount(0);
        }
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    } finally {
      dispatch(toggleModal(""));
    }
  };

  //Fetch Election List on initial load
  const fetchElectionList = async (
    searchString,
    page,
    name,
    type,
    party,
    year
  ) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await communication.getElectionList(
        searchString,
        page,
        name,
        type,
        party,
        year
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setElectionList(responseFromServer?.data?.elections);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(currentPage);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        dispatch(hideLoader());
        setElectionList([]);
        setPageCount(0);
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
      }
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };
  // start election
  const startElection = async (electionId) => {
    Swal.fire({
      text: " Are you sure you want to start election?",
      showCancelButton: true,
      confirmButtonColor: "#38CAB3",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        dispatch(showLoader());
        const responseFromServer = await communication.getElectionStart(
          electionId
        );
        if (responseFromServer?.data?.status === "SUCCESS") {
          Swal.fire({
            text: responseFromServer?.data?.message,
            icon: "success",
          });
          fetchElectionList();
        } else if (responseFromServer?.data?.status === "JWT_INVALID") {
          Swal.fire({
            text: responseFromServer?.data?.message,
            icon: "warning",
          });
          navigate("/login");
        } else {
          Swal.fire({
            text: responseFromServer?.data?.message,
            icon: "warning",
          });
        }
        dispatch(hideLoader());
      } else {
        return;
      }
    });
  };

  // Election Completed
  const electionCompleted = async (electionId) => {
    Swal.fire({
      text: " Are you sure you want to completed the election?",
      showCancelButton: true,
      confirmButtonColor: "#38CAB3",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        dispatch(showLoader());
        const responseFromServer = await communication.getElectionCompleted(
          electionId,
          "completed"
        );
        if (responseFromServer?.data?.status === "SUCCESS") {
          Swal.fire({
            text: responseFromServer?.data?.message,
            icon: "success",
          });
          fetchElectionList();
        } else if (responseFromServer?.data?.status === "JWT_INVALID") {
          Swal.fire({
            text: responseFromServer?.data?.message,
            icon: "warning",
          });
          navigate("/login");
        } else {
          Swal.fire({
            text: responseFromServer?.data?.message,
            icon: "warning",
          });
        }
        dispatch(hideLoader());
      } else {
        return;
      }
    });
  };
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (searchValue?.trim() !== "") {
  //       getElectionListBySearch(searchValue?.trim(), 1);
  //     } else {
  //       fetchElectionList("", 1);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [searchValue]);

  useEffect(() => {
    if (searchValue.trim() !== "") {
      // If search value is present,we get designation list by search
      getElectionListBySearch(searchValue.trim(), 1);
    } else {
      // Otherwise, fetch designation list with empty search string
      fetchElectionList("", 1);
    }
  }, [searchValue]); 

  useEffect(() => {
    handlePageClick();
  }, [isPageUpdated]);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">Election Management</div>
            <div className="search_wrapper">
              <input
                type="search"
                value={searchValue}
                onChange={(event) => {
                  // getElectionListBySearch(event.target.value.trim(), 1);
                  setPage(1);
                  setCurrentPage(1);
                  setSearchValue(event.target.value.trim());
                }}
                className="form-control"
                placeholder="search..."
                style={{
                  width: `${toggleSearchInput === false ? "37px" : "250px"}`,
                  borderRadius: `${
                    toggleSearchInput === false ? "50%" : "5px"
                  }`,
                }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() => {
                  SetToggleSearchInput(!toggleSearchInput);
                  setSearchValue("");
                  toggleSearchInput &&
                    searchValue !== "" &&
                    fetchElectionList("", 1);
                }}
                style={{
                  left: `${toggleSearchInput === false ? "50" : "90"}%`,
                  padding: "16px",
                }}
              />
            </div>
          </div>
          <section>
            <div className="section_wrapper">
              <div className="section_header_wrapper">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  isPageUpdated={isPageUpdated}
                  setIsPageUpdated={setIsPageUpdated}
                />
                <div className="button_wrapper">
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    onClick={() => {
                      fetchElectionList("", currentPage);
                      setPage(currentPage);
                      //setCurrentPage(1);
                      setFilterData({});
                      setSearchValue("");
                    }}
                    className="refresh_icon"
                    title="Refresh"
                  />
                  <Button
                    icon={faPlus}
                    name="Add New Election"
                    onClick={() => dispatch(toggleModal("add_election"))}
                  />
                  <FilterButton
                    name="Filter"
                    icon={faSliders}
                    onClick={() => dispatch(toggleModal("filter"))}
                  />
                </div>
              </div>
              <div className="table_wrapper">
                <div className="table election_table">
                  <>
                    <div className="table_header">
                      <div className="serial_number">
                        <h5>Sr. No</h5>
                      </div>
                      <div className="election_name">
                        <h5>Election Name</h5>
                      </div>
                      <div className="election_type">
                        <h5>Election Type</h5>
                      </div>
                      <div className="party">
                        <h5>Party</h5>
                      </div>
                      <div className="election_year">
                        <h5>Election Year</h5>
                      </div>
                      <div className="date">
                        <h5>Election Date</h5>
                      </div>
                      <div className="election_action">
                        <h5>Action</h5>
                      </div>
                    </div>
                    {electionList?.length > 0 ? (
                      <>
                        {electionList?.map((electionDetail, index) => {
                          return (
                            <div className="table_data" key={index}>
                              <div className="serial_number">
                                <h6>
                                  {Number(pageLimit) * (page - 1) + (index + 1)}
                                </h6>
                              </div>
                              <div className="election_name">
                                <h6>{electionDetail?.name}</h6>
                              </div>
                              <div className="election_type">
                                <h6>{electionDetail?.type}</h6>
                              </div>
                              <div className="party">
                                <h6>{electionDetail?.party}</h6>
                              </div>
                              <div className="election_year">
                                <h6>{electionDetail?.year}</h6>
                              </div>
                              <div className="date">
                                <h6>
                                  {![undefined, null, ""].includes(
                                    electionDetail?.electionDate
                                  )
                                    ? new Date(
                                        electionDetail?.electionDate
                                      ).toLocaleDateString()
                                    : "-"}
                                </h6>
                              </div>
                              <div className="election_action">
                                {electionDetail?.status === "new" ? (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faPenToSquare}
                                      onClick={() => {
                                        dispatch(
                                          toggleModal("update_election")
                                        );
                                        setElectionId(electionDetail?._id);
                                      }}
                                    />
                                    <button
                                      className="action_button"
                                      onClick={() =>
                                        startElection(electionDetail?._id)
                                      }
                                    >
                                      Start
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    {electionDetail?.status === "start" ? (
                                      <button
                                        className="action_button"
                                        onClick={() =>
                                          electionCompleted(electionDetail?._id)
                                        }
                                      >
                                        Mark Completed
                                      </button>
                                    ) : (
                                      <h6>Completed</h6>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="data_not_available_wrapper">
                        Election Not Available
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
            {modal === "add_election" && (
              <AddNewElection fetchElectionList={fetchElectionList} />
            )}
            {modal === "update_election" && (
              <UpdateElection
                electionId={electionId}
                fetchElectionList={fetchElectionList}
              />
            )}
            {modal === "filter" && (
              <ElectionFilter
                filterData={filterData}
                setFilterData={setFilterData}
                getElectionListBySearch={getElectionListBySearch}
                searchString={searchValue}
                setCurrentPage={setCurrentPage}
              />
            )}
          </section>
        </>
      )}
    </>
  );
};

export default ElectionList;
