import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputBox from "../../common-components/InputBox";
import { toggleModal } from "../../redux-store/modalReducer";
import { useDispatch } from "react-redux";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import FilterButton from "../../common-components/FilterButton";
import { useEffect } from "react";
import { communication } from "../../services/communication";
import Swal from "sweetalert2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { showLoader } from "../../redux-store/loaderReducer";

const OrganizerFilter = ({
  filterDetail,
  setFilterDetail,
  getOrganizerListBySearch,
  searchString,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [designationList, setDesignationList] = useState([]);

  //search filter
  const searchFilter = () => {
    if (
      filterDetail.city ||
      filterDetail.designationId ||
      filterDetail.voterId
    ) {
      setCurrentPage(1);
      getOrganizerListBySearch(searchString, 1);
    } else {
      Swal.fire({ text: "At least one field is required", icon: "warning" });
      return false;
    }
  };
  //get all designation list
  const fetchDesignationList = async () => {
    try {
      const responseFromServer = await communication.getAllDesignations();
      if (responseFromServer?.data?.status === "SUCCESS") {
        // dispatch(showLoader());
        setDesignationList(responseFromServer?.data?.designation);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setDesignationList([]);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    fetchDesignationList();
  }, []);

  return (
    <div className="modal_wrapper">
      <div className="card_wrapper">
        <div className="card_header">
          <div className="title">
            <h5>Filter</h5>
          </div>
          <div
            className="close_button"
            onClick={() => {
              dispatch(toggleModal(""));
              setFilterDetail({
                city: "",
                designationId: "",
                voterId: "",
              });
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        <div className="card_body">
          <div className="row">
            <div className="col-4">
              <div className="input_box_wrapper">
                <InputBox
                  type="text"
                  name="city"
                  value={filterDetail.city}
                  onChange={(event) =>
                    setFilterDetail({
                      ...filterDetail,
                      city: event.target.value,
                    })
                  }
                  placeholder="Enter Village/City Name"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="input_box_wrapper">
                <select
                  name="designationId"
                  onChange={(event) =>
                    setFilterDetail({
                      ...filterDetail,
                      designationId: event.target.value,
                    })
                  }
                  className="form-select"
                >
                  <option value="">Select Designation</option>
                  {designationList?.map((designation, index) => {
                    return (
                      <option
                        value={designation?._id}
                        selected={
                          designation?._id === filterDetail.designationId
                        }
                        key={index}
                      >
                        {designation?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-4">
              <div className="input_box_wrapper">
                <InputBox
                  type="text"
                  name="voterId"
                  value={filterDetail.voterId}
                  onChange={(event) =>
                    setFilterDetail({
                      ...filterDetail,
                      voterId: event.target.value,
                    })
                  }
                  placeholder="Enter Voter ID"
                />
              </div>
            </div>
            <div className="submit_button_wrapper">
              <FilterButton name="Add Filter" onClick={() => searchFilter()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizerFilter;
