import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useValidator from "../../utilities/useValidator";
import { communication } from "../../services/communication";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../redux-store/loaderReducer";
import { toggleModal } from "../../redux-store/modalReducer";
import Loader from "../../utilities/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import FilterButton from "../../common-components/FilterButton";

const ImportDataVoter = ({ fetchVoterList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validator, showValidationMessage] = useValidator();
  const [electionList, setElectionList] = useState([]);
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [data, setData] = useState({
    file: "",
    electionId: "",
  });

  //get all election list
  const fetchElectionList = async () => {
    try {
      const responseFromServer = await communication.getAllElectionList();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setElectionList(responseFromServer?.data?.election);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setElectionList([]);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };
  //Import Data
  const importData = async () => {
    try {
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      const formData = new FormData();
      formData.append("file", data?.file);
      dispatch(showLoader());
      const serverResponse = await communication.importExcelData(
        data.electionId,
        formData
      );
      if (serverResponse?.data?.status === "SUCCESS") {
        Swal.fire({ text: serverResponse?.data?.message, icon: "success" });
        dispatch(toggleModal(""));
        fetchVoterList();
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
      }
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };
  useEffect(() => {
    fetchElectionList();
  }, []);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <div className="modal_wrapper">
          <div className="card_wrapper">
            <div className="card_header">
              <div className="title">
                <h5>Upload File</h5>
              </div>
              <div
                className="close_button"
                onClick={() => dispatch(toggleModal(""))}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div className="card_body">
              <div className="import_data_wrapper">
                <div className="input_box_wrapper">
                  <select
                    name="electionId"
                    onChange={(event) =>
                      setData({ ...data, electionId: event.target.value })
                    }
                    className="form-select"
                  >
                    <option value="">Select Election</option>
                    {electionList
                      ?.filter((ele) => ele.status === "new")
                      ?.map((electionDetail, index) => {
                        return (
                          <option
                            value={electionDetail?._id}
                            selected={electionDetail?._id === data.electionId}
                            key={index}
                          >
                            {electionDetail?.name}
                          </option>
                        );
                      })}
                  </select>
                  {validator.message(
                    "ElectionId",
                    data.electionId,
                    "requried",
                    {
                      message: {
                        require: "Election is Required",
                      },
                    }
                  )}
                </div>
                <div>
                  <input
                    type="file"
                    name="file"
                    onChange={(event) =>
                      setData({ ...data, file: event.target.files[0] })
                    }
                    id="file"
                    style={{ display: "none" }}
                  />
                  <label for="file">
                    <FontAwesomeIcon icon={faArrowUpFromBracket} />
                    {data?.file ? (
                      <h6>{data?.file?.name}</h6>
                    ) : (
                      <h6>
                        Choose file <span>to Upload</span>
                      </h6>
                    )}
                  </label>
                  {validator.message("file", data?.file, "required", {
                    messages: {
                      required: "File is required",
                    },
                  })}
                </div>
              </div>
              <div className="submit_button_wrapper">
                <FilterButton
                  name="Upload"
                  onClick={() => importData()}
                ></FilterButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImportDataVoter;
