import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SurveyAnalysisGraph = ({ graphData }) => {

    const labels = graphData?.labels;

    const data = {
        labels,
        datasets: [
            {
                label: graphData?.datasets[0]?.label,
                data:graphData?.datasets[0]?.data,
                //data: graphData?.datasets?.map((ele) => { return ele?.data[0] }),
                backgroundColor: "#3BCD90",
                barPercentage: 0.5,

            },
            {
                label: graphData?.datasets[1]?.label,
                data:graphData?.datasets[1]?.data,
                //data: graphData?.datasets?.map((ele) => { return ele?.data[1] }),
                backgroundColor: "#25D2DC",
                barPercentage: 0.5
            },
            {
                label: graphData?.datasets[2]?.label,
                data:graphData?.datasets[2]?.data,
                //data: graphData?.datasets?.map((ele) => { return ele?.data[2] }),
                backgroundColor: "#FA6D83",
                barPercentage: 0.5
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
                labels: {
                    font: { size: 12 },
                }
            },
            title: {
                display: true,
                text: "Survey Report",
            }
        },
        // layout: { padding: { left: 50, right: 50, top: 0, bottom: 0 } }
    };

    return (
        <div className="survey_analysis_graph_wrapper">
            <div className="graph_main">
                <Bar options={options} data={data} />
            </div>
        </div>
    )
}

export default SurveyAnalysisGraph