import { createSlice } from '@reduxjs/toolkit'

const modalReducer = createSlice({
    name: "modal",
    initialState: {
        modal: "",
    },
    reducers: {
        toggleModal: (state, action) => {
            state.modal = action.payload !== "" ? action.payload : "";
        }
    }
});

export const { toggleModal } = modalReducer.actions;
export default modalReducer.reducer;