import React, { useState, useEffect } from "react";
import { getServerUrl } from "../../../services/communication";
import { io } from "socket.io-client";
import { graphSvgArray } from "../../../utilities/graphSvgArray";
import Swal from "sweetalert2";

const VotingAreasGraph = () => {
  const [socket, setSocket] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    const socketConnection = io(getServerUrl(), { transports: ["websocket"] });

  //   socketConnection.on("connect_error", (error) => {
  //     Swal.fire({
  //     icon:'warning',
  //     // title:'Socket not connected',
  //     text:'The socket connection has been disconnected'
  //     })
  //     console.log(`connect_error due to ${error.message}`);
  // });

    setSocket(socketConnection);
    return () => {
      socketConnection.disconnect();
      console.log("disconnect");
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("getElectionReport", (graph) => {
        setData(graph?.talukaWiseData);
      });
    }
  }, [socket]);

  return (
    <div className="msi_panel_card_wrapper">
      <div className="title">
        <h5>Voting Areas Graph</h5>
      </div>
      <div className="row main pt-3">
        <div className="col-4">
          <div className="voting_area_label_wrapper">
            <div className="voting_area_label">
              <div className="label_color_box"></div>
              <h6>High Voting</h6>
            </div>
            <div className="voting_area_label">
              <div className="label_color_box"></div>
              <h6>Average Voting</h6>
            </div>
            <div className="voting_area_label">
              <div className="label_color_box"></div>
              <h6>Low Voting</h6>
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="voting_area_graph_wrapper">
            {data?.map((graphData, index) => {
              return (
                <div
                  className="svg_wrapper"
                  key={index}
                  title={graphData?.area}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={graphData?.viewBox}
                  >
                    <path
                      fill={graphData?.fill}
                      d={graphData?.d}
                      transform={graphData?.transform}
                    />
                  </svg>
                  <h6>{graphData?.area}</h6>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VotingAreasGraph;
