import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowRight,
  faChartLine,
  faCheckToSlot,
  faFileArrowDown,
  faFileCircleCheck,
  faGauge,
  faHandHoldingDollar,
  faHandshake,
  faListCheck,
  faPeopleRoof,
  faPersonBooth,
  faRightToBracket,
  faSitemap,
  faUser,
  faUsers,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import DashboardDropdown from "../pages/dropdowns/DashboardDropdown";
import SurveyManagementDropdown from "../pages/dropdowns/SurveyManagementDropdown";
import DataAnalysisDropdown from "../pages/dropdowns/DataAnalysisDropdown";
import VotingDayDropdown from "../pages/dropdowns/VotingDayDropdown";
import { removeUserDetails } from "../redux-store/userDetailReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toggleModal } from "../redux-store/modalReducer";

const SideNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLogoutBox, setShowLogoutBox] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState({
    dashboardDropdown: false,
    surveyDropdown: false,
    dataAnalysisDropdown: false,
    votingDayDropdown: false,
  });
  const loginUserDetails = useSelector((state) => state.userDetailReducer);

  const logout = async () => {
    Swal.fire({
      text: " Are you sure you want to logout?",
      showCancelButton: true,
      confirmButtonColor: "#38CAB3",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        dispatch(removeUserDetails());
        navigate("/login");
      } else {
        return;
      }
    });
  };
  return (
    <div className="side_nav_wrapper" onClick={() => dispatch(toggleModal(""))}>
      <div className="logo">
        <h1>Victory</h1>
      </div>
      <div className="nav_list_wrapper">
        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs?.includes("Dashboard") ? (
          <div
            className="tab"
            onClick={() =>
              setToggleDropdown({
                ...toggleDropdown,
                dashboardDropdown: !toggleDropdown.dashboardDropdown,
                surveyDropdown: false,
                dataAnalysisDropdown: false,
                votingDayDropdown: false,
              })
            }
          >
            <div className="icon">
              <FontAwesomeIcon icon={faGauge} />
            </div>
            <div className="title">
              <span>Dashboard</span>
            </div>
            <div className="caret">
              <FontAwesomeIcon
                icon={faAngleDown}
                style={{
                  transform: `${
                    toggleDropdown.dashboardDropdown === false
                      ? "rotate(0deg)"
                      : "rotate(180deg)"
                  }`,
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {toggleDropdown.dashboardDropdown && <DashboardDropdown />}

        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs?.includes("Election Management") ? (
          <NavLink to="/dashboard/election-management">
            <div className="tab">
              <div className="icon">
                <FontAwesomeIcon icon={faPeopleRoof} />
              </div>
              <div className="title">
                <span>Election Management</span>
              </div>
            </div>
          </NavLink>
        ) : (
          <></>
        )}
        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs?.includes("Designation Management") ? (
          <NavLink to="/dashboard/designation-management">
            <div className="tab">
              <div className="icon">
                <FontAwesomeIcon icon={faUsers} />
              </div>
              <div className="title">
                <span>Designation Management</span>
              </div>
            </div>
          </NavLink>
        ) : (
          <></>
        )}

        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs?.includes("Organization Data") ? (
          <NavLink to="/dashboard/organization-management">
            <div className="tab">
              <div className="icon">
                <FontAwesomeIcon icon={faSitemap} />
              </div>
              <div className="title">
                <span>Organization Data</span>
              </div>
            </div>
          </NavLink>
        ) : (
          <></>
        )}

        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs?.includes("Voter Data") ? (
          <NavLink to="/dashboard/voter-data">
            <div className="tab">
              <div className="icon">
                <FontAwesomeIcon icon={faCheckToSlot} />
              </div>
              <div className="title">
                <span>Voter Data</span>
              </div>
            </div>
          </NavLink>
        ) : (
          <></>
        )}

        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs?.includes("Beneficial List") ? (
          <NavLink to="/dashboard/beneficial-voters">
            <div className="tab">
              <div className="icon">
                <FontAwesomeIcon icon={faHandHoldingDollar} />
              </div>
              <div className="title">
                <span>Beneficial List</span>
              </div>
            </div>
          </NavLink>
        ) : (
          <></>
        )}
        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs?.includes("Comfirm Voters") ? (
          <NavLink to="/dashboard/confirmed-voters">
            <div className="tab">
              <div className="icon">
                <FontAwesomeIcon icon={faHandshake} />
              </div>
              <div className="title">
                <span>Confirmed Voters</span>
              </div>
            </div>
          </NavLink>
        ) : (
          <></>
        )}
        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs?.includes("Survey Management") ? (
          <div
            className="tab"
            onClick={() =>
              setToggleDropdown({
                ...toggleDropdown,
                surveyDropdown: !toggleDropdown.surveyDropdown,
                dashboardDropdown: false,
                dataAnalysisDropdown: false,
                votingDayDropdown: false,
              })
            }
          >
            <div className="icon">
              <FontAwesomeIcon icon={faListCheck} />
            </div>
            <div className="title">
              <span>Survey Management</span>
            </div>
            <div className="caret">
              <FontAwesomeIcon
                icon={faAngleDown}
                style={{
                  transform: `${
                    toggleDropdown.surveyDropdown === false
                      ? "rotate(0deg)"
                      : "rotate(180deg)"
                  }`,
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {toggleDropdown.surveyDropdown && <SurveyManagementDropdown />}
        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs?.includes("Data Analysis") ? (
          <div
            className="tab"
            onClick={() =>
              setToggleDropdown({
                ...toggleDropdown,
                dataAnalysisDropdown: !toggleDropdown.dataAnalysisDropdown,
                dashboardDropdown: false,
                surveyDropdown: false,
                votingDayDropdown: false,
              })
            }
          >
            <div className="icon">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <div className="title">
              <span>Data Analysis</span>
            </div>
            <div className="caret">
              <FontAwesomeIcon
                icon={faAngleDown}
                style={{
                  transform: `${
                    toggleDropdown.dataAnalysisDropdown === false
                      ? "rotate(0deg)"
                      : "rotate(180deg)"
                  }`,
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {toggleDropdown.dataAnalysisDropdown && <DataAnalysisDropdown />}

        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs.includes("Voting Day") ? (
          <div
            className="tab"
            onClick={() =>
              setToggleDropdown({
                ...toggleDropdown,
                votingDayDropdown: !toggleDropdown.votingDayDropdown,
                dashboardDropdown: false,
                surveyDropdown: false,
                dataAnalysisDropdown: false,
              })
            }
          >
            <div className="icon">
              <FontAwesomeIcon icon={faPersonBooth} />
            </div>
            <div className="title">
              <span>Voting Day</span>
            </div>
            <div className="caret">
              <FontAwesomeIcon
                icon={faAngleDown}
                style={{
                  transform: `${
                    toggleDropdown.votingDayDropdown === false
                      ? "rotate(0deg)"
                      : "rotate(180deg)"
                  }`,
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {toggleDropdown.votingDayDropdown && <VotingDayDropdown />}
        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs?.includes("Activity Management") ? (
          <NavLink to="/dashboard/activity-management/activity-list">
            <div className="tab">
              <div className="icon">
                <FontAwesomeIcon icon={faFileCircleCheck} />
              </div>
              <div className="title">
                <span>Activity Management</span>
              </div>
            </div>
          </NavLink>
        ) : (
          <></>
        )}

        {loginUserDetails?.designation?.toLowerCase() === "admin" ||
        loginUserDetails?.tabs?.includes("Download Template") ? (
          <NavLink to="/dashboard/templates">
            <div className="tab">
              <div className="icon">
                <FontAwesomeIcon icon={faFileArrowDown} />
              </div>
              <div className="title">
                <span>Download Templates</span>
              </div>
            </div>
          </NavLink>
        ) : (
          <></>
        )}
      </div>

      <div className="logout_box_wrapper">
        {showLogoutBox === false ? (
          <div
            className="box_main"
            style={{
              transform: `${
                showLogoutBox === false ? "translateX(0%)" : "translateX(-100%)"
              }`,
            }}
          >
            <div
              className="profile_box"
              title="Click to see profile"
              onClick={() => setShowLogoutBox(true)}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        ) : (
          <div
            className="profile_box_wrapper"
            style={{
              transform: `${
                showLogoutBox === false ? "translateX(100%)" : "translateX(0%)"
              }`,
            }}
          >
            <NavLink to="/dashboard/profile">
              <div className="profile">
                <div className="profile_title">
                  <span>Profile</span>
                </div>
                <div className="profile_icon">
                  <FontAwesomeIcon icon={faUser} />
                </div>
              </div>
            </NavLink>
            <div className="profile" onClick={() => logout()}>
              <div className="profile_title">
                <span>Logout</span>
              </div>
              <div className="profile_icon">
                <FontAwesomeIcon icon={faRightToBracket} />
              </div>
            </div>
            <div
              className="close_button"
              onClick={() => setShowLogoutBox(false)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideNav;
