import React, { useEffect } from "react";
import Loader from "../../utilities/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faCheckToSlot,
  faMagnifyingGlass,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterButton from "../../common-components/FilterButton";
import { hideLoader, showLoader } from "../../redux-store/loaderReducer";
import { communication } from "../../services/communication";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import BeneficialFilter from "./BeneficialFilter";
import { toggleModal } from "../../redux-store/modalReducer";
import VoterBenefits from "./VoterBenefits";
import Pagination from "../../common-components/Pagination";

const BeneficialVoterList = () => {
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const modal = useSelector((state) => state.modalReducer.modal);
  const pageLimit = process.env.REACT_APP_PAGE_LIMIT ?? 20;
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isPageUpdated, setIsPageUpdated] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [toggleSearchInput, SetToggleSearchInput] = useState(false);
  const [benificialVoterList, setBenificialVoterList] = useState([]);
  const [electionList, setElectionList] = useState([]);
  const [electionId, setElectionId] = useState("");
  const [voterId, setVoterId] = useState("");
  const [filterDetail, setFilterDetail] = useState({
    city: "",
    gender: "",
    voterId: "",
  });
  const [totalVoters, setTotalVoters] = useState(0);
  //State to handle index numbers of list
  const [page, setPage] = useState();

  const handlePageClick = (e) => {
    if (currentPage > pageCount) {
      Swal.fire({
        text: "Entered Page Cannot be grater than total Page",
        icon: "warning",
      });
      return false;
    } else {
      if (searchValue) {
        getBenificialVoterListBySearch(
          searchValue,
          currentPage,
          electionId,
          filterDetail.city,
          filterDetail.gender,
          filterDetail.voterId
        );
      } else {
        fetchBenificialVoterList(
          searchValue,
          currentPage,
          electionId,
          filterDetail.city,
          filterDetail.gender,
          filterDetail.voterId
        );
      }
    }
  };

  //get election wist voter list
  const electionWiseVoterList = (electionId) => {
    try {
      if (electionId) {
        fetchBenificialVoterList(
          searchValue,
          currentPage,
          electionId,
          filterDetail.city,
          filterDetail.gender,
          filterDetail.voterId
        );
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };
  //get BenificialVote list by search
  const getBenificialVoterListBySearch = async (searchString, page) => {
    try {
      if (searchString) {
        const responseFromServer = await communication.getBenificialVoterList(
          searchString,
          page,
          electionId,
          filterDetail.city,
          filterDetail.gender,
          filterDetail.voterId
        );
        if (responseFromServer?.data?.status === "SUCCESS") {
          setBenificialVoterList(responseFromServer?.data?.voter);
          setPageCount(responseFromServer?.data?.totalPages);
          setTotalVoters(responseFromServer?.data?.totalVoters ?? 0);
          setPage(page);
        } else if (responseFromServer?.data?.status === "JWT_INVALID") {
          Swal.fire({
            text: responseFromServer?.data?.message,
            icon: "warning",
          });
          navigate("/login");
        } else {
          setBenificialVoterList([]);
          setPageCount(0);
          setTotalVoters(0);
        }
        if (searchValue === "") {
          dispatch(toggleModal(""));
        }
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    } finally {
      dispatch(toggleModal(""));
    }
  };

  //get Data by Filter
  const getDataByFilter = () => {
    fetchBenificialVoterList(
      searchValue,
      currentPage,
      electionId,
      filterDetail.city,
      filterDetail.gender,
      filterDetail.voterId
    );
    dispatch(toggleModal(""));
  };

  //Fetch Benificial Voter list on initial load
  const fetchBenificialVoterList = async (
    searchString,
    page,
    electionId,
    city,
    gender,
    voterId
  ) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await communication.getBenificialVoterList(
        searchString,
        page,
        electionId,
        city,
        gender,
        voterId
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setBenificialVoterList(responseFromServer?.data?.voter);
        setPageCount(responseFromServer?.data?.totalPages);
        setElectionId(responseFromServer?.data?.electionId);
        setTotalVoters(responseFromServer?.data?.totalVoters);
        setPage(page);
        fetchElectionList();
        setSearchValue("");
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setBenificialVoterList([]);
        setTotalVoters(0);
        dispatch(hideLoader());
        setPageCount(0);
      }
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //get all election list
  const fetchElectionList = async () => {
    try {
      const responseFromServer = await communication.getAllElectionList();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setElectionList(responseFromServer?.data?.election);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setElectionList([]);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue?.trim() !== "") {
        getBenificialVoterListBySearch(searchValue?.trim(), 1);
      } else {
        fetchBenificialVoterList("", 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  useEffect(() => {
    handlePageClick();
  }, [isPageUpdated]);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">Beneficial Voter List</div>
            <div className="search_wrapper">
              <input
                type="search"
                value={searchValue}
                onChange={(event) => {
                  // getBenificialVoterListBySearch(event.target.value.trim(), 1);
                  setPage(1);
                  setCurrentPage(1);
                  setSearchValue(event.target.value.trim());
                }}
                className="form-control"
                placeholder="search..."
                style={{
                  width: `${toggleSearchInput === false ? "37px" : "250px"}`,
                  borderRadius: `${
                    toggleSearchInput === false ? "50%" : "5px"
                  }`,
                }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() => {
                  SetToggleSearchInput(!toggleSearchInput);
                  setSearchValue("");
                  toggleSearchInput &&
                    searchValue !== "" &&
                    fetchBenificialVoterList("", 1);
                }}
                style={{
                  left: `${toggleSearchInput === false ? "50" : "90"}%`,
                  padding:"16px"
                  
                }}
              />
            </div>
          </div>

          <section>
            <div className="section_wrapper">
              <div className="section_header_wrapper">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  isPageUpdated={isPageUpdated}
                  setIsPageUpdated={setIsPageUpdated}
                />
                <div className="button_wrapper">
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    onClick={(event) => {
                      fetchBenificialVoterList("", currentPage);
                      setPage(currentPage);
                      // setCurrentPage(1);
                      setFilterDetail({});
                      setSearchValue("");
                    }}
                    className="refresh_icon"
                    title="Refresh"
                  />
                  <select
                    name="election"
                    onChange={(event) => {
                      electionWiseVoterList(event.target.value);
                      setElectionId(event.target.value);
                    }}
                    className="form-select party_select_box"
                  >
                    <option value="">Select Election</option>
                    {electionList?.map((election, index) => {
                      return (
                        <option
                          value={election?._id}
                          selected={electionId === election?._id}
                          key={index}
                        >
                          {election?.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="beneficial_voters_count">
                    <h6>
                      Beneficial Voter <FontAwesomeIcon icon={faCheckToSlot} />
                    </h6>
                    <h6>{totalVoters}</h6>
                  </div>
                  <FilterButton
                    name="Filter"
                    icon={faSliders}
                    onClick={() => dispatch(toggleModal("filter"))}
                  />
                </div>
              </div>
              <div className="table_wrapper">
                <div className="table">
                  <div className="table_header">
                    <div className="serial_number">
                      <h5>Sr. No</h5>
                    </div>
                    <div className="full_name">
                      <h5>Full Name</h5>
                    </div>
                    <div className="city">
                      <h5>Village/City</h5>
                    </div>
                    <div className="voterId">
                      <h5>Voter ID</h5>
                    </div>
                    <div className="gender">
                      <h5>Gender</h5>
                    </div>
                    <div className="booth_name">
                      <h5>Booth Name</h5>
                    </div>

                    <div className="page_number">
                      <h5>Page Number</h5>
                    </div>
                    <div className="mobile_number">
                      <h5>Mobile Number</h5>
                    </div>
                  </div>

                  {benificialVoterList?.length > 0 ? (
                    <>
                      {benificialVoterList?.map((benificialVoter, index) => {
                        return (
                          <div className="table_data" key={index}>
                            <div className="serial_number">
                              <h6>
                                {Number(pageLimit) * (page - 1) + (index + 1)}
                              </h6>
                            </div>
                            <div className="full_name">
                              <h6
                                className="hyperlink"
                                onClick={() => {
                                  dispatch(toggleModal("benifits_voter"));
                                  setVoterId(benificialVoter.voterId);
                                  setElectionId(benificialVoter?.electionId);
                                }}
                              >
                                <a>{benificialVoter.name}</a>
                              </h6>
                            </div>
                            <div className="city">
                              <h6>{benificialVoter.city}</h6>
                            </div>
                            <div className="voterId">
                              <h6>{benificialVoter.voterId}</h6>
                            </div>
                            <div className="gender">
                              <h6>{benificialVoter.gender}</h6>
                            </div>
                            <div className="booth_name">
                              <h6>{benificialVoter.boothName}</h6>
                            </div>
                            <div className="page_number">
                              <h6>{benificialVoter.pageNo}</h6>
                            </div>
                            <div className="mobile_number">
                              <h6>
                                {[undefined, null, ""].includes(
                                  benificialVoter.mobile
                                )
                                  ? "NA"
                                  : benificialVoter.mobile}
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="data_not_available_wrapper">
                      Voter Not Available
                    </div>
                  )}
                </div>
              </div>
            </div>

            {modal === "filter" && (
              <BeneficialFilter
                filterDetail={filterDetail}
                setFilterDetail={setFilterDetail}
                getDataByFilter={getDataByFilter}
                searchString={searchValue}
                setCurrentPage={setCurrentPage}
              />
            )}
            {modal === "benifits_voter" && (
              <VoterBenefits voterId={voterId} electionId={electionId} />
            )}
          </section>
        </>
      )}
    </>
  );
};

export default BeneficialVoterList;
