import { icon } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import Swal from "sweetalert2";

const Pagination = ({
  currentPage,
  setCurrentPage,
  isPageUpdated,
  setIsPageUpdated,
  pageCount,
}) => {
  const checkPageValidation = (event) => {
    if (event.target.value > pageCount) {
      Swal.fire({
        text: `Entered page cannot be greater than ${pageCount}`,
        icon: "warning",
      });
      return false;
    } else if (["0", 0].includes(event.target.value)) {
      Swal.fire({ text: "Entered page should not be zero", icon: "warning" });
      return false;
    } else {
      setCurrentPage(event.target.value);
    }
  };

  return (
    <div className="paginationWrapper">
      <button
        onClick={() => {
          setCurrentPage(Number(currentPage) - 1);
          setIsPageUpdated(!isPageUpdated);
        }}
        disabled={currentPage <= 1 ? true : false}
        className="btn_pagination"
      >
        Prev
      </button>
      <div className="paginationInputWrapper">
        <input
          type="number"
          value={Number(currentPage)}
          onWheel={(event) => event.target.blur()}
          onChange={(event) =>
            setCurrentPage(
              event.target.value > pageCount
                ? pageCount
                : event.target.value && event.target.value <= 1
                ? 1
                : event.target.value
            )
          }
          className="form-control"
          placeholder="Page No."
        />
        <button
          className="find_btn"
          type="submit"
          onClick={() => setIsPageUpdated(!isPageUpdated)}
        >
          Find
        </button>
      </div>
      <button
        onClick={() => {
          setCurrentPage(Number(currentPage) + 1);
          setIsPageUpdated(!isPageUpdated);
        }}
        disabled={currentPage >= pageCount ? true : false}
        className="btn_pagination"
      >
        Next
      </button>
      <h6>{pageCount}</h6>
    </div>
  );
};

export default Pagination;
