import React, { useEffect, useState } from "react";
import {
  faArrowsRotate,
  faMagnifyingGlass,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FilterButton from "../../common-components/FilterButton";
import { toggleModal } from "../../redux-store/modalReducer";
import SurveyTrendsFilter from "../data-analysis/SurveyTrendsFilter";
import Loader from "../../utilities/Loader";
import { communication } from "../../services/communication";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../redux-store/loaderReducer";
import Pagination from "../../common-components/Pagination";

const SurveyTrends = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageLimit = Number(process.env.REACT_APP_PAGE_LIMIT);
  const modal = useSelector((state) => state.modalReducer.modal);
  const loader = useSelector((state) => state.loaderReducer.loaderState);
  const [toggleSearchInput, SetToggleSearchInput] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [trendVoterList, setTrendVoterList] = useState([]);
  const [electionList, setElectionList] = useState([]);
  const [filterData, setFilterData] = useState({
    city: "",
    voterId: "",
    gender: "",
    electionId: "",
  });

  const [page, setPage] = useState();
  const [isPageUpdated, setIsPageUpdated] = useState(false);

  //set current page on click
  const handlePageClick = (e) => {
    if (currentPage > pageCount) {
      Swal.fire({
        text: "Entered Page Cannot be grater than total Page",
        icon: "warning",
      });
      return false;
    } else {
      if (searchValue) {
        getSurveyTrendsVoterListBySearch(
          searchValue,
          currentPage,
          filterData.electionId,
          filterData.city,
          filterData.voterId,
          filterData.gender
        );
      } else {
        fetchSurveyTrendsVoterList(
          searchValue,
          currentPage,
          filterData.electionId,
          filterData.city,
          filterData.voterId,
          filterData.gender
        );
      }
    }
  };

  //Filter by Election
  const filterByElection = (electionId) => {
    if (electionId) {
      fetchSurveyTrendsVoterList(
        searchValue,
        currentPage,
        electionId,
        filterData.city,
        filterData.voterId,
        filterData.gender
      );
    }
  };

  //get survey trend list by search
  const getSurveyTrendsVoterListBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await communication.getSurveyTrendsVoterList(
        searchString,
        page,
        filterData.electionId,
        filterData.city,
        filterData.voterId,
        filterData.gender
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        setTrendVoterList(responseFromServer?.data);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(page);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setTrendVoterList([]);
        setPageCount(0);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    } finally {
      dispatch(toggleModal(""));
    }
  };
  //get Survey trends voter list on initial load
  const fetchSurveyTrendsVoterList = async (
    searchString,
    page,
    electionId,
    city,
    voterId,
    gender
  ) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await communication.getSurveyTrendsVoterList(
        searchString,
        page,
        electionId,
        city,
        voterId,
        gender
      );
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setTrendVoterList(responseFromServer?.data);
        setPageCount(responseFromServer?.data?.totalPages);
        setPage(page);
        setFilterData({ electionId: responseFromServer?.data?.electionId });
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        dispatch(hideLoader());
        setTrendVoterList([]);
        setPageCount(0);
      }
    } catch (error) {
      dispatch(hideLoader());
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };

  //get all election list
  const fetchElectionList = async () => {
    try {
      const responseFromServer = await communication.getAllElectionList();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setElectionList(responseFromServer?.data?.election);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        Swal.fire({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setElectionList([]);
      }
    } catch (error) {
      Swal.fire({
        text: error?.response?.data?.message || error?.message,
        icon: "error",
      });
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue?.trim() !== "") {
        getSurveyTrendsVoterListBySearch(searchValue?.trim(), 1);
      } else {
        fetchSurveyTrendsVoterList("", 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  useEffect(() => {
    // fetchSurveyTrendsVoterList();
    fetchElectionList();
  }, []);

  useEffect(() => {
    handlePageClick();
  }, [isPageUpdated]);

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="top_header">
            <div className="title">Survey Trends</div>
            <div className="search_wrapper">
              <input
                type="search"
                value={searchValue}
                onChange={(event) => {
                  // getSurveyTrendsVoterListBySearch(
                  //   event.target.value.trim(),
                  //   1
                  // );
                  setPage(1);
                  setCurrentPage(1);
                  setSearchValue(event.target.value.trim());
                }}
                className="form-control"
                placeholder="search..."
                style={{
                  width: `${toggleSearchInput === false ? "37px" : "250px"}`,
                  borderRadius: `${
                    toggleSearchInput === false ? "50%" : "5px"
                  }`,
                }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={() => {
                  SetToggleSearchInput(!toggleSearchInput);
                  setSearchValue("");
                  toggleSearchInput &&
                    searchValue !== "" &&
                    fetchSurveyTrendsVoterList("", 1);
                }}
                style={{
                  left: `${toggleSearchInput === false ? "50" : "90"}%`,
                  padding: "16px",
                }}
              />
            </div>
          </div>
          <section>
            <div className="section_wrapper">
              <div className="section_header_wrapper">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  isPageUpdated={isPageUpdated}
                  setIsPageUpdated={setIsPageUpdated}
                />
                <div className="button_wrapper">
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    onClick={() => {
                      fetchSurveyTrendsVoterList("", currentPage);
                      setPage(currentPage);
                      setFilterData({});
                      setSearchValue("");
                    }}
                    className="refresh_icon"
                    title="Refresh"
                  />
                  <select
                    name="electionId"
                    onChange={(event) => {
                      filterByElection(event.target.value);
                      setFilterData({
                        ...filterData,
                        electionId: event.target.value,
                      });
                    }}
                    className="form-select"
                  >
                    <option value="">Select Election</option>
                    {electionList?.map((election, index) => {
                      return (
                        <option
                          value={election?._id}
                          selected={election?._id === filterData.electionId}
                          key={index}
                        >
                          {election?.name}
                        </option>
                      );
                    })}
                  </select>
                  <FilterButton
                    name="Filter"
                    icon={faSliders}
                    onClick={() => dispatch(toggleModal("filter"))}
                  />
                </div>
              </div>
              <div className="table_wrapper survey_trends_wrapper">
                <div className="table">
                  <div className="table_header">
                    {trendVoterList?.headers?.map((headers, index) => {
                      return (
                        <div className={headers?.classname} key={index}>
                          <h5>{headers?.name}</h5>
                        </div>
                      );
                    })}
                  </div>
                  {trendVoterList?.voter?.length > 0 ? (
                    <>
                      {trendVoterList?.voter?.map((voterList, index) => {
                        return (
                          <div
                            div
                            className="table_data"
                            key={index}
                            style={{
                              backgroundColor: `${voterList?.colorCode}`,
                            }}
                          >
                            <div className="serial_number">
                              <h6>
                                {Number(pageLimit) * (page - 1) + (index + 1)}
                              </h6>
                            </div>
                            <div className="name">
                              <h6>{voterList?.name}</h6>
                            </div>
                            <div className="city">
                              <h6>{voterList?.city}</h6>
                            </div>
                            <div className="voterId">
                              <h6>{voterList?.voterId}</h6>
                            </div>
                            <div className="gender">
                              <h6>{voterList?.gender}</h6>
                            </div>
                            <div className="booth_name">
                              <h6>{voterList?.boothName}</h6>
                            </div>
                            {voterList?.surveyDetails?.map(
                              (surveyList, index) => {
                                return (
                                  <div className="survey_name" key={index}>
                                    <h6>
                                      {![undefined, null, ""].includes(
                                        surveyList?.vote
                                      )
                                        ? surveyList?.vote
                                        : "-"}
                                    </h6>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="data_not_available_wrapper">
                      Data Not Available
                    </div>
                  )}
                </div>
              </div>
            </div>
            {modal === "filter" && (
              <SurveyTrendsFilter
                filterData={filterData}
                setFilterData={setFilterData}
                getSurveyTrendsVoterListBySearch={
                  getSurveyTrendsVoterListBySearch
                }
                searchString={searchValue}
                setCurrentPage={setCurrentPage}
              />
            )}
          </section>
        </>
      )}
    </>
  );
};

export default SurveyTrends;
