import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputBox from '../../common-components/InputBox';
import { toggleModal } from '../../redux-store/modalReducer';
import { useDispatch } from 'react-redux';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import FilterButton from '../../common-components/FilterButton';
import { useEffect } from 'react';
import { communication } from '../../services/communication';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { genderArray } from '../../utilities/genderArray'

const VoterFilter = ({ filterDetail, setFilterDetail, getVoterListBySearch, searchString, setCurrentPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [voterList, setVoterList] = useState([]);

  const searchFilter =()=>{
    if(filterDetail?.city||filterDetail?.gender||filterDetail?.voterId||filterDetail?.boothName){
      setCurrentPage(1)
      getVoterListBySearch(searchString,1)
    }else{
      Swal.fire({ text: "At least one field is required", icon: "warning" });
      return false;
    }
  }


  
  return (
    <div className="modal_wrapper">
      <div className="card_wrapper">
        <div className="card_header">
          <div className="title">
            <h5>Filter</h5>
          </div>
          <div className="close_button" onClick={() => {dispatch(toggleModal(""));setFilterDetail(
            {
              city: "",
              gender: "",
              voterId: "",
              boothName: ""
            }
          ) }}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        <div className="card_body">
          <div className="row">
            <div className="col-4">
              <div className="input_box_wrapper">
                <InputBox type="text" name="city" value={filterDetail.city} onChange={(event) => setFilterDetail({ ...filterDetail, city: event.target.value })} placeholder="Enter Village/City Name" />

              </div>
            </div>
            <div className="col-4">
              <div className="input_box_wrapper">
                <select name="designationId" onChange={(event) => setFilterDetail({ ...filterDetail, gender: event.target.value })} className="form-select">
                  <option value="">Select Gender</option>
                  {genderArray?.map((gender, index) => {
                    return (
                      <option value={gender} selected={gender === filterDetail?.gender} key={index}>{gender}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="input_box_wrapper">
                <InputBox type="text" name="voterId" placeholder="Enter VoterId" value={filterDetail.voterId} onChange={(event) => setFilterDetail({ ...filterDetail, voterId: event.target.value })} />
              </div>
            </div>
            <div className="col-4">
              <div className="input_box_wrapper" >
                <InputBox type="text" name="boothName" value={filterDetail.boothName} onChange={(event) => setFilterDetail({ ...filterDetail, boothName: event.target.value })} placeholder="Enter Booth Name" />
              </div>
            </div>

            <div className="submit_button_wrapper">
              <FilterButton name="Add Filter" onClick={() => searchFilter()} />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default VoterFilter